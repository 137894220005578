import { useState } from 'react';
import { AddCircleBlueSVG } from '../../../../../../../../assets/icons';
import { Dialog, Input } from '../../../../../../../../components';
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector';
import { Relation } from '../../../../../../../../types/enums/AppointmentEnum';
import { CoveredService, Insurance } from '../../../../../../../../types/InsuranceType';
import { Roles } from '../../../../../../../../types/Roles';
import InsuredList from '../../../InsuranceInformation/components/InsuredList';
import RelatedRow from '../../../InsuranceInformation/components/RelatedRow';
import * as S from '../../styles';
import AddCoveredServicesDialog from '../AddCoveredServiceDialog';
import CoveredServices from '../CoveredServices';
import ItemValue from '../ItemValue';
import PhotoInsurancesBlock from '../PhotoInsurancesBlock';
import dayjs from 'dayjs';

type Props = {
  row?: Insurance;
};

const InsuranceItemBlock = ({ row }: Props) => {
  const userInfo = useAppSelector(state => state.auth);

  const [isOpenAddCoveredServiceDialog, setIsOpenAddCoveredServiceDialog] =
    useState(false);

  const [editedRow, setEditedRow] = useState<CoveredService | null>(null);

  return (
    <S.InsuranceItemWrapper>
      <Dialog
        open={isOpenAddCoveredServiceDialog}
        onClose={() => {
          setEditedRow(null);
          setIsOpenAddCoveredServiceDialog(!isOpenAddCoveredServiceDialog);
        }}
      >
        <AddCoveredServicesDialog
          row={editedRow}
          insurance={row}
          onClose={() => {
            setEditedRow(null);
            setIsOpenAddCoveredServiceDialog(!isOpenAddCoveredServiceDialog);
          }}
        />
      </Dialog>
      <S.Line>
        <ItemValue
          article="Effective Start Date"
          value={
            row?.effectiveStartDate && dayjs(row.effectiveStartDate).format('MM/DD/YYYY')
          }
        />
        <ItemValue
          article="Effective Stop Date"
          value={
            row?.effectiveStopDate && dayjs(row.effectiveStopDate).format('MM/DD/YYYY')
          }
        />
      </S.Line>
      <S.Line>
        <ItemValue article="PayerId" value={row?.payerId} />
        <ItemValue article="Insurance Company" value={row?.primaryInsuranceCompany} />
        <ItemValue article="Company phone" value={row?.companyPhone} />
        <ItemValue article="Insured/ Subscriber Name" value={row?.subscriberName} />
        <ItemValue article="Relationship to Patient" value={row?.relation} />
        <ItemValue article="Insured SSN" value={row?.ssn} />
        <ItemValue article="ID" value={row?.insuranceId} />
        <ItemValue article="Group #" value={row?.groupNumber} />
        <ItemValue article="Group name" value={row?.groupName} />
      </S.Line>
      <PhotoInsurancesBlock row={row} />
      {userInfo.role !== Roles.PATIENT && row && row.relation !== Relation.SELF && (
        <S.BlockContent>
          <InsuredList label="Insured list for insurance" insuranceId={row?.id} />
          <S.InsuredListWrapper>
            {row &&
              row.related?.map(item => {
                return <RelatedRow key={item.id} row={item} />;
              })}
          </S.InsuredListWrapper>
        </S.BlockContent>
      )}

      <S.ArticleSmallWrapper className="sub">
        Covered services
        <S.ShowBtnsLogs
          onClick={() => {
            setIsOpenAddCoveredServiceDialog(!isOpenAddCoveredServiceDialog);
          }}
        >
          <AddCircleBlueSVG />
          Add service
        </S.ShowBtnsLogs>
      </S.ArticleSmallWrapper>
      {row?.coveredServices.map(item => {
        return (
          <CoveredServices
            key={item.id}
            service={item}
            setIsOpenAddCoveredServiceDialog={setIsOpenAddCoveredServiceDialog}
            setEditedRow={setEditedRow}
          />
        );
      })}
    </S.InsuranceItemWrapper>
  );
};

export default InsuranceItemBlock;
