import { FormikProps } from 'formik';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { AppointmentsNew } from '../../../../../../types/AppointmentNewTypes';
import AutoCompleteInput from '../../../../../atoms/AutoComplete';
import OptionContent from '../OptionContent';
import Input from '../../../../../atoms/Input';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { useUserDeepSearchMutMutation } from '../../../../../../store/api/searchApi/searchApi';
import { useDebounce } from 'use-debounce';
import { OptionType } from '../OptionContent/OptionContent';
import dayjs from 'dayjs';
import { Roles } from '../../../../../../types/Roles';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  clinic?: string;
  error?: string;
  appointmentInformation?: AppointmentsNew;
};

const DOB = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      clinic,
      appointmentInformation,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);

    const [allPatientsGet, allPatientsStatus] = useUserDeepSearchMutMutation({});

    const [data, setData] = useState([]);

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (
        !userProfile &&
        !duplAppointment &&
        !appointmentInformation &&
        (!selectedAppointment || selectedAppointment?.isPossibleToUpdate)
      ) {
        allPatientsGet({
          dateOfBirthSearch: debouncedSearchValue,
          page: 1,
          ...(clinic && { clinicId: clinic }),
        })
          .unwrap()
          .then(res => setData(res?.rows));
      }
    }, [debouncedSearchValue, clinic]);

    useEffect(() => {
      if (selectedValue) {
        formik.setValues({
          ...formik.values,
          firstName: selectedValue.profile?.firstName || '',
          lastName: selectedValue.profile?.lastName || '',
          phone: selectedValue?.phone || '',
          userId: selectedValue?.id || '',
          email: selectedValue?.email || '',
          dateOfBirth:
            dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY') || '',
        });
      }
    }, [selectedValue]);

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('dateOfBirth', searchValue);
      }
    }, [searchValue]);

    return (
      <>
        {byClick ? (
          <AutoCompleteInput
            data={data || []}
            isRequired
            customRender={true}
            mainLabel={'DOB'}
            loading={allPatientsStatus.isLoading ? true : false}
            searchValue={searchValue}
            options={data || []}
            setSearchValue={setSearchValue}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            disabled={
              !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
            }
            getOptionLabel={(option: any) =>
              !selectedValue
                ? formik?.values?.dateOfBirth
                  ? dayjs(formik?.values?.dateOfBirth).format('MM/DD/YYYY')
                  : ''
                : option && dayjs(option?.profile?.dateOfBirth).format('MM/DD/YYYY')
            }
            renderOption={(props: any, option: any) => {
              return (
                <OptionContent
                  searchValue={debouncedSearchValue}
                  key={option.id}
                  textToHighlight={
                    option.profile?.dateOfBirth
                      ? dayjs(option.profile?.dateOfBirth).format('MM/DD/YYYY')
                      : ''
                  }
                  otherText={option.profile?.lastName || ''}
                  props={props}
                  type={OptionType.DOB}
                  option={option}
                  loading={allPatientsStatus.isLoading}
                />
              );
            }}
          />
        ) : (
          <Input
            label="DOB"
            id="dateOfBirth"
            name="dateOfBirth"
            error={!!formik.errors.dateOfBirth}
            helperText={formik.errors.dateOfBirth as string}
            value={dayjs(formik.values.dateOfBirth).format('MM/DD/YYYY') || ''}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.dateOfBirth ||
              !!selectedAppointment?.patient?.profile?.dateOfBirth ||
              !!duplAppointment?.patient?.profile?.dateOfBirth ||
              !!selectedValue
            }
          />
        )}
      </>
    );
  },
);

export default DOB;
