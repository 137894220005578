import { useState } from 'react';
import { AppointmentTypeEnum } from '../../types/enums/AppointmentEnum';

export const transformReasonText = (text: AppointmentTypeEnum | null) => {
  if (text) {
    if (text === AppointmentTypeEnum.MASSAGE) {
      return {
        text: 'Massage',
      };
    }

    if (text === AppointmentTypeEnum.HERBS) {
      return {
        text: 'Herbs',
      };
    }

    if (text === AppointmentTypeEnum.ACUPUNCTURE) {
      return {
        text: 'ACU',
      };
    }

    if (text === AppointmentTypeEnum.OTHER) {
      return {
        text: 'Other',
      };
    }

    if (text === AppointmentTypeEnum.PT) {
      return {
        text: 'PT',
      };
    }
  }

  return {
    text: 'Unknown',
  };
};
