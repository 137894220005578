import { useParams } from 'react-router-dom';
import { AddClinicIconSVG, ArrowLSVG, ArrowRSVG } from '../../../../../../assets/icons';
import { Button, CustomTable, Dialog } from '../../../../../../components';
import { useDoctorInsurancesQuery } from '../../../../../../store/api/staff/staffApi';
import * as S from '../../styles';
import InsuranceToRow from './TableData/InsuranceToRow';
import { useEffect, useState } from 'react';
import DialogAddInsuranceTo from '../Dialog_AddInsuranceTo';
import { Clinics } from '../../../../../../types/ClinicTypes';

type Props = {
  assignClinics?: Array<Clinics>;
};

export type InsuranceToDoctor = {
  id: string;
  clinicUserId: string;
  payerName: string;
  payerId: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  clinicUser: {
    id: string;
    userId: string;
    clinicId: string;
    position: string;
    speciality: any;
    workingStartDate: string;
    workingEndDate: any;
    isWorkInClinic: boolean;
    accountNumber: any;
    isPatient: boolean;
    createdAt: string;
    updatedAt: string;
    clinic: {
      id: string;
      name: string;
      logo: {
        id: string;
        url: string;
      };
    };
  };
};

const InsuranceTo: React.FC<Props> = ({ assignClinics }) => {
  const headers = [
    { name: 'Clinic', key: 'Clinic' },
    { name: 'Payer ID', key: 'PayerID' },
    { name: 'Payer Name', key: 'PayerName' },
    { name: 'Type', key: 'Type' },
    { name: '', key: 'Edit' },
  ];

  const params = useParams();

  const [page, setPage] = useState(1);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const getDoctorInsurances = useDoctorInsurancesQuery({
    id: params.profileId,
    page: page,
    take: 20,
  });

  const handleShowCount = () => {
    if (
      getDoctorInsurances?.currentData?.count &&
      getDoctorInsurances.currentData.count > 0
    ) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getDoctorInsurances?.currentData?.count &&
          getDoctorInsurances.currentData.count < secondCount
            ? getDoctorInsurances.currentData.count
            : secondCount}{' '}
          of {getDoctorInsurances?.currentData?.count} insurances{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getDoctorInsurances?.currentData?.count} insurances{' '}
        </S.PaginationText>
      );
    }
  };

  const [open, setOpen] = useState(false);

  const [editedInsurance, setEditedInsurance] = useState<InsuranceToDoctor | null>(null);

  const handleChangeOpen = () => {
    setEditedInsurance(null);
    setOpen(!open);
  };

  useEffect(() => {
    if (editedInsurance) {
      setOpen(true);
    }
  }, [editedInsurance]);

  return (
    <>
      <Dialog open={open} onClose={handleChangeOpen}>
        <DialogAddInsuranceTo
          onClose={handleChangeOpen}
          assignClinics={assignClinics}
          doctorId={params.profileId}
          editedInsurance={editedInsurance}
        />
      </Dialog>
      <S.InsuranceWrapper>
        <S.Line className="margin">
          <S.PaginationWrapper>
            <S.Arrows>
              <ArrowLSVG
                onClick={handleBack}
                className={getDoctorInsurances.currentData?.page === 1 ? 'disabled' : ''}
              />
              <ArrowRSVG
                onClick={handleForward}
                className={
                  getDoctorInsurances.currentData?.page ===
                  getDoctorInsurances.currentData?.lastPage
                    ? 'disabled'
                    : ''
                }
              />
            </S.Arrows>
            {getDoctorInsurances.isFetching ? <div>...</div> : handleShowCount()}
          </S.PaginationWrapper>
          <S.ButtonWrapper>
            <Button text="Add Insurance" onClick={handleChangeOpen}>
              <AddClinicIconSVG />
            </Button>
          </S.ButtonWrapper>
        </S.Line>
        <CustomTable headers={headers} isFetching={getDoctorInsurances.isFetching}>
          <InsuranceToRow
            data={getDoctorInsurances.currentData?.rows}
            headers={headers}
            setEditedInsurance={setEditedInsurance}
          />
        </CustomTable>
      </S.InsuranceWrapper>
    </>
  );
};

export default InsuranceTo;
