import { useEffect, useState } from 'react';
import { useGetEventsHistoryQuery } from 'store/api/claims/claimsApi';
import * as S from '../../styles';
import ClaimMessageErrorCard from '../ClaimMessageErrorCard';
import { CircularLoader } from 'components';
import { ClaimStatusType } from 'types/InvoiceType';
import useInfinityScroll from 'hooks/useInfinityScroll';

const Claim_HISTORY_LIMIT = 20;

type Props = {
  claimId: string;
};

const ClaimHistoryEvents = ({ claimId }: Props) => {
  const [page, setPage] = useState(1);

  const getEvents = useGetEventsHistoryQuery({
    claimId: claimId,
    page: page,
    take: Claim_HISTORY_LIMIT,
  });

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(page + 1),
    hasMore: page < (getEvents.currentData?.lastPage ?? 0),
  });

  useEffect(() => {
    getEvents.refetch();
  }, [page]);

  return (
    <>
      {getEvents.isFetching || getEvents.isLoading ? (
        <S.Loader>
          <CircularLoader color="#0084B1" size={20} />
        </S.Loader>
      ) : (
        getEvents.data?.rows.map((item, index, { length }) => {
          const refLast = index === length - 1 ? setLastElement : null;

          return (
            <div ref={refLast} key={item.id}>
              <ClaimMessageErrorCard
                color="#F9F9FC"
                clickable={!!item.modifierId}
                title={item.status}
                claimEvent={item}
              />
            </div>
          );
        })
      )}
    </>
  );
};

export default ClaimHistoryEvents;
