import { MouseEventHandler, MouseEvent, useEffect, useState } from 'react';
import {
  AppointmentSVG,
  ArrowLeftSVG,
  ArrowRightSVG,
  CalendarPlaceholderSVG,
  ChoosedDateCalendarSVG,
  ClinicSVG,
  FilterSVG,
} from '../../../../assets/icons';
import {
  Button,
  CheckBox,
  Drawer,
  MultiDatePicker,
  RecordItem,
  ScrollContainer,
  SearchInput,
  SelectInput,
} from '../../../../components';
import * as S from './styles';
import { Calendar, DateObject, type Value } from 'react-multi-date-picker';
import { getWeekInitial } from '../../../../helpers/CalendarHelpers/getWeekInitial';
import {
  CircularProgress,
  LinearProgress,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { appointmentStatuses, modeTypes } from '../../../../constants/constants';
import AuthDrawerContent from '../../../Auth/components/AuthDrawerContent';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDay } from '../../../../helpers/CalendarHelpers/getDay';
import { getMonth } from '../../../../helpers/CalendarHelpers/getMonth';
import { getYear } from '../../../../helpers/CalendarHelpers/getYear';
import {
  useAllClinicsGetQuery,
  useClinicsGetQuery,
} from '../../../../store/api/clinics/clinicApi';
import { DrawerMode } from '../../../../types/DrawerMode';
import TreatmentForm from './components/TreatmentForm';
import { useAppointmentGetQuery } from '../../../../store/api/appointment/appointmentApi';
import { Clinics } from '../../../../types/ClinicTypes';
import { Appointments as AppointmentsType } from '../../../../types/AppointmentsTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  handgleChangeMode,
  handleBackDate,
  handleChangeDate,
  handleForwardDate,
} from '../../../../helpers/CalendarHelpers/handleDates';
import { useDebounce } from 'use-debounce';
import ChooseDrawerContent from '../../../../services/chooseDrawerContent';

const Appointments = () => {
  const [selectedDate, setSelectedDate] = useState<DateObject[]>(
    getWeekInitial(new DateObject()),
  );
  const userId = useAppSelector(state => state.auth.id);
  const userInfo = useAppSelector(state => state.auth);
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);

  const [modeParam, setModeParam] = useSearchParams('mode=');

  const clearDate = () => {
    setSelectedDate([]);
  };

  const [mode, setMode] = useState('Week');
  const [appointmentStatus, setAppointmentStatus] = useState<any>(appointmentStatuses);
  const [clinic, setClinic] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);

  const allAppointments = useAppointmentGetQuery({
    ...(userId && { 'userId[]': userId }),
    q: debouncedSearch,
    ...(clinic && { 'clinicId[]': clinic }),
    visitDateTimeStart: getDay(selectedDate[0])[0].format('YYYY-MM-DD'),
    visitDateTimeEnd: getDay(selectedDate[1])[1].format('YYYY-MM-DD'),
    ...(appointmentStatus && { 'status[]': appointmentStatus }),
    page: page,
  });

  const isShowDrawer = useAppSelector(state => state.drawer.show);
  const dispatch = useAppDispatch();

  const handleChangeSelect = (event: SelectChangeEvent<typeof appointmentStatus>) => {
    const {
      target: { value },
    } = event;
    setAppointmentStatus(typeof value === 'string' ? value.split(',') : value);
    setPage(1);
  };

  useEffect(() => {
    setModeParam('mode=' + mode.toLowerCase());
    handgleChangeMode(mode, setSelectedDate, setPage);
  }, [mode]);

  const clinicsList = useAllClinicsGetQuery({
    take: 50,
    status: 'active',
  });

  const found = clinicsList.data?.rows.find(item => item.id === clinic)
    ? clinicsList.data?.rows.find(item => item.id === clinic)?.name
    : '';

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  return (
    <>
      <Drawer
        open={isShowDrawer}
        onClose={() =>
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }))
        }
      >
        <ChooseDrawerContent />
      </Drawer>
      <S.AppointmentsWrapper>
        <S.SubtitleBar>
          <S.SubtitleText>All Appointments</S.SubtitleText>
          <S.SubtitleItems>
            <S.ButtonWrapper>
              <Button
                text="Request an Appointment"
                onClick={() => {
                  const newUserInfo = {
                    profile: {
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                    },
                    ...userInfo,
                  };
                  dispatch(
                    showDrawer({
                      show: true,
                      mode: DrawerMode.NEW_APPOINTMENT_USER,
                      props: newUserInfo,
                    }),
                  );
                }}
              >
                <AppointmentSVG />
              </Button>
            </S.ButtonWrapper>
          </S.SubtitleItems>
        </S.SubtitleBar>
        <S.Content>
          <S.CalendarBar>
            <MultiDatePicker
              weekPicker={mode === 'Week' ? true : false}
              onlyMonthPicker={mode === 'Month' ? true : false}
              onlyYearPicker={mode === 'Year' ? true : false}
              value={selectedDate}
              onChange={(value: any) =>
                handleChangeDate(mode, setSelectedDate, value, setPage)
              }
              multiple={false}
            ></MultiDatePicker>
            <S.Filters>
              <S.FilterWrapper>
                <SelectInput
                  label=""
                  renderValue={() => (
                    <S.SelectInputOutput>
                      <ClinicSVG />
                      <S.SelectInputOutputText>
                        {clinic === '' ? 'All' : found}
                      </S.SelectInputOutputText>
                    </S.SelectInputOutput>
                  )}
                  value={clinic}
                  disabled={allAppointments.isFetching}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    setPage(1);
                    setClinic(e.target.value as string);
                  }}
                >
                  <MenuItem value={''}>
                    <S.MenuItemRow>{'All'}</S.MenuItemRow>
                  </MenuItem>
                  {clinicsList?.data?.rows.map((clinic: Clinics) => (
                    <MenuItem
                      value={clinic.id}
                      key={clinic.id}
                      disabled={allAppointments.isFetching}
                    >
                      <S.MenuItemRow>{clinic.name}</S.MenuItemRow>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.FilterWrapper>
              <S.FilterWrapper>
                <SelectInput
                  label=""
                  multiple
                  disabled={allAppointments.isFetching}
                  value={appointmentStatus}
                  renderValue={(selected: any) => (
                    <S.SelectInputOutput>
                      <FilterSVG />
                      <S.SelectInputOutputText>
                        {selected.length === 0
                          ? 'Status not set'
                          : selected.join(', ').toUpperCase()}
                      </S.SelectInputOutputText>
                    </S.SelectInputOutput>
                  )}
                  onChange={handleChangeSelect}
                >
                  {appointmentStatuses.map(status => (
                    <MenuItem
                      key={status}
                      value={status}
                      disabled={allAppointments.isFetching}
                    >
                      <S.MenuItemContent>
                        <CheckBox checked={appointmentStatus.indexOf(status) > -1} />
                        {status.toUpperCase()}
                      </S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.FilterWrapper>
              <S.FilterWrapper>
                <SelectInput
                  className="forMobile"
                  label=""
                  value={mode}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    setMode(e.target.value as string)
                  }
                >
                  {modeTypes.map(mode => (
                    <MenuItem value={mode} key={mode}>
                      <S.MenuItemRow>{mode}</S.MenuItemRow>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.FilterWrapper>
            </S.Filters>
          </S.CalendarBar>
          <S.Information>
            <S.HeaderInformation>
              <S.ChoosedDateWrapper>
                <ArrowLeftSVG
                  className="btn"
                  onClick={() =>
                    handleBackDate(mode, setSelectedDate, selectedDate, setPage)
                  }
                />
                <ChoosedDateCalendarSVG />
                <S.ChoosedDateText>
                  {mode === 'Day'
                    ? selectedDate[0]?.format('MMM DD, YYYY')
                    : selectedDate[0]?.format('MMM DD, YYYY') +
                      selectedDate[1]?.format(' - MMM DD, YYYY')}
                </S.ChoosedDateText>
                <ArrowRightSVG
                  className="btn"
                  onClick={() =>
                    handleForwardDate(mode, setSelectedDate, selectedDate, setPage)
                  }
                />
              </S.ChoosedDateWrapper>
              <S.SelectWrapper>
                <SelectInput
                  label=""
                  value={mode}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    setMode(e.target.value as string)
                  }
                >
                  {modeTypes.map(mode => (
                    <MenuItem value={mode} key={mode}>
                      <S.MenuItemRow>{mode}</S.MenuItemRow>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.SelectWrapper>
            </S.HeaderInformation>
            <ScrollContainer>
              {userInfo?.clinics?.length ? (
                <S.RecordItems>
                  {allAppointments.isLoading && !allAppointments.isFetching ? (
                    <S.Loader>
                      <LinearProgress />
                    </S.Loader>
                  ) : (
                    <>
                      {allAppointments.currentData?.rows.length ? (
                        allAppointments.currentData?.rows.map(
                          (item, index, { length }) => {
                            const isLast =
                              index === length - 1 &&
                              allAppointments.currentData &&
                              length < allAppointments.currentData.count;
                            return (
                              <div
                                key={item.id + index}
                                ref={isLast ? setObserverTarget : undefined}
                              >
                                <RecordItem
                                  key={item.id}
                                  row={item}
                                  onClick={e => {
                                    e.stopPropagation();
                                    dispatch(
                                      showDrawer({
                                        show: true,
                                        mode: DrawerMode.INFO_APPOINTMENT_USER,
                                        props: item,
                                      }),
                                    );
                                  }}
                                  onTreatmentClick={e => {
                                    e.stopPropagation();
                                    dispatch(
                                      showDrawer({
                                        show: true,
                                        mode: DrawerMode.TREATMENT,
                                        props: item,
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            );
                          },
                        )
                      ) : (
                        <S.CalendarPlaceholderWrapper>
                          <CalendarPlaceholderSVG />
                          <S.PlaceText>No any appointment yet</S.PlaceText>
                        </S.CalendarPlaceholderWrapper>
                      )}
                      {allAppointments.isFetching && (
                        <S.Loader>
                          <LinearProgress />
                        </S.Loader>
                      )}
                    </>
                  )}
                </S.RecordItems>
              ) : (
                <S.CalendarPlaceholderWrapper>
                  <CalendarPlaceholderSVG />
                  <S.PlaceText className="noopacity">
                    To complete the Registration process, you need to create your first
                    appointee. Please click the "Request an Appointment" button and select
                    the appropriate options. We are glad to see you as a patient!
                  </S.PlaceText>
                  <S.ButtonWrapper>
                    <Button
                      text="Request an Appointment"
                      onClick={() => {
                        dispatch(
                          showDrawer({
                            show: true,
                            mode: DrawerMode.NEW_APPOINTMENT_USER,
                            props: null,
                          }),
                        );
                      }}
                    >
                      <AppointmentSVG />
                    </Button>
                  </S.ButtonWrapper>
                </S.CalendarPlaceholderWrapper>
              )}
            </ScrollContainer>
          </S.Information>
        </S.Content>
      </S.AppointmentsWrapper>
    </>
  );
};

export default Appointments;
