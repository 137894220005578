import dayjs from 'dayjs';
import { CoveredServicesInformation } from '../../../../../types/enums/AppointmentEnum';

export const configVRName = (index: number) => {
  switch (index) {
    case 0:
      return 'P-';
    case 1:
      return 'S-';
    case 2:
      return 'T-';
    default:
      return '';
  }
};

export const configVRCount = (count: number) => {
  switch (count) {
    case 9999:
      return 'UV';
    default:
      return count;
  }
};

export const configColor = (service: CoveredServicesInformation) => {
  const { startDate, endDate } = service.insuranceInfo;
  const currentDate = dayjs();

  if (
    startDate &&
    endDate &&
    service.count > 0 &&
    currentDate.isAfter(startDate) &&
    currentDate.isBefore(endDate)
  ) {
    return '#9BC15F';
  } else {
    return '#C66060';
  }
};
