import { useState } from 'react';
import {
  DeleteRedBtnSVG,
  EditBtnCurrentColorSVG,
  RemoveCircleSVG,
} from '../../../../../../../../assets/icons';
import { CheckBox, Dialog } from '../../../../../../../../components';
import ConfirmDeleteDialog from '../../../../../../../../components/molecules/ConfirmDeleteDialog';
import { transformArticleText } from '../../../../../../../../helpers/functions/transformArticleText';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useDeleteCoveredServiceMutation } from '../../../../../../../../store/api/insuranceRelative/insuranceRelativeApi';
import { setMessage } from '../../../../../../../../store/slices/message';
import { CoveredService } from '../../../../../../../../types/InsuranceType';
import * as S from '../../styles';
import ItemValue from '../ItemValue';
import { getNetworkTypeName } from 'helpers/functions/getInOutNetworkName';

type Props = {
  service: CoveredService;
  setIsOpenAddCoveredServiceDialog: (value: React.SetStateAction<boolean>) => void;
  setEditedRow: React.Dispatch<React.SetStateAction<CoveredService | null>>;
};

const CoveredServices = ({
  service,
  setIsOpenAddCoveredServiceDialog,
  setEditedRow,
}: Props) => {
  const [deleteCoveredService] = useDeleteCoveredServiceMutation({});
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  const [deletedId, setDeletedId] = useState<string | null>(null);

  const handleSuccessDelete = () => {
    if (deletedId) {
      deleteCoveredService({
        id: service.id,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Covered service was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };
  return (
    <S.Row>
      <S.ServiceRow>
        <Dialog
          open={openDialog}
          onClose={() => {
            setDeletedId(null);
            setOpenDialog(!openDialog);
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setDeletedId(null);
              setOpenDialog(!openDialog);
            }}
            titleText={'covered service'}
            helperText="This process is irreversible"
            onSuccess={handleSuccessDelete}
          />
        </Dialog>
        <S.Line>
          <ItemValue
            article="Choosed Service"
            value={transformArticleText(service.service).text}
          />
          <ItemValue article="Type" value={getNetworkTypeName(service.type)} />
          <S.CheckItem>
            <CheckBox
              checked={service.isUnlimited}
              className="big"
              style={{ pointerEvents: 'none' }}
              value={service.isUnlimited}
            />
            Unlimited
          </S.CheckItem>
          <ItemValue
            article="No. of Visit Authorized"
            value={service.isUnlimited ? 'UV' : service.visitAuthorizet}
          />
          <ItemValue article="No. of Visit Used" value={service.visitUsed} />
        </S.Line>
        <S.Btns>
          <EditBtnCurrentColorSVG
            color="#848A9B"
            onClick={() => {
              setEditedRow(service);
              setIsOpenAddCoveredServiceDialog(true);
            }}
          />
          <RemoveCircleSVG
            onClick={() => {
              setDeletedId(service.id);
              setOpenDialog(true);
            }}
            style={{
              width: '19px',
              height: '19px',
            }}
            color="#C66060"
          />
        </S.Btns>
      </S.ServiceRow>
    </S.Row>
  );
};

export default CoveredServices;
