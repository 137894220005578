import { FormikProps } from 'formik';
import { DatePicker, Input } from '../../../../../../../../components';
import * as S from '../../styles';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { ChangeEvent, forwardRef } from 'react';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { BoldLineSVG } from '../../../../../../../../assets/icons';

import { ClientRegistrationType } from '../../../pages/ClientRegistrationPage/ClientRegistrationType';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import dayjs from 'dayjs';

type Props = {
  formik: FormikProps<ClientRegistrationType>;
  setIsDirty?: () => void;
};
const CROtherContacts = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => (
    <>
      <S.Article>Other Contacts</S.Article>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Referring Physician'}
            defaultValue={formik.values.otherContacts.referringPhysician}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.referringPhysician', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Phone'}
            defaultValue={formik.values.otherContacts.phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.phone', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.Caption>Marital Status</S.Caption>
        <CheckBoxWithLabel label="Single" />
        <CheckBoxWithLabel label="Married" />
        <CheckBoxWithLabel label="Divorced" />
        <CheckBoxWithLabel label="Widowed" />
        <CheckBoxWithLabel label="Separated" />
        <CheckBoxWithLabel label="Domestic" />
        <CheckBoxWithLabel label="Partner" />
        <CheckBoxWithLabel label="Minor Child" />
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Name of Spouse'}
            defaultValue={formik.values.otherContacts.nameOfSpouse}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.nameOfSpouse', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <DatePicker
            label="Patient`s Birth Date"
            defaultValue={dayjs(formik.values?.otherContacts?.birthDate)}
            onChange={value => {
              handleChangeDatePicker(value, 'otherContacts.birthDate', ref, setIsDirty);
            }}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label={'Age'}
            defaultValue={formik.values.otherContacts.age}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.age', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Physical Address'}
            defaultValue={formik.values.otherContacts.physicalAddress}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.physicalAddress', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label={'City'}
            defaultValue={formik.values.otherContacts.city}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.city', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label={'State'}
            defaultValue={formik.values.otherContacts.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.state', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label={'Zip Code'}
            defaultValue={formik.values.otherContacts.zipCode}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.zipCode', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Spouse Employer'}
            defaultValue={formik.values.otherContacts.spouseEmployer}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.spouseEmployer', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Occupation'}
            defaultValue={formik.values.otherContacts.occupation}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.occupation', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
      <S.Line>
        <S.InputWrapper className="px240">
          <Input
            label={'Work Phone'}
            defaultValue={formik.values.otherContacts.workPhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.workPhone', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
        <S.InputWrapper className="px240">
          <Input
            label={'Cell Phone'}
            defaultValue={formik.values.otherContacts.cellPhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInputRef(e, 'otherContacts.cellPhone', ref, setIsDirty)
            }
          />
        </S.InputWrapper>
      </S.Line>
    </>
  ),
);

export default CROtherContacts;
