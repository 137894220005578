import { useState } from 'react';
import {
  AddCircleBlueSVG,
  EditBlueBtnSVG,
  EditBtnMinimalSVG,
} from '../../../../../../assets/icons';
import { Dialog } from '../../../../../../components';
import CheckBoxWithLabel from '../../../../../../components/atoms/CheckboxWithLabel';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import AddInsuranceDialog from './components/AddInsuranceDialog';
import CommonInformationBlock from './components/CommonInformationBlock';
import InsurancesBlock from './components/InsurancesBlock';
import * as S from './styles';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../types/DrawerMode';
import { Insurance } from '../../../../../../types/InsuranceType';

type Props = {
  rows: UserProfile | undefined;
};

const InsuranceInformationV2 = ({ rows }: Props) => {
  const [isOpenAddInsuranceDialog, setIsOpenAddInsuranceDialog] = useState(false);

  const [editedRow, setEditedRow] = useState<Insurance | null>(null);

  const dispatch = useAppDispatch();
  return (
    <S.Wrapper>
      <Dialog
        open={isOpenAddInsuranceDialog}
        onClose={() => {
          setEditedRow(null);
          setIsOpenAddInsuranceDialog(!isOpenAddInsuranceDialog);
        }}
      >
        <AddInsuranceDialog
          row={editedRow}
          userId={rows?.profile?.id}
          onClose={() => {
            setEditedRow(null);
            setIsOpenAddInsuranceDialog(!isOpenAddInsuranceDialog);
          }}
        />
      </Dialog>
      <S.Content>
        <S.Article>
          Insurance information
          <S.ShowBtnsLogs
            onClick={() =>
              dispatch(
                showDrawer({
                  show: true,
                  mode: DrawerMode.INSURANCE_LOGS,
                  props: rows?.id,
                }),
              )
            }
          >
            <EditBlueBtnSVG />
            Show Edit History
          </S.ShowBtnsLogs>
        </S.Article>
        <S.SubArticle>
          Please fill out this form in its entirety, we can not verify or accept insurance
          with any missing information.
        </S.SubArticle>
        <CommonInformationBlock rows={rows} />
        <InsurancesBlock
          rows={rows}
          setEditedRow={setEditedRow}
          setIsOpenAddInsuranceDialog={setIsOpenAddInsuranceDialog}
        />
        <S.ButtonAddInsurance
          onClick={() => setIsOpenAddInsuranceDialog(!isOpenAddInsuranceDialog)}
        >
          <AddCircleBlueSVG />
          Add Insurance
        </S.ButtonAddInsurance>
      </S.Content>
    </S.Wrapper>
  );
};

export default InsuranceInformationV2;
