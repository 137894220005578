import { useState } from 'react';
import { TabsItem } from 'components';
import * as S from './styles';
import { Tab } from '@mui/material';
import StaffReceptionists from './components/StaffReceptionists';
import StaffDoctors from './components/StaffDoctors';
import StaffBilling from './components/StaffBilling';
import StaffLocal from './components/StaffLocal';
import ReferringProviders from './components/ReferringProviders/ReferringProviders';

const Staff = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Staff</S.SubtitleText>
      </S.SubtitleBar>

      <S.Content>
        <S.MenuStaffWrapper>
          <S.SubtitleItems>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="Local Admin"></Tab>
              <Tab disableRipple label="Billing Specialists"></Tab>
              <Tab disableRipple label="Doctors"></Tab>
              <Tab disableRipple label="Receptionists"></Tab>
              <Tab disableRipple label="Specialists"></Tab>
              <Tab disableRipple label="Referring providers"></Tab>
            </TabsItem>
            {/* {handleButtonShow()} */}
          </S.SubtitleItems>
          {tabIndex === 0 && <StaffLocal fromClinic={false} />}
          {tabIndex === 1 && <StaffBilling />}
          {tabIndex === 2 && <StaffDoctors deletable={false} />}
          {tabIndex === 3 && <StaffReceptionists fromClinic={false} />}
          {tabIndex === 4 && <StaffDoctors isSpecialists deletable={false} />}
          {tabIndex === 5 && <ReferringProviders />}
        </S.MenuStaffWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Staff;
