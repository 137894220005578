import { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../components/atoms/Button';
import CheckBox from '../../../../../components/atoms/CheckBox';
import * as S from './styles';
import Input from '../../../../../components/atoms/Input';
import {
  BackChestSVG,
  BackHeadSVG,
  BackHipsSVG,
  BackLeftArmSVG,
  BackLeftLegSVG,
  BackNeckSVG,
  BackRightArmSVG,
  BackRightLegSVG,
  FrontChestSVG,
  FrontHeadSVG,
  FrontHipsSVG,
  FrontLeftArmSVG,
  FrontLeftLegSVG,
  FrontNeckSVG,
  FrontRightArmSVG,
  FrontRightLegSVG,
  FrontStomachSVG,
} from '../../../../../assets/icons';
import { Appointments } from '../../../../../types/AppointmentsTypes';
import { Treatment } from '../../../../../types/TreatmentType';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useTreatmentCreateMutation,
  useTreatmentUpdateMutation,
} from '../../../../../store/api/treatment/treatmentApi';
import { useDispatch } from 'react-redux';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { setMessage } from '../../../../../store/slices/message';
import { AlertItem } from '../../../../../components';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { Roles } from '../../../../../types/Roles';
import { DrawerMode } from '../../../../../types/DrawerMode';

type Props = {
  selectedAppointment?: Appointments | null;
};

const TreatmentSchema = yup.object().shape({});

const TreatmentForm: React.FC<Props> = ({ selectedAppointment }) => {
  const [createTreatment] = useTreatmentCreateMutation();
  const [treatmentUpdate] = useTreatmentUpdateMutation();
  const dispatch = useDispatch();
  const userInfo = useAppSelector(state => state.auth);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
  } = useFormik<Treatment>({
    initialValues: {
      isMedicalRecords: false,
      isTestResults: false,
      isFilms: false,
      isOther: false,
      isAllergies: false,
      isOtherAggravatingFactors: false,
      isPreviousOperations: false,
      hpi: '',
      painLocation: {
        frontSide: {
          head: false,
          chest: false,
          neck: false,
          stomach: false,
          shin: false,
          rightHand: false,
          leftHand: false,
          rightLeg: false,
          leftLeg: false,
        },
        backSide: {
          head: false,
          neck: false,
          back: false,
          shin: false,
          rightHand: false,
          leftHand: false,
          rightLeg: false,
          leftLeg: false,
        },
      },
    },
    validateOnChange: false,
    validationSchema: TreatmentSchema,
    onSubmit: () => {
      dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
      createTreatment({
        ...values,
        appointmentId: selectedAppointment?.id,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Initial treatment form was added successfully ',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });
  const [isNotUpd, setIsNotUpd] = useState(false);

  useEffect(() => {
    if (!!selectedAppointment?.treatments === false) {
      if (selectedAppointment?.status === AppointmentStatus.CANCELED) {
        setIsNotUpd(true);
      } else {
        setIsNotUpd(false);
      }
      resetForm();
    } else {
      if (
        selectedAppointment?.status === AppointmentStatus.CANCELED ||
        userInfo.role === Roles.PATIENT
      ) {
        setIsNotUpd(true);
      } else {
        setIsNotUpd(false);
      }

      setValues({
        isMedicalRecords: selectedAppointment?.treatments?.isMedicalRecords,
        isTestResults: selectedAppointment?.treatments?.isTestResults,
        isFilms: selectedAppointment?.treatments?.isFilms,
        isOther: selectedAppointment?.treatments?.isOther,
        isAllergies: selectedAppointment?.treatments?.isAllergies,
        isOtherAggravatingFactors:
          selectedAppointment?.treatments?.isOtherAggravatingFactors,
        isPreviousOperations: selectedAppointment?.treatments?.isPreviousOperations,
        hpi: selectedAppointment?.treatments?.hpi,
        painLocation: {
          frontSide: {
            head: selectedAppointment?.treatments?.painLocation?.frontSide.head,
            chest: selectedAppointment?.treatments?.painLocation?.frontSide.chest,
            neck: selectedAppointment?.treatments?.painLocation?.frontSide.neck,
            stomach: selectedAppointment?.treatments?.painLocation?.frontSide.stomach,
            shin: selectedAppointment?.treatments?.painLocation?.frontSide.shin,
            rightHand: selectedAppointment?.treatments?.painLocation?.frontSide.rightHand,
            leftHand: selectedAppointment?.treatments?.painLocation?.frontSide.leftHand,
            rightLeg: selectedAppointment?.treatments?.painLocation?.frontSide.rightLeg,
            leftLeg: selectedAppointment?.treatments?.painLocation?.frontSide.leftLeg,
          },
          backSide: {
            head: selectedAppointment?.treatments?.painLocation?.backSide.head,
            neck: selectedAppointment?.treatments?.painLocation?.backSide.neck,
            back: selectedAppointment?.treatments?.painLocation?.backSide.back,
            shin: selectedAppointment?.treatments?.painLocation?.backSide.shin,
            rightHand: selectedAppointment?.treatments?.painLocation?.backSide.rightHand,
            leftHand: selectedAppointment?.treatments?.painLocation?.backSide.leftHand,
            rightLeg: selectedAppointment?.treatments?.painLocation?.backSide.rightLeg,
            leftLeg: selectedAppointment?.treatments?.painLocation?.backSide.leftLeg,
          },
        },
      });
    }
  }, [selectedAppointment]);

  const validateFirstBlock = useMemo(
    () =>
      values.isMedicalRecords || values.isFilms || values.isTestResults || values.isOther,
    [values.isMedicalRecords, values.isFilms, values.isTestResults, values.isOther],
  );

  const validateSecondBlock = useMemo(
    () =>
      values.isAllergies ||
      values.isOtherAggravatingFactors ||
      values.isPreviousOperations,
    [values.isAllergies, values.isOtherAggravatingFactors, values.isPreviousOperations],
  );

  const handleUpdateTreatment = () => {
    dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
    if (selectedAppointment?.treatments) {
      treatmentUpdate({
        id: selectedAppointment?.treatments?.id,
        ...values,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Initial treatment form was updated successfully ',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.ContentWrapper>
        <S.Content>
          <S.Article>Initial Treatment form</S.Article>
          <S.AlertWrapper>
            <AlertItem
              text={'Please fill in the mandatory fields marked with *'}
              type="info"
            />
          </S.AlertWrapper>
          <S.TreatmentInfo>
            <S.SubArticle>
              Please check if you brought the following <S.RequiredSpan>*</S.RequiredSpan>
            </S.SubArticle>
            <S.Items>
              <S.CheckBoxItem>
                <CheckBox
                  id="isMedicalRecords"
                  name="isMedicalRecords"
                  value={values.isMedicalRecords}
                  checked={values.isMedicalRecords}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Medical Records</S.CheckBoxLabel>
              </S.CheckBoxItem>
              <S.CheckBoxItem>
                <CheckBox
                  id="isFilms"
                  name="isFilms"
                  value={values.isFilms}
                  checked={values.isFilms}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Films</S.CheckBoxLabel>
              </S.CheckBoxItem>
              <S.CheckBoxItem>
                <CheckBox
                  id="isTestResults"
                  name="isTestResults"
                  checked={values.isTestResults}
                  value={values.isTestResults}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Test Results</S.CheckBoxLabel>
              </S.CheckBoxItem>
              <S.CheckBoxItem>
                <CheckBox
                  id="isOther"
                  name="isOther"
                  checked={values.isOther}
                  value={values.isOther}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Other</S.CheckBoxLabel>
              </S.CheckBoxItem>
            </S.Items>
            <S.SubArticle>HPI (History of Present Illness)</S.SubArticle>
            <S.HelperText>
              Please provide a brief overview of your pain history
            </S.HelperText>
            <S.InputWrapper>
              <Input
                value={values.hpi}
                onChange={handleChange}
                multiline={true}
                rows={5}
                label=""
                id="hpi"
                name="hpi"
                error={!!errors.hpi}
                helperText={errors.hpi}
                disabled={isNotUpd}
              />
            </S.InputWrapper>
            <S.SubArticle>
              Special attention <S.RequiredSpan>*</S.RequiredSpan>
            </S.SubArticle>
            <S.Items>
              <S.CheckBoxItem>
                <CheckBox
                  id="isAllergies"
                  name="isAllergies"
                  value={values.isAllergies}
                  checked={values.isAllergies}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Have allergies </S.CheckBoxLabel>
              </S.CheckBoxItem>
              <S.CheckBoxItem>
                <CheckBox
                  id="isOtherAggravatingFactors"
                  name="isOtherAggravatingFactors"
                  value={values.isOtherAggravatingFactors}
                  checked={values.isOtherAggravatingFactors}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Other aggravating factors</S.CheckBoxLabel>
              </S.CheckBoxItem>
              <S.CheckBoxItem>
                <CheckBox
                  id="isPreviousOperations"
                  name="isPreviousOperations"
                  checked={values.isPreviousOperations}
                  value={values.isPreviousOperations}
                  onChange={handleChange}
                  disabled={isNotUpd}
                />
                <S.CheckBoxLabel>Previous operations</S.CheckBoxLabel>
              </S.CheckBoxItem>
            </S.Items>
            <S.SubArticle>Please indicate the location of your pain below</S.SubArticle>
          </S.TreatmentInfo>
          <S.HumanContent>
            <S.FrontSide>Front</S.FrontSide>
            <S.FrontRight>Right</S.FrontRight>
            <S.FrontLeft>Left</S.FrontLeft>
            <S.BackSide>Back</S.BackSide>
            <S.BackLeft>Left</S.BackLeft>
            <S.BackRight>Right</S.BackRight>
            <S.HumanView>
              <S.Front>
                <FrontHeadSVG
                  className={'frontHead ' + values.painLocation?.frontSide.head}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.head',
                        !values.painLocation?.frontSide.head,
                      );
                  }}
                />
                <FrontNeckSVG
                  className={'frontNeck ' + values.painLocation?.frontSide.neck}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.neck',
                        !values.painLocation?.frontSide.neck,
                      );
                  }}
                />
                <FrontChestSVG
                  className={'frontChest ' + values.painLocation?.frontSide.chest}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.chest',
                        !values.painLocation?.frontSide.chest,
                      );
                  }}
                />
                <FrontRightArmSVG
                  className={'frontRightArm ' + values.painLocation?.frontSide.rightHand}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.rightHand',
                        !values.painLocation?.frontSide.rightHand,
                      );
                  }}
                />
                <FrontLeftArmSVG
                  className={'frontLeftArm ' + values.painLocation?.frontSide.leftHand}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.leftHand',
                        !values.painLocation?.frontSide.leftHand,
                      );
                  }}
                />
                <FrontStomachSVG
                  className={'frontStomach ' + values.painLocation?.frontSide.stomach}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.stomach',
                        !values.painLocation?.frontSide.stomach,
                      );
                  }}
                />
                <FrontHipsSVG
                  className={'frontHips ' + values.painLocation?.frontSide.shin}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.shin',
                        !values.painLocation?.frontSide.shin,
                      );
                  }}
                />
                <FrontRightLegSVG
                  className={'frontRightLeg ' + values.painLocation?.frontSide.rightLeg}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.rightLeg',
                        !values.painLocation?.frontSide.rightLeg,
                      );
                  }}
                />
                <FrontLeftLegSVG
                  className={'frontLeftLeg ' + values.painLocation?.frontSide.leftLeg}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.frontSide.leftLeg',
                        !values.painLocation?.frontSide.leftLeg,
                      );
                  }}
                />
              </S.Front>
              <S.Back>
                <BackHeadSVG
                  className={'backHead ' + values.painLocation?.backSide.head}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.head',
                        !values.painLocation?.backSide.head,
                      );
                  }}
                />
                <BackNeckSVG
                  className={'backNeck ' + values.painLocation?.backSide.neck}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.neck',
                        !values.painLocation?.backSide.neck,
                      );
                  }}
                />
                <BackChestSVG
                  className={'backChest ' + values.painLocation?.backSide.back}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.back',
                        !values.painLocation?.backSide.back,
                      );
                  }}
                />
                <BackLeftArmSVG
                  className={'backLeftArm ' + values.painLocation?.backSide.leftHand}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.leftHand',
                        !values.painLocation?.backSide.leftHand,
                      );
                  }}
                />
                <BackRightArmSVG
                  className={'backRightArm ' + values.painLocation?.backSide.rightHand}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.rightHand',
                        !values.painLocation?.backSide.rightHand,
                      );
                  }}
                />
                <BackHipsSVG
                  className={'backHips ' + values.painLocation?.backSide.shin}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.shin',
                        !values.painLocation?.backSide.shin,
                      );
                  }}
                />

                <BackLeftLegSVG
                  className={'backLeftLeg ' + values.painLocation?.backSide.leftLeg}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.leftLeg',
                        !values.painLocation?.backSide.leftLeg,
                      );
                  }}
                />
                <BackRightLegSVG
                  className={'backRightLeg ' + values.painLocation?.backSide.rightLeg}
                  onClick={() => {
                    !isNotUpd &&
                      setFieldValue(
                        'painLocation.backSide.rightLeg',
                        !values.painLocation?.backSide.rightLeg,
                      );
                  }}
                />
              </S.Back>
            </S.HumanView>
          </S.HumanContent>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            {selectedAppointment?.status === AppointmentStatus.CANCELED ||
            selectedAppointment?.treatments ? (
              <>
                {userInfo.role !== Roles.PATIENT &&
                selectedAppointment?.status === AppointmentStatus.CANCELED ? (
                  <Button
                    text="Back"
                    backgroundColor="#848A9B"
                    onClick={() =>
                      dispatch(
                        showDrawer({
                          show: false,
                          mode: DrawerMode.DEFAULT,
                          props: null,
                        }),
                      )
                    }
                  />
                ) : (
                  <Button
                    text="Update"
                    onClick={() => handleUpdateTreatment()}
                    disabled={!(validateFirstBlock && validateSecondBlock)}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  text="Save"
                  type="submit"
                  disabled={!(validateFirstBlock && validateSecondBlock)}
                />
              </>
            )}
          </S.BtnWrapper>
        </S.Footer>
      </S.ContentWrapper>
    </form>
  );
};

export default TreatmentForm;
