import styled from 'styled-components';

export const ItemsWrap = styled.div`
  padding: 23px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 50px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
`;

export const Article = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
`;
