import dayjs from 'dayjs';
import * as S from '../styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { ChangeEvent, useState } from 'react';
import { ClickAwayListener, IconButton, InputAdornment } from '@mui/material';
import InputCell from 'components/atoms/InputCell';
import { useInvoiceItemUpdateMutation } from 'store/api/invoices/invoiceApi';
import { setMessage } from 'store/slices/message';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { AccessLevel } from 'types/enums/AppointmentEnum';
import { ChooseDoctorOrClinic } from './ChooseDoctorOrClinic';
import { ChooseProviderTab } from 'constants/constants';
import { ChooseInjuryCode } from 'pages/Billing/components/Claims/components/ClaimForm/components/AlphabetLines/ChooseInjuryCode';
import { ChooseCptCode } from 'pages/Billing/components/Claims/components/ClaimForm/components/ServicesTable/ChooseCptCode';
import { ItemInInvoiceSheetType } from 'types/InvoiceType';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { useAppointmentGetOneMutation } from 'store/api/appointment/appointmentApi';

type Props = {
  row: ItemInInvoiceSheetType;
  header: string;
};

const InvInfData: React.FC<Props> = ({ row, header }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const { invoiceSheetAccessLevel } = useAppSelector(state => state.auth);

  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState('');

  const [editAmount, setEditAmount] = useState(false);
  const [amount, setAmount] = useState(0);

  const [editCpt, setEditCpt] = useState(false);
  const [editICD, setEditICD] = useState(false);
  const [editRenderingProvider, setEditRenderingProvider] = useState(false);
  const [editServiceLocation, setEditServiceLocation] = useState(false);
  const [editBillingProvider, setEditBillingProvider] = useState(false);
  const [editBillingLocation, setEditBillingLocation] = useState(false);

  const [changeItem] = useInvoiceItemUpdateMutation({});
  const [getAppointmentOne] = useAppointmentGetOneMutation({});

  const handleSave = ({ ...params }) => {
    changeItem({
      ...params,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Invoice item was updated successfully',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleAppointmentClick = (id: string) => {
    getAppointmentOne({ id })
      .unwrap()
      .then(res => {
        dispatch(
          showDrawer({
            show: true,
            mode: DrawerMode.INFO_APPOINTMENT,
            props: res,
          }),
        );
      });
  };

  switch (header) {
    case 'SIGS': {
      return (
        <S.CellWrapper className={row.appointment ? 'cursor' : ''}>
          <S.Link
            onClick={() =>
              row.appointmentId ? handleAppointmentClick(row.appointmentId) : undefined
            }
          >
            {row?.date ? dayjs(row?.date).format('MM/DD/YYYY') : '-'}{' '}
          </S.Link>
          {row.claim.claimType === 'System' && row.appointment?.countSystemClaims
            ? `(${row.appointment?.countSystemClaims})`
            : row.appointment?.countManualClaims
              ? `(${row.appointment?.countManualClaims})`
              : ''}
        </S.CellWrapper>
      );
    }
    case 'ICD': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE && setEditICD(true)
            }
          >
            {row?.icd?.at(0)?.code ? row?.icd?.at(0)?.code : '-'}
          </S.CellWrapper>
          <Dialog open={editICD} onClose={() => setEditICD(false)}>
            <ChooseInjuryCode
              onApplyClick={code => {
                handleSave({
                  id: row.id,
                  icd: [{ type: 'icd-10', code }],
                  invoiceId: Number(params.invoiceId),
                });
                setEditICD(false);
              }}
              title={'ICD-10 Codes'}
              currentValue={row?.icd?.at(0)?.code}
            />
          </Dialog>
        </>
      );
    }
    case 'CPT': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE && setEditCpt(true)
            }
          >
            {row.cpt?.length ? row.cpt?.at(0) : '-'}
          </S.CellWrapper>
          <Dialog open={editCpt} onClose={() => setEditCpt(false)}>
            <ChooseCptCode
              onApplyClick={code => {
                handleSave({
                  id: row.id,
                  cpt: [code],
                  invoiceId: Number(params.invoiceId),
                });
                setEditCpt(false);
              }}
              title={'CPT Codes'}
              currentValue={row.cpt?.at(0)}
            />
          </Dialog>
        </>
      );
    }
    case 'RenderingProvider': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE &&
              setEditRenderingProvider(true)
            }
          >
            {row.doctor?.profile?.firstName
              ? row.doctor?.profile?.firstName + ' ' + row.doctor?.profile?.lastName
              : '-'}
          </S.CellWrapper>

          <Dialog
            open={editRenderingProvider}
            onClose={() => setEditRenderingProvider(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    doctorId: id,
                    invoiceId: Number(params.invoiceId),
                  });
                setEditRenderingProvider(false);
              }}
              title={'Rendering Provider'}
              onlyDoctor
              currentValue={row?.doctor?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'ServiceLoc': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE &&
              setEditServiceLocation(true)
            }
          >
            {row.clinic?.name ? row.clinic?.name : '-'}
          </S.CellWrapper>

          <Dialog
            open={editServiceLocation}
            onClose={() => setEditServiceLocation(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    clinicId: id,
                    invoiceId: Number(params.invoiceId),
                  });
                setEditServiceLocation(false);
              }}
              title={'Service Loc'}
              onlyClinic
              currentValue={row?.clinic?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'BillingProvider': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE &&
              setEditBillingProvider(true)
            }
          >
            {row.billingProviderId
              ? row.billingProvider?.name
              : row.billingProviderAsDoctorId
                ? row.billingProviderAsDoctor?.profile?.firstName +
                  ' ' +
                  row.billingProviderAsDoctor?.profile?.lastName
                : '-'}
          </S.CellWrapper>

          <Dialog
            open={editBillingProvider}
            onClose={() => setEditBillingProvider(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={(id, tab) => {
                !!id &&
                  handleSave({
                    id: row.id,
                    billingProviderId: tab === ChooseProviderTab.Clinic ? id : null,
                    billingProviderAsDoctorId:
                      tab === ChooseProviderTab.Doctor ? id : null,
                    invoiceId: Number(params.invoiceId),
                  });
                setEditBillingProvider(false);
              }}
              currentValue={
                row.billingProviderId
                  ? row.billingProviderId
                  : row.billingProviderAsDoctorId
                    ? row.billingProviderAsDoctorId
                    : undefined
              }
              defaultDoctorTab={!!row.billingProviderAsDoctorId}
            />
          </Dialog>
        </>
      );
    }
    case 'BillingLoc': {
      return (
        <>
          <S.CellWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={() =>
              invoiceSheetAccessLevel === AccessLevel.WRITE &&
              setEditBillingLocation(true)
            }
          >
            {row.billingLocation?.name ? row.billingLocation?.name : '-'}
          </S.CellWrapper>

          <Dialog
            open={editBillingLocation}
            onClose={() => setEditBillingLocation(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    billingLocationId: id,
                    invoiceId: Number(params.invoiceId),
                  });
                setEditBillingLocation(false);
              }}
              title={'Billing Loc'}
              onlyClinic
              currentValue={row?.billingLocation?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'ChargeAmount': {
      return (
        <S.CellWrapper
          editable={invoiceSheetAccessLevel}
          onDoubleClick={() => {
            if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
              setAmount(row.chargeAmount || 0);
              setEditAmount(true);
            }
          }}
        >
          {editAmount ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditAmount(false);
                editAmount &&
                  amount !== row.chargeAmount &&
                  !(amount === 0 && row.chargeAmount === null) &&
                  handleSave({
                    id: row.id,
                    chargeAmount: +amount,
                    invoiceId: Number(params.invoiceId),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disableRipple={true}
                          aria-label="toggle password visibility"
                        >
                          USD
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={amount}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAmount(+e.target.value)
                  }
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{(row?.chargeAmount ?? 0) + ' USD'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Notes': {
      return (
        <S.CellWrapper
          editable={invoiceSheetAccessLevel}
          onDoubleClick={() => {
            if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
              setNote(row.note || '');
              setEditNote(true);
            }
          }}
        >
          {editNote ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditNote(false);
                editNote &&
                  note !== row.note &&
                  !(note === '' && row.note === null) &&
                  handleSave({
                    id: row.id,
                    note: note,
                    invoiceId: Number(params.invoiceId),
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  multiline
                  rows={3}
                  value={note}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row.note ? row.note : '-'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Status': {
      return <S.Link className={'simply'}>{row.claim?.status}</S.Link>;
    }
    case 'Claim': {
      return (
        <S.Link onClick={() => !!row.claimId && navigate(`/claim-form/${row.claimId}`)}>
          {row.claimId ? '#' + row.claimId : '-'}
        </S.Link>
      );
    }

    default:
      // @ts-ignore
      return row[header] || '-';
  }
};

export default InvInfData;
