import { getBackgroundColors } from '../../../helpers/getAppointmetColor';
import * as S from './styles';

type Props = {
  type: string;
  isFirstTimeVisit?: boolean;
};

const StatusPin: React.FC<Props> = ({ type, isFirstTimeVisit = false }) => {
  const background = getBackgroundColors(type, isFirstTimeVisit).backgroundColor;
  const border = getBackgroundColors(type, isFirstTimeVisit).borderColor;
  const font = getBackgroundColors(type, isFirstTimeVisit).fontColor;
  const text = getBackgroundColors(type, isFirstTimeVisit).text;

  return (
    <S.StatusPinWrapper border={border} background={background} font={font}>
      {text}
    </S.StatusPinWrapper>
  );
};

export default StatusPin;
