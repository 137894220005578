import { useNavigate } from 'react-router-dom';
import {
  CompleteIconSVG,
  EditBtnControlledSVG,
  EditBtnMinimalSVG,
  EditBtnSVG,
  EmptyIconSVG,
  HealthRecordsSVG,
  ProfileColorSVG,
} from '../../../../../assets/icons';
import * as S from './styles';
import { AppointmentProcessInterface } from '../../../../../types/AppointmentNewTypes';
import CheckBox from '../../../../atoms/CheckBox';
import CheckBoxWithLabel from '../../../../atoms/CheckboxWithLabel';
import {
  useGetTemplateListMutation,
  useSetStepAsCurrentMutation,
} from '../../../../../store/api/start-progress/startProgressApi';
import CircularLoader from '../../../../atoms/CircuralLoader';
import { Dialog } from '../../../..';
import DialogStartAppointment from '../../../AppointmentDrawer/components/DialogStartAppointment';
import { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import DialogEditAppointmentProcess from '../../../AppointmentDrawer/components/DialogEditAppointmentProcess';

type Props = {
  data?: AppointmentProcessInterface;
  changeStep: (id: string) => void;
  isLoading?: boolean;
  refetch: () => void;
};

const TemplateList: React.FC<Props> = ({ data, changeStep, isLoading, refetch }) => {
  const navigate = useNavigate();
  const [isOpenStartProcessDialog, setIsOpenStartProcessDialog] = useState(false);
  const [getTemplateList, templateListStatus] = useGetTemplateListMutation({});
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  return (
    <S.TemplateList>
      <Dialog
        open={isOpenStartProcessDialog}
        onClose={() => setIsOpenStartProcessDialog(!isOpenStartProcessDialog)}
      >
        <DialogEditAppointmentProcess
          onClose={() => {
            setIsOpenStartProcessDialog(!isOpenStartProcessDialog);
          }}
          refetch={refetch}
          isLoading={isLoading}
          steps={data?.steps}
          data={templateListStatus?.data}
          appointment={data?.appointment}
        />
      </Dialog>
      <S.NavigateButtons>
        <S.LinkButton
          onClick={() => navigate(`/patients/${data?.appointment?.patient?.id}`)}
        >
          <S.LinkContent>
            <ProfileColorSVG />
            <S.Text>Patient profile info</S.Text>
          </S.LinkContent>
        </S.LinkButton>
        <S.LinkButton>
          <S.LinkContent
            onClick={() =>
              navigate(`/patients/${data?.appointment?.patient?.id}`, {
                state: { startedAppointment: data?.appointment },
              })
            }
          >
            <HealthRecordsSVG />
            <S.Text>Health records</S.Text>
          </S.LinkContent>
        </S.LinkButton>
      </S.NavigateButtons>

      <S.Templates>
        <S.Article>Templates list</S.Article>
        {isLoading ? (
          <S.Loader>
            <CircularLoader color="#0084B1" />
          </S.Loader>
        ) : (
          <>
            {data?.steps &&
              data.steps.map(item => {
                return (
                  <S.TempRow
                    key={item.id}
                    $isCurrent={item.isCurrent}
                    onClick={() =>
                      !item.isCurrent && !item.isCompleted && changeStep(item.id)
                    }
                  >
                    <S.TempRowContent>
                      {item.isCompleted ? <CompleteIconSVG /> : <EmptyIconSVG />}
                      <S.Text>{item.clinicTemplate.template.name}</S.Text>
                    </S.TempRowContent>
                  </S.TempRow>
                );
              })}
          </>
        )}
      </S.Templates>

      <S.EditButtonWrapper
        onClick={() => {
          console.log(data);
          getTemplateList({
            appointmentId: data?.appointmentId,
            clinicId: selectedClinic.id,
          });
          setIsOpenStartProcessDialog(true);
        }}
      >
        <EditBtnControlledSVG color="#202E5F" />
        Edit template list
      </S.EditButtonWrapper>
    </S.TemplateList>
  );
};

export default TemplateList;
