import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, CircularLoader, Input, SelectInput } from '../../../../../../components';
import * as S from './styles';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Clinics } from '../../../../../../types/ClinicTypes';
import { ClinicInsuranceType } from '../../../../../../types/enums/AppointmentEnum';
import { getNetworkTypeName } from '../../../../../../helpers/functions/getInOutNetworkName';
import { ClearFiltersSVG, PlaceholderAttentionSVG } from '../../../../../../assets/icons';
import {
  useAssignInsuranceToDoctorMutation,
  useChangeHealthCareMutationMutation,
  useChangeHealthCareQuery,
  useUpdateInsuranceDoctorMutation,
} from '../../../../../../store/api/staff/staffApi';
import { useDebounce } from 'use-debounce';
import SearchContentRow from './components/SearchContentRow';
import ReactInputMask from 'react-input-mask';
import { useInsuranceAddMutation } from '../../../../../../store/api/insuranceRelative/insuranceRelativeApi';
import { setMessage } from '../../../../../../store/slices/message';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { InsuranceToDoctor } from '../InsuranceTo/InsuranceTo';

type Row = {
  payerId: string;
  payerName: string;
};

type Props = {
  onClose: () => void;
  assignClinics?: Array<Clinics>;
  doctorId?: string;
  editedInsurance?: InsuranceToDoctor | null;
};

const DialogAddInsuranceTo: React.FC<Props> = ({
  onClose,
  assignClinics,
  doctorId,
  editedInsurance,
}) => {
  const [clinic, setClinic] = useState('');
  const [type, setType] = useState<ClinicInsuranceType | string>('');
  const [payerId, setPayerId] = useState('');
  const [payerName, setPayerName] = useState('');

  const [debouncedPayerId] = useDebounce(payerId, 500);
  const [debouncedPayerName] = useDebounce(payerName, 500);

  const [getPayerList, payerStatus] = useChangeHealthCareMutationMutation({});
  const [addInsurance] = useAssignInsuranceToDoctorMutation({});
  const [editInsurance] = useUpdateInsuranceDoctorMutation({});

  const [selectedRow, setSelectedRow] = useState<Row | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      (debouncedPayerId || (debouncedPayerName && debouncedPayerName.length > 2)) &&
      !selectedRow &&
      !(debouncedPayerName as string).includes('_') &&
      !(debouncedPayerId as string).includes('_')
    ) {
      getPayerList({
        ...(debouncedPayerId && { payerID: debouncedPayerId }),
        ...(debouncedPayerName && { payerName: debouncedPayerName }),
      });
    }
  }, [debouncedPayerId, debouncedPayerName, selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      setPayerId(selectedRow.payerId);
      setPayerName(selectedRow.payerName);
    }
  }, [selectedRow]);

  const disableButton = useMemo(
    () => !clinic || !type || !selectedRow,
    [clinic, type, selectedRow],
  );

  useEffect(() => {
    if (editedInsurance) {
      setClinic(editedInsurance.clinicUser.clinicId);
      setType(editedInsurance.type);

      setSelectedRow({
        payerId: editedInsurance.payerId,
        payerName: editedInsurance.payerName,
      });
    }
  }, [editedInsurance]);

  return (
    <S.Content>
      <S.Article>{editedInsurance ? 'Edit Insurance' : 'Add Insurance'}</S.Article>
      <S.InputWrapper>
        <SelectInput
          label="Clinic"
          id="clinics"
          name="clinics"
          value={clinic}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setClinic(e.target.value as string);
          }}
        >
          {assignClinics &&
            assignClinics.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
        </SelectInput>
      </S.InputWrapper>
      <S.InputWrapper>
        <SelectInput
          label="Type"
          value={type}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setType(e.target.value as ClinicInsuranceType);
          }}
        >
          <MenuItem value={ClinicInsuranceType.IN_NETWORK}>
            <S.MenuItemContent>
              {getNetworkTypeName(ClinicInsuranceType.IN_NETWORK)}
            </S.MenuItemContent>
          </MenuItem>
          <MenuItem value={ClinicInsuranceType.OUT_OF_NETWORK}>
            <S.MenuItemContent>
              {getNetworkTypeName(ClinicInsuranceType.OUT_OF_NETWORK)}
            </S.MenuItemContent>
          </MenuItem>
        </SelectInput>
      </S.InputWrapper>
      <S.FilterBody>
        {selectedRow && (
          <S.SVG onClick={() => setSelectedRow(null)}>
            <ClearFiltersSVG />
            Change Payer Information
          </S.SVG>
        )}
        <S.InputWrapper>
          <ReactInputMask
            mask={'*****'}
            value={payerId}
            maskPlaceholder={'_'}
            disabled={selectedRow ? true : false}
            onChange={e => {
              setPayerId(e.target.value);
            }}
          >
            <Input label="PayerID" placeholder="PayerID" />
          </ReactInputMask>
          <Input
            label="Payer Name (min 3 symbols)"
            placeholder="Payer Name"
            value={payerName}
            disabled={selectedRow ? true : false}
            onChange={e => setPayerName(e.target.value)}
          />
        </S.InputWrapper>
        {!selectedRow && (
          <>
            <S.SearchResultDiv>
              <S.SearchText>
                Search result: {payerStatus.data?.searchResult || '0'}
              </S.SearchText>
            </S.SearchResultDiv>
            <S.BodyResult>
              {payerStatus.isLoading ? (
                <CircularLoader size={24} color="#0084B1" />
              ) : payerStatus.data?.searchResult && payerStatus.data?.searchResult > 0 ? (
                payerStatus.data?.row.map((item: Row, index) => {
                  return (
                    <SearchContentRow
                      key={item.payerId + item.payerName}
                      row={item}
                      index={index}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  );
                })
              ) : (
                <S.Placeholder>
                  <PlaceholderAttentionSVG />
                  <S.PlaceholderText>
                    No result. Please, use filter to find insurance company
                  </S.PlaceholderText>
                </S.Placeholder>
              )}
            </S.BodyResult>
          </>
        )}
      </S.FilterBody>
      <S.ButtonWrapper>
        {editedInsurance ? (
          <Button
            disabled={disableButton}
            text="Edit"
            onClick={() => {
              onClose();
              editInsurance({
                doctorId: doctorId,
                id: editedInsurance.id,
                type: type,
                clinicId: clinic,
                payerId: payerId,
                payerName: payerName,
              })
                .unwrap()
                .then(res => {
                  dispatch(
                    setMessage({
                      message: 'Insurance wass successfully updated',
                      type: 'success',
                    }),
                  );
                })
                .catch(error => {
                  dispatch(
                    setMessage({
                      message: error.data.message,
                      type: 'error',
                    }),
                  );
                });
            }}
          />
        ) : (
          <Button
            disabled={disableButton}
            text="Add"
            onClick={() => {
              onClose();
              addInsurance({
                doctorId: doctorId,
                type: type,
                clinicId: clinic,
                payerId: payerId,
                payerName: payerName,
              })
                .unwrap()
                .then(res => {
                  dispatch(
                    setMessage({
                      message: 'Insurance wass successfully assigned',
                      type: 'success',
                    }),
                  );
                })
                .catch(error => {
                  dispatch(
                    setMessage({
                      message: error.data.message,
                      type: 'error',
                    }),
                  );
                });
            }}
          />
        )}
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default DialogAddInsuranceTo;
