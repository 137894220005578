import {
  DatePicker,
  DateValidationError,
  DatePickerSlotsComponentsProps,
  DatePickerProps,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as S from './styles';
import { FormHelperText, InputProps, TextFieldProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { isSameDay } from 'date-fns';

type Props = {
  placeholder?: string;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  isPassword?: boolean;
  label: string;
  helperText?: string;
  background?: string;
  id?: string;
  name?: string;
  error?: boolean;
  isCell?: boolean;
  isRequired?: boolean;

  daysWeekends?: Array<string> | null;
} & DatePickerProps<any>;

const DatePickerItem: React.FC<Props> = ({
  isRequired,
  label,
  width,
  error,
  background,
  isCell,
  name,
  helperText,
  daysWeekends,
  id,
  ...props
}) => {
  const [errorType, setErrorMessage] = useState<DateValidationError | null>(null);

  const CustomDay = (propsCustomDay: PickersDayProps<unknown>) => {
    if (daysWeekends) {
      const matchedStyles = daysWeekends.reduce((a, v) => {
        const date = new Date(propsCustomDay.day as Date);

        return isSameDay(date, new Date(v))
          ? {
              color: '#C66060 !important',
              backgroundColor:
                dayjs(props.value).format('MM/DD/YYYY') ===
                dayjs(propsCustomDay.day as Date).format('MM/DD/YYYY')
                  ? '#CD2828 !important'
                  : '#E3AFAF !important',
            }
          : a;
      }, {});
      return <PickersDay {...propsCustomDay} sx={{ ...matchedStyles }} />;
    } else return <PickersDay {...propsCustomDay} />;
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <S.DatePickerWrapper>
          <S.Label>
            {label} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
          </S.Label>
          <S.DatePickerItem
            className={isCell ? 'isCell' : ''}
            onError={newError => setErrorMessage(newError)}
            sx={{
              backgroundColor: background || '#F9F9FC',
              border: error ? '0.5px solid red' : '',
            }}
            slots={daysWeekends ? { day: CustomDay } : {}}
            slotProps={{
              textField: {
                id: id,
                name: name,
                variant: isCell ? 'standard' : undefined,
                inputProps: {
                  style: {
                    backgroundColor: background || '#F9F9FC',
                  },
                },
              },
            }}
            {...props}
          />
          <S.FormHelperText>
            <FormHelperText>{helperText}</FormHelperText>
          </S.FormHelperText>
        </S.DatePickerWrapper>
      </LocalizationProvider>
    </>
  );
};

export default DatePickerItem;
