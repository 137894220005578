import { useEffect } from 'react';
import { useAppSelector } from '../hooks/useAppSelector';
import Auth from '../pages/Auth';
import Doctors from '../pages/Doctors';
import Receptionist from '../pages/Receptionist';
import Users from '../pages/Users';
import { Roles } from '../types/Roles';
import { useAuthMeMutation } from '../store/api/auth/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/api/auth/authSlice';
import { CircularProgress, LinearProgress } from '@mui/material';
import SuperAdmin from '../pages/SuperAdmin';
import LocalAdmin from '../pages/LocalAdmin';
import Billing from '../pages/Billing';

const RoleWrapper = () => {
  const role = useAppSelector(state => state.auth.role);
  const [authMe] = useAuthMeMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    authMe({})
      .unwrap()
      .then(res => {
        dispatch(setUser(res));
      })
      .catch(error => {
        dispatch(setUser({ role: Roles.UNAUTH }));
      });
  }, []);

  switch (role) {
    case Roles.PATIENT:
      return <Users />;
    case Roles.DOCTOR:
      return <Receptionist />;
    case Roles.RECEPTIONIST:
      return <Receptionist />;
    case Roles.UNAUTH:
      return <Auth />;
    case Roles.BILLING:
      return <Billing />;
    case Roles.SUPER_ADMIN:
      return <SuperAdmin />;
    case Roles.LOCATION_MANAGER:
      return <LocalAdmin />;
    default:
      return <LinearProgress />;
  }
};

export default RoleWrapper;
