import { useState } from 'react';
import { VisitReasons } from '../../types/StatusTypes';

export const getCalendarTypes = (type: string, isFirstTimeVisit: boolean) => {
  if (type === VisitReasons.ACUPUNCTURE && !isFirstTimeVisit) {
    return {
      backgroundColor: '#fff',
      borderColor: '#848A9B',
      fontColor: '#848A9B',
      title: 'ACU',
    };
  }

  if (type === VisitReasons.ACUPUNCTURE && isFirstTimeVisit) {
    return {
      backgroundColor: '#FFDBB0',
      borderColor: '#D17E00',
      fontColor: '#D17E00',
      title: 'ACU',
    };
  }

  if (type === VisitReasons.MASSAGE && isFirstTimeVisit) {
    return {
      backgroundColor: '#FFDBB0',
      borderColor: '#D17E00',
      fontColor: '#D17E00',
      title: 'Massage',
    };
  }

  if (type === VisitReasons.MASSAGE && !isFirstTimeVisit) {
    return {
      backgroundColor: '#EBF3DF',
      borderColor: '#79A030',
      fontColor: '#79A030',
      title: 'Massage',
    };
  }

  if (type === VisitReasons.PHYSICAL_THERAPY) {
    return {
      backgroundColor: '#EADAFF',
      borderColor: '#9747FF',
      fontColor: '#9747FF',
      title: 'PT',
    };
  }

  if (type === VisitReasons.HERBS) {
    return {
      backgroundColor: '#D0EBEF',
      borderColor: '#429AA7',
      fontColor: '#429AA7',
      title: 'Herbs',
    };
  }

  if (type === VisitReasons.OTHER) {
    return {
      backgroundColor: '#FFB7E6',
      borderColor: '#DF27A1',
      fontColor: '#DF27A1',
      title: 'Other',
    };
  }

  return {
    backgroundColor: '#FFFF',
    borderColor: '#D7D7D7',
    fontColor: '#202e5f',
  };
};
