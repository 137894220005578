import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 30px;
`;
