import { useEffect, useState, MouseEvent } from 'react';
import * as S from './styles';
import { UserProfile } from '../../../../types/UserProfileTypes';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Roles } from '../../../../types/Roles';
import {
  ApproveBtnSVG,
  ClearFiltersSVG,
  EditBtnSVG,
  FilterSVG,
  ResetApproveBtnSVG,
} from '../../../../assets/icons';
import CheckBox from '../../../atoms/CheckBox';
import ScrollContainer from '../../../molecules/ScrollContainer';
import LinearLoader from '../../../atoms/LinearLoader';
import RecordItem from '../../RecordItem';
import { LinearProgress } from '@mui/material';
import { useAppointmentGetQuery } from '../../../../store/api/appointment/appointmentApi';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { api } from '../../../../store/api/api';
import DatePicker from '../../../atoms/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from 'use-debounce';
import NewAppointmentDrawer from '../../NewAppointmentDrawer';
import { DrawerMode } from '../../../../types/DrawerMode';
import { Appointments } from '../../../../types/AppointmentsTypes';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import TreatmentForm from '../../Appointments/components/TreatmentForm';
import Drawer from '../../../atoms/Drawer';
import { AppointmentsNew } from '../../../../types/AppointmentNewTypes';

type Props = {
  rows: UserProfile | undefined;
  onHandleChangeDrawerMode: (
    mode: string,
    selectedAppointment: AppointmentsNew | null,
  ) => void;
};

const VisitHistory: React.FC<Props> = ({ rows, onHandleChangeDrawerMode }) => {
  const userInfo = useAppSelector(state => state.auth);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [isEditable, setIsEditable] = useState(false);
  const patientId = useParams().patientId;
  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>('');
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>('');

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  const allAppointments = useAppointmentGetQuery({
    ...(patientId && { 'patientId[]': patientId }),
    // q: debouncedSearch,
    ...(debouncedFirstDate &&
      !isNaN(dayjs(debouncedFirstDate).day()) && {
        visitDateTimeStart: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
      }),
    ...(debounceSecondDate &&
      !isNaN(dayjs(debounceSecondDate).day()) && {
        visitDateTimeEnd: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
      }),
    page: page,
    ...(selectedClinic?.id && { 'clinicId[]': selectedClinic.id }),
  });

  const location = useLocation().pathname;
  const isShowDrawer = useAppSelector(state => state.drawer.show);

  useEffect(() => {
    // dispatch(api.util.resetApiState());
  }, [location]);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
        return;
      }
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  useEffect(() => {
    if (secondDate && firstDate && secondDate < firstDate) {
      setFirstDate(secondDate);
      setPage(1);
    }
  }, [secondDate]);

  const handleAppointmentClick = (item: AppointmentsNew) => {
    dispatch(showDrawer({ show: true, mode: DrawerMode.INFO_APPOINTMENT, props: item }));
  };

  const handleTreatmentClick = (e: MouseEvent<HTMLDivElement>, item: AppointmentsNew) => {
    e.stopPropagation();
    dispatch(showDrawer({ show: true, mode: DrawerMode.NEW_TREATMENT, props: item }));
  };

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.RecordItems>
            {allAppointments.isLoading && !allAppointments.isFetching ? (
              <LinearLoader />
            ) : (
              <>
                <S.Header>
                  <S.DatePickerWrapper>
                    <DatePicker
                      label="Date from"
                      shouldDisableDate={date => {
                        if (secondDate && date > secondDate) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      value={firstDate}
                      onChange={value => {
                        setFirstDate(dayjs(value));
                        setPage(1);
                      }}
                      id="birthDate"
                      name="birthDate"
                    />
                  </S.DatePickerWrapper>
                  {'-'}
                  <S.DatePickerWrapper>
                    <DatePicker
                      shouldDisableDate={date => {
                        if (firstDate && date < firstDate) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      label="Date to"
                      value={secondDate}
                      onChange={value => {
                        setSecondDate(dayjs(value));
                        setPage(1);
                      }}
                      id="birthDate"
                      name="birthDate"
                    />
                  </S.DatePickerWrapper>

                  <S.ClearFilter
                    onClick={() => {
                      setFirstDate('');
                      setSecondDate('');
                      setPage(1);
                    }}
                  >
                    <ClearFiltersSVG />
                  </S.ClearFilter>
                </S.Header>
                {!allAppointments.isFetching &&
                  !allAppointments.isLoading &&
                  allAppointments.currentData?.rows.map((item, index, { length }) => {
                    const isLast =
                      index === length - 1 &&
                      allAppointments.currentData &&
                      length < allAppointments.currentData.count;
                    return (
                      <div
                        key={item.id + index}
                        ref={isLast ? setObserverTarget : undefined}
                      >
                        <RecordItem
                          key={item.id}
                          row={item}
                          onClick={e => {
                            e.stopPropagation();
                            handleAppointmentClick(item);
                          }}
                          onTreatmentClick={e => {
                            handleTreatmentClick(e, item);
                          }}
                        />
                      </div>
                    );
                  })}
                {allAppointments.isFetching && <LinearLoader />}
              </>
            )}
          </S.RecordItems>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default VisitHistory;
