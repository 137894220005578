export enum AppointmentStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum VisitReasons {
  ACUPUNCTURE = 'acupuncture',
  MASSAGE = 'massage',
  HERBS = 'herbs',
  PHYSICAL_THERAPY = 'physical_therapy',
  OTHER = 'other',
}
