import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const VisitReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

export const AppointmentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 538px;
  padding-bottom: 70px;
  @media ${devices.xs} {
    width: 375px;
  }
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 5px;
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 538px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const ArticleWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const SmallArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  width: 110px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 7px;

  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InputWrapper = styled.div`
  width: 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const ResetButtonWrapper = styled.div`
  cursor: pointer;
  user-select: none;

  padding-right: 3px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;
`;

export const LabelCheckbox = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 4px;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const PaymentInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const Inputs = styled.div`
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 21px;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const SelectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SelectInputWrap = styled.div`
  width: 240px;
`;

export const PatientInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;
  margin-top: 15px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const AppointmentInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  margin-left: 6px;
  width: 502px;
  margin-top: 15px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const InputRowSpace = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;

  @media ${devices.xs} {
    gap: 5px;

    &.devide {
      flex-wrap: wrap;
    }
  }
`;

export const HelperText = styled.div`
  color: var(--icon, #dd8500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ErrorText = styled.div`
  color: var(--icon, #b65252);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
