import { useState } from 'react';
import { AddPatientsSVG, ArrowLSVG, ArrowRSVG } from 'assets/icons';
import { Button, CustomTable, Dialog, SearchInput } from 'components';
import * as S from '../../styles';
import StaffDoctorRow from './TableData/StaffDoctorRow';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { TabsEnum } from 'types/enums/TabsEnum';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import AssignClinicDialog from '../../../ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { Roles } from 'types/Roles';

type Props = {
  deletable?: boolean;
  fromClinic?: boolean;

  isSpecialists?: boolean;
};

const StaffDoctors: React.FC<Props> = ({ deletable, isSpecialists, fromClinic }) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const params = useParams();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const getReceptionistsList = useStaffGetQuery({
    position: TabsEnum.DOCTOR,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    q: debouncedSearchValue,
    isSpecialist: isSpecialists ? true : false,
  });

  const headers = [
    { name: 'Doctor name', key: 'DoctorName' },
    { name: 'Clinic name', key: 'ClinicName' },
    { name: 'Phone', key: 'Phone' },
    ...(isSpecialists ? [{ name: 'Speciality', key: 'Speciality' }] : []),
    { name: 'Email', key: 'Email' },
    { name: 'Status', key: 'Status' },
    ...(deletable ? [{ name: '', key: 'Edit' }] : []),
  ];

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const handleShowCount = () => {
    if (
      getReceptionistsList?.currentData?.count &&
      getReceptionistsList.currentData.count > 0
    ) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getReceptionistsList?.currentData?.count &&
          getReceptionistsList.currentData.count < secondCount
            ? getReceptionistsList.currentData.count
            : secondCount}{' '}
          of {getReceptionistsList?.currentData?.count} doctors{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getReceptionistsList?.currentData?.count} doctors{' '}
        </S.PaginationText>
      );
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.DOCTOR}
          clinicId={params.clinicId}
        />
      </Dialog>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getReceptionistsList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getReceptionistsList.currentData?.page ===
                getReceptionistsList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getReceptionistsList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>

          {!fromClinic && (
            <S.ButtonWrapper>
              <Button
                text={isSpecialists ? 'Add Specialists' : 'Add Doctor'}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_DOCTOR,
                      props: isSpecialists ? true : null,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            </S.ButtonWrapper>
          )}

          {fromClinic && (
            <S.ButtonWrapper>
              <Button
                text={isSpecialists ? 'Assign Specialists' : 'Assign Doctor'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getReceptionistsList.isFetching}>
        <StaffDoctorRow
          clickable
          data={getReceptionistsList?.currentData?.rows}
          headers={headers}
          deletable={deletable}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffDoctors;
