type Props = {
  row: any;
  header: string;
};

const ModifiedInformationData = ({ row, header }: Props) => {
  switch (header) {
    case 'Description': {
      return row.key || '-';
    }
    case 'NewValue': {
      return row.to || '-';
    }
    case 'OldValue': {
      return row.from || '-';
    }
    default:
      return '-';
  }
};

export default ModifiedInformationData;
