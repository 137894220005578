import { useEffect, useState } from 'react';
import * as S from './styles';
import {
  AddRectangleSVG,
  ArrowLSVG,
  ArrowRSVG,
  CalculatorSVG,
} from '../../../../assets/icons';
import {
  Button,
  CustomTable,
  Dialog,
  LinearLoader,
  NotificationPopover,
  SearchInput,
} from '../../../../components';
import PatientTableRow from '../../../../components/organismus/CustomTable/PatientTableData/PatientTableRow';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useCashboxGetQuery } from '../../../../store/api/cash/cashboxApi';
import CashTableRow from './components/CashTableRow/CashTableRow';
import CashBoxDialog from './components/CashBoxDialog';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Clinics } from '../../../../types/ClinicTypes';
import CalculateDialog from './components/CalculateDialog';
import AddRowDialog from './components/AddRowDialog';

type Props = {};

const Cash: React.FC<Props> = () => {
  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);
  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const handleAnchorExport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElExport ? setAnchorElExport(null) : setAnchorElExport(e.currentTarget);
  };
  const dispatch = useDispatch();

  const headers = [
    { name: 'Patient Name', key: 'PatientName' },
    { name: 'Appointment', key: 'Appointment' },
    { name: 'Date', key: 'Date' },
    { name: 'Credit card', key: 'CreditCard' },
    { name: 'Cash In', key: 'In' },
    { name: 'Cash Out', key: 'Out' },
    { name: 'Cash Total', key: 'Total' },
    { name: 'Note', key: 'Note' },
    { name: '', key: 'Edit' },
  ];

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const cashResult = useCashboxGetQuery({
    clinicId: selectedClinic.id,
    q: debouncedSearch,
    page: page,
  });

  const handleShowCount = () => {
    if (cashResult?.currentData?.count && cashResult.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {cashResult?.currentData?.count && cashResult.currentData.count < secondCount
            ? cashResult.currentData.count
            : secondCount}{' '}
          of {cashResult?.currentData?.count} cash records{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {cashResult?.currentData?.count} cash records{' '}
        </S.PaginationText>
      );
    }
  };

  const [isShowDialogCashBox, setIsShowDialogCashBox] = useState(false);
  const [isShowCalculateDialog, setIsShowCalculateDialog] = useState(false);
  const [isShowAddRowDialog, setIsShowAddRowDialog] = useState(false);

  const [currentRow, setCurrentRow] = useState<string | null>('');

  const handleShowDialogCashBox = () => {
    setIsShowDialogCashBox(!isShowDialogCashBox);
  };

  const handleShowCalculateDialog = () => {
    setIsShowCalculateDialog(!isShowCalculateDialog);
  };

  const handleCurrentRow = (value: string) => {
    setCurrentRow(value);
  };

  return (
    <S.Wrapper>
      <Dialog open={isShowDialogCashBox} onClose={handleShowDialogCashBox}>
        <CashBoxDialog onClose={handleShowDialogCashBox} data={currentRow} />
      </Dialog>
      <Dialog open={isShowCalculateDialog} onClose={handleShowCalculateDialog}>
        <CalculateDialog onClose={handleShowCalculateDialog} />
      </Dialog>
      <Dialog
        open={isShowAddRowDialog}
        onClose={() => setIsShowAddRowDialog(!isShowAddRowDialog)}
      >
        <AddRowDialog onClose={() => setIsShowAddRowDialog(!isShowAddRowDialog)} />
      </Dialog>
      <S.SubtitleBar>
        <S.SubtitleText>Cash box</S.SubtitleText>
      </S.SubtitleBar>
      <S.Content>
        <S.HeaderTable>
          <S.PaginationWrapper>
            <S.Arrows>
              <ArrowLSVG
                onClick={handleBack}
                className={cashResult.currentData?.page === 1 ? 'disabled' : ''}
              />
              <ArrowRSVG
                onClick={handleForward}
                className={
                  cashResult.currentData?.page === cashResult.currentData?.lastPage
                    ? 'disabled'
                    : ''
                }
              />
            </S.Arrows>
            {cashResult.isFetching ? <div>...</div> : handleShowCount()}
          </S.PaginationWrapper>
          <S.SearchItems>
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
            <S.ButtonWrapper>
              <Button
                text="Calculate"
                backgroundColor="#848A9B"
                onClick={() => {
                  handleShowCalculateDialog();
                }}
              >
                <CalculatorSVG />
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                text="Add"
                onClick={() => {
                  handleCurrentRow('');
                  setIsShowAddRowDialog(!isShowAddRowDialog);
                }}
              >
                <AddRectangleSVG />
              </Button>
            </S.ButtonWrapper>
          </S.SearchItems>
        </S.HeaderTable>
        {/* {patients.isFetching ? (
          <LinearLoader />
        ) : ( */}
        <S.TableBody>
          <CustomTable headers={headers} isFetching={cashResult.isFetching}>
            <CashTableRow
              data={cashResult?.currentData?.rows}
              headers={headers}
              handleCurrentRow={(value: string) => handleCurrentRow(value)}
              handleShowDialogCashBox={handleShowDialogCashBox}
            />
          </CustomTable>
        </S.TableBody>
      </S.Content>
    </S.Wrapper>
  );
};

export default Cash;
