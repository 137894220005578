import { FormikProps } from 'formik';
import AutoCompleteInput from '../../../../../atoms/AutoComplete';
import Input from '../../../../../atoms/Input';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import OptionContent from '../OptionContent';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { AppointmentsNew } from '../../../../../../types/AppointmentNewTypes';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { useUserDeepSearchMutMutation } from '../../../../../../store/api/searchApi/searchApi';
import { useDebounce } from 'use-debounce';
import { OptionType } from '../OptionContent/OptionContent';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  clinic?: string;
  error?: string;
  appointmentInformation?: AppointmentsNew;
};

const LastName = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      clinic,
      error,
      appointmentInformation,
    },
    ref,
  ) => {
    const [searchValueLN, setSearchValueLN] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValueLN, 500);

    const [allPatientsGet, allPatientsStatus] = useUserDeepSearchMutMutation({});

    const [data, setData] = useState([]);

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      if (
        !userProfile &&
        !duplAppointment &&
        !appointmentInformation &&
        (!selectedAppointment || selectedAppointment?.isPossibleToUpdate)
      ) {
        allPatientsGet({
          lastName: debouncedSearchValue,
          page: 1,
          ...(clinic && { clinicId: clinic }),
        })
          .unwrap()
          .then(res => setData(res?.rows));
      }
    }, [debouncedSearchValue, clinic]);

    useEffect(() => {
      if (selectedValue) {
        formik.setValues({
          ...formik.values,
          firstName: selectedValue.profile?.firstName || '',
          lastName: selectedValue.profile?.lastName || '',
          phone: selectedValue?.phone || '',
          email: selectedValue?.email || '',
          userId: selectedValue?.id || '',
          dateOfBirth: selectedValue.profile?.dateOfBirth || '',
        });
      }
    }, [selectedValue]);

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('lastName', searchValueLN);
      }
    }, [searchValueLN]);

    return (
      <>
        {byClick ? (
          <AutoCompleteInput
            data={data || []}
            isRequired
            customRender={true}
            mainLabel={'Last name'}
            loading={allPatientsStatus.isLoading ? true : false}
            searchValue={searchValueLN}
            options={data || []}
            setSearchValue={setSearchValueLN}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            disabled={
              !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
            }
            getOptionLabel={(option: any) =>
              !selectedValue
                ? formik?.values?.lastName || ''
                : option && option?.profile?.lastName
            }
            renderOption={(props: any, option: any) => {
              return (
                <OptionContent
                  type={OptionType.LASTNAME}
                  searchValue={debouncedSearchValue}
                  textToHighlight={option.profile?.lastName || ''}
                  key={option.id}
                  otherText={option.profile?.firstName || ''}
                  props={props}
                  option={option}
                  loading={allPatientsStatus.isLoading}
                />
              );
            }}
          />
        ) : (
          <Input
            label="Last name"
            id="lastName"
            isRequired
            name="lastName"
            error={error ? true : false}
            helperText={formik.errors.lastName as string}
            value={formik.values.lastName}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.lastName ||
              !!selectedAppointment?.patient?.profile?.lastName ||
              !!duplAppointment?.patient?.profile?.lastName
            }
          />
        )}
      </>
    );
  },
);

export default LastName;
