import dayjs from 'dayjs';
import * as S from '../../Invoices/styles';
import { UserStatusPin } from '../../../../../components';
import { AvatarSVG } from '../../../../../assets/icons';
import { ucFirst } from '../../../../../helpers/functions/toUpperCase';

type Props = {
  row: any;
  header: string;
};

const InvoicePostpaymentData: React.FC<Props> = ({ row, header }) => {
  //console.log(row);
  switch (header) {
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin type={row.status} />
        </S.CellWrapper>
      );
    }

    case 'Clinic': {
      return row.clinic?.name || '-';
    }

    case 'Year': {
      return row.year || '-';
    }
    case 'Charged': {
      return row?.totalPaidAmount ? row?.totalPaidAmount + ' USD' : '0 USD';
    }
    case 'Number': {
      return row.totalItemsCount || '0';
    }
    case 'Name': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.patient?.avatar ? (
              <img src={row.patient.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          {row.patient.profile?.firstName + ' ' + row.patient.profile?.lastName || '-'}
        </S.CellWrapper>
      );
    }

    default:
      return row[header] || '-';
  }
};

export default InvoicePostpaymentData;
