import moment from 'moment';
import { CheckWorkingTimeDiapason } from '../../../../types/AppointmentsTypes';
import dayjs from 'dayjs';

const getHoursOfWorkDay = (currentData: Array<CheckWorkingTimeDiapason>, date: Date) => {
  if (
    currentData &&
    currentData.find(
      item =>
        item.date === dayjs(date).format('YYYY-MM-DD') &&
        item.isWorkDay === true &&
        item.time.length &&
        item.time.find(time => time === moment(date).format('HH:mm:ss')),
    )
  ) {
    return true;
  } else return false;
};

export default getHoursOfWorkDay;
