import { useState } from 'react';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import SearchInput from '../../../../atoms/SearchInput';
import { AddRectangleSVG, ArrowLSVG, ArrowRSVG } from '../../../../../assets/icons';
import { useCouponsGetQuery } from '../../../../../store/api/coupons/couponsApi';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import Button from '../../../../atoms/Button';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { DrawerMode } from '../../../../../types/DrawerMode';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import CustomTable from '../../../CustomTable';
import SurveyRow from './TableData/SurveyRow';
import { useSurveyGetQuery } from '../../../../../store/api/survey/surveyApi';

const Surveys = () => {
  const headers = [
    { name: 'Title', key: 'Title' },
    { name: 'Date', key: 'Date' },
    { name: 'Link', key: 'Link' },
    { name: 'Clinic name', key: 'ClinicName' },
    { name: 'Survey recipients', key: 'NumberPart' },
    { name: '', key: 'Edit' },
  ];

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [page, setPage] = useState(1);

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useSurveyGetQuery({
    ...(selectedClinic.id && { clinicId: selectedClinic.id }),
    page: page,
    q: debouncedSearchValue,
  });

  const dispatch = useAppDispatch();

  const handleShowCount = () => {
    if (getList?.currentData?.count && getList.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getList?.currentData?.count && getList.currentData.count < secondCount
            ? getList.currentData.count
            : secondCount}{' '}
          of {getList?.currentData?.count} coupons{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getList?.currentData?.count} coupons{' '}
        </S.PaginationText>
      );
    }
  };
  return (
    <S.TableBody>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getList.currentData?.page === getList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.Btns>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            <Button
              text="Create Survey"
              onClick={() =>
                dispatch(
                  showDrawer({
                    show: true,
                    props: null,
                    mode: DrawerMode.ADD_SURVEY,
                  }),
                )
              }
            >
              <AddRectangleSVG />
            </Button>
          </S.ButtonWrapper>
        </S.Btns>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <SurveyRow data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default Surveys;
