import { useFormik } from 'formik';
import { Button, CheckBox, Input, SelectInput } from '../../../../../../../../components';
import { CoveredService, Insurance } from '../../../../../../../../types/InsuranceType';
import * as S from './styles';
import * as yup from 'yup';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { visitReasonsList } from '../../../../../../../../constants/constants';
import {
  AppointmentTypeEnum,
  ClinicInsuranceType,
} from '../../../../../../../../types/enums/AppointmentEnum';
import { transformReasonText } from '../../../../../../../../helpers/functions/getAppointmentReasonName';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import {
  useAddCoveredServiceMutation,
  useUpdateCoveredServiceMutation,
} from '../../../../../../../../store/api/insuranceRelative/insuranceRelativeApi';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../../store/slices/message';
import { useEffect } from 'react';

type Props = {
  onClose: () => void;
  row: CoveredService | null;
  insurance?: Insurance;
};

type FormValues = {
  service: string;
  type: string;
  visitAuthorizet: number;
  isUnlimited: boolean;
};

const AddCoveredServicesDialog = ({ onClose, row, insurance }: Props) => {
  const Schema = yup.object().shape({
    service: yup.string().required('Is required'),
    type: yup.string().required('Is required'),
    visitAuthorizet: yup
      .number()
      .min(0, 'Field must be greater than or equal to zero')
      .optional(),
  });
  const [addService] = useAddCoveredServiceMutation({});
  const [updateService] = useUpdateCoveredServiceMutation({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        service: row.service,
        type: row.type,
        visitAuthorizet: row.visitAuthorizet,
        isUnlimited: row.isUnlimited,
      });
    }
  }, [row]);

  const formik = useFormik<FormValues>({
    initialValues: {
      service: '',
      type: '',
      visitAuthorizet: 0,
      isUnlimited: false,
    },
    validateOnChange: true,
    validationSchema: Schema,
    onSubmit: () => {
      row
        ? updateService({
            id: row.id,
            service: formik.values.service,
            isUnlimited: formik.values.isUnlimited,
            type: formik.values.type,
            visitAuthorizet: formik.values.isUnlimited
              ? 0
              : formik.values.visitAuthorizet,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Covered service was successfully updated',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              onClose();
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            })
        : addService({
            insuranceId: insurance?.id,
            service: formik.values.service,
            isUnlimited: formik.values.isUnlimited,
            type: formik.values.type,
            visitAuthorizet: formik.values.isUnlimited
              ? 0
              : formik.values.visitAuthorizet,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Covered service was successfully created',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              onClose();
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
    },
  });
  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Content>
        <S.Article>{row ? 'Edit Covered Service ' : 'Add Covered Service'}</S.Article>
        <S.MainContent>
          <S.InputItems>
            <S.InputRow>
              <S.InputWrapper className="large">
                <SelectInput
                  label="Choosed service"
                  isRequired
                  id={`service`}
                  name={`service`}
                  error={!!formik.errors.service}
                  helperText={formik.errors.service}
                  value={formik.values.service}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('service', '');
                    formik.handleChange(e);
                  }}
                >
                  {visitReasonsList.map(reason => (
                    <MenuItem
                      key={reason.name}
                      value={reason.value}
                      disabled={insurance?.coveredServices.some(
                        service => reason.value === service.service,
                      )}
                    >
                      <S.MenuItemContent>{reason.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <S.InputWrapper>
                <SelectInput
                  label="Type"
                  isRequired
                  id={`type`}
                  name={`type`}
                  error={!!formik.errors.type}
                  helperText={formik.errors.type}
                  value={formik.values.type}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('type', '');
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value={ClinicInsuranceType.OUT_OF_NETWORK}>
                    <S.MenuItemContent>Out of network</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={ClinicInsuranceType.IN_NETWORK}>
                    <S.MenuItemContent>In network</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
              <S.InputWrapper className="small">
                <Input
                  type={formik.values.isUnlimited ? 'text' : 'number'}
                  label="No. of Visit Authorized"
                  id="visitAuthorizet"
                  name="visitAuthorizet"
                  value={formik.values.isUnlimited ? 'UV' : formik.values.visitAuthorizet}
                  disabled={formik.values.isUnlimited}
                  error={
                    formik.values.isUnlimited
                      ? undefined
                      : !!formik.errors.visitAuthorizet
                  }
                  helperText={
                    formik.values.isUnlimited ? undefined : formik.errors.visitAuthorizet
                  }
                  onChange={e => {
                    // setFieldError("birthDate", " ");
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <CheckBoxWithLabel
                isArticle
                label="Is unlimited"
                id="isUnlimited"
                name="isUnlimited"
                checked={formik.values.isUnlimited}
                value={formik.values.isUnlimited}
                onChange={e => {
                  // setFieldError("birthDate", " ");
                  formik.handleChange(e);
                }}
              />
            </S.InputRow>
          </S.InputItems>
        </S.MainContent>
        <S.ButtonWrapper>
          <Button text={row ? 'Edit' : 'Add'} type="submit" />
        </S.ButtonWrapper>
      </S.Content>
    </form>
  );
};

export default AddCoveredServicesDialog;
