import {
  useTreatmentGetQuery,
  useTreatmentUpdateMutation,
} from '../../../../store/api/treatment/treatmentApi';
import { Treatment } from '../../../../types/TreatmentType';
import { UserProfile } from '../../../../types/UserProfileTypes';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CheckBox from '../../../atoms/CheckBox';
import Input from '../../../atoms/Input';
import {
  ApproveBtnSVG,
  BackChestSVG,
  BackHeadSVG,
  BackHipsSVG,
  BackLeftArmSVG,
  BackLeftLegSVG,
  BackNeckSVG,
  BackRightArmSVG,
  BackRightLegSVG,
  EditBtnSVG,
  FrontChestSVG,
  FrontHeadSVG,
  FrontHipsSVG,
  FrontLeftArmSVG,
  FrontLeftLegSVG,
  FrontNeckSVG,
  FrontRightArmSVG,
  FrontRightLegSVG,
  FrontStomachSVG,
  PlaceHolderTreatmentsSVG,
  ResetApproveBtnSVG,
} from '../../../../assets/icons';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ChangeEvent, useEffect, useState } from 'react';
import { Roles } from '../../../../types/Roles';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../store/slices/message';
import NoTreatmentPlaceholder from './NoTreatmentPlaceholder/NoTreatmentPlaceholder';

type Props = {
  rows?: UserProfile | undefined;
};

const TreatmentForm: React.FC<Props> = ({ rows }) => {
  const TreatmentSchema = yup.object().shape({});
  const initialTreatment = useTreatmentGetQuery({
    id: rows?.lastTreatmentId,
  });
  const [updateTreatment, statusUpdate] = useTreatmentUpdateMutation({});
  const userInfo = useAppSelector(state => state.auth);
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();

  // console.log(initialTreatment);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    submitForm,
  } = useFormik<Treatment>({
    initialValues: {
      isMedicalRecords: false,
      isTestResults: false,
      isFilms: false,
      isOther: false,
      isAllergies: false,
      isOtherAggravatingFactors: false,
      isPreviousOperations: false,
      hpi: '',
      painLocation: {
        frontSide: {
          head: false,
          chest: false,
          neck: false,
          stomach: false,
          shin: false,
          rightHand: false,
          leftHand: false,
          rightLeg: false,
          leftLeg: false,
        },
        backSide: {
          head: false,
          neck: false,
          back: false,
          shin: false,
          rightHand: false,
          leftHand: false,
          rightLeg: false,
          leftLeg: false,
        },
      },
    },
    validateOnChange: false,
    validationSchema: TreatmentSchema,
    onSubmit: () => {
      updateTreatment({
        id: rows?.lastTreatmentId,
        ...values,
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      isMedicalRecords: initialTreatment?.currentData?.isMedicalRecords || false,
      isTestResults: initialTreatment?.currentData?.isTestResults || false,
      isFilms: initialTreatment?.currentData?.isFilms || false,
      isOther: initialTreatment?.currentData?.isOther || false,
      isAllergies: initialTreatment?.currentData?.isAllergies || false,
      isOtherAggravatingFactors:
        initialTreatment?.currentData?.isOtherAggravatingFactors || false,
      isPreviousOperations: initialTreatment?.currentData?.isPreviousOperations || false,
      hpi: initialTreatment?.currentData?.hpi || '',
      painLocation: {
        frontSide: {
          head: initialTreatment?.currentData?.painLocation?.frontSide.head || false,
          chest: initialTreatment?.currentData?.painLocation?.frontSide.chest || false,
          neck: initialTreatment?.currentData?.painLocation?.frontSide.neck || false,
          stomach:
            initialTreatment?.currentData?.painLocation?.frontSide.stomach || false,
          shin: initialTreatment?.currentData?.painLocation?.frontSide.shin || false,
          rightHand:
            initialTreatment?.currentData?.painLocation?.frontSide.rightHand || false,
          leftHand:
            initialTreatment?.currentData?.painLocation?.frontSide.leftHand || false,
          rightLeg:
            initialTreatment?.currentData?.painLocation?.frontSide.rightLeg || false,
          leftLeg:
            initialTreatment?.currentData?.painLocation?.frontSide.leftLeg || false,
        },
        backSide: {
          head: initialTreatment?.currentData?.painLocation?.backSide.head || false,
          neck: initialTreatment?.currentData?.painLocation?.backSide.neck || false,
          back: initialTreatment?.currentData?.painLocation?.backSide.back || false,
          shin: initialTreatment?.currentData?.painLocation?.backSide.shin || false,
          rightHand:
            initialTreatment?.currentData?.painLocation?.backSide.rightHand || false,
          leftHand:
            initialTreatment?.currentData?.painLocation?.backSide.leftHand || false,
          rightLeg:
            initialTreatment?.currentData?.painLocation?.backSide.rightLeg || false,
          leftLeg: initialTreatment?.currentData?.painLocation?.backSide.leftLeg || false,
        },
      },
    });
  }, [isEditable, initialTreatment]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <S.Wrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {initialTreatment.currentData ? (
          <>
            <S.ArticleWrapper>
              {userInfo.role !== Roles.PATIENT ? (
                <S.EditBtnWrapper>
                  <S.Article>
                    Initial Treatment form{' '}
                    <S.SpanArticle>
                      Last edited{' '}
                      {dayjs(initialTreatment.currentData?.updatedAt).format(
                        'MMMM D, YYYY h:mm A',
                      )}{' '}
                    </S.SpanArticle>
                  </S.Article>
                  {isEditable ? (
                    <S.ButtonsEdit>
                      <ResetApproveBtnSVG
                        onClick={() => {
                          setIsEditable(false);
                          resetForm();
                        }}
                      />
                      <ApproveBtnSVG
                        onClick={() => {
                          submitForm();
                        }}
                      />
                    </S.ButtonsEdit>
                  ) : (
                    <EditBtnSVG onClick={() => setIsEditable(true)} />
                  )}
                </S.EditBtnWrapper>
              ) : (
                <S.Article>
                  Initial Treatment form <S.SpanArticle>Last edited </S.SpanArticle>
                </S.Article>
              )}
            </S.ArticleWrapper>

            <S.Content>
              <S.TreatmentWrapper>
                <S.TreatmentInfo>
                  <S.SubArticle>
                    Please check if you brought the following{' '}
                    <S.RequiredSpan>*</S.RequiredSpan>
                  </S.SubArticle>
                  <S.Items>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isMedicalRecords"
                        name="isMedicalRecords"
                        value={values.isMedicalRecords}
                        checked={values.isMedicalRecords}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Medical Records</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isFilms"
                        name="isFilms"
                        value={values.isFilms}
                        checked={values.isFilms}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Films</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isTestResults"
                        name="isTestResults"
                        checked={values.isTestResults}
                        value={values.isTestResults}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Test Results</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isOther"
                        name="isOther"
                        checked={values.isOther}
                        value={values.isOther}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Other</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                  </S.Items>
                  <S.SubArticle>HPI (History of Present Illness)</S.SubArticle>
                  <S.HelperText>
                    Please provide a brief overview of your pain history
                  </S.HelperText>
                  <S.InputWrapper>
                    <Input
                      value={values.hpi}
                      onChange={handleChangeInput}
                      multiline={true}
                      rows={5}
                      label=""
                      id="hpi"
                      name="hpi"
                      error={!!errors.hpi}
                      helperText={errors.hpi}
                      disabled={!isEditable}
                    />
                  </S.InputWrapper>
                  <S.SubArticle>
                    Special attention <S.RequiredSpan>*</S.RequiredSpan>
                  </S.SubArticle>
                  <S.Items>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isAllergies"
                        name="isAllergies"
                        value={values.isAllergies}
                        checked={values.isAllergies}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Have allergies </S.CheckBoxLabel>
                    </S.CheckBoxItem>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isOtherAggravatingFactors"
                        name="isOtherAggravatingFactors"
                        value={values.isOtherAggravatingFactors}
                        checked={values.isOtherAggravatingFactors}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Other aggravating factors</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                    <S.CheckBoxItem>
                      <CheckBox
                        id="isPreviousOperations"
                        name="isPreviousOperations"
                        checked={values.isPreviousOperations}
                        value={values.isPreviousOperations}
                        onChange={handleChange}
                        disabled={!isEditable}
                      />
                      <S.CheckBoxLabel>Previous operations</S.CheckBoxLabel>
                    </S.CheckBoxItem>
                  </S.Items>
                  <S.SubArticle>
                    Please indicate the location of your pain below
                  </S.SubArticle>
                </S.TreatmentInfo>
              </S.TreatmentWrapper>
              <S.HumanContent>
                <S.FrontSide>Front</S.FrontSide>
                <S.FrontRight>Right</S.FrontRight>
                <S.FrontLeft>Left</S.FrontLeft>
                <S.BackSide>Back</S.BackSide>
                <S.BackLeft>Left</S.BackLeft>
                <S.BackRight>Right</S.BackRight>
                <S.HumanView>
                  <S.Front>
                    <FrontHeadSVG
                      className={'frontHead ' + values.painLocation?.frontSide.head}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.head',
                            !values.painLocation?.frontSide.head,
                          );
                      }}
                    />
                    <FrontNeckSVG
                      className={'frontNeck ' + values.painLocation?.frontSide.neck}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.neck',
                            !values.painLocation?.frontSide.neck,
                          );
                      }}
                    />
                    <FrontChestSVG
                      className={'frontChest ' + values.painLocation?.frontSide.chest}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.chest',
                            !values.painLocation?.frontSide.chest,
                          );
                      }}
                    />
                    <FrontRightArmSVG
                      className={
                        'frontRightArm ' + values.painLocation?.frontSide.rightHand
                      }
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.rightHand',
                            !values.painLocation?.frontSide.rightHand,
                          );
                      }}
                    />
                    <FrontLeftArmSVG
                      className={
                        'frontLeftArm ' + values.painLocation?.frontSide.leftHand
                      }
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.leftHand',
                            !values.painLocation?.frontSide.leftHand,
                          );
                      }}
                    />
                    <FrontStomachSVG
                      className={'frontStomach ' + values.painLocation?.frontSide.stomach}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.stomach',
                            !values.painLocation?.frontSide.stomach,
                          );
                      }}
                    />
                    <FrontHipsSVG
                      className={'frontHips ' + values.painLocation?.frontSide.shin}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.shin',
                            !values.painLocation?.frontSide.shin,
                          );
                      }}
                    />
                    <FrontRightLegSVG
                      className={
                        'frontRightLeg ' + values.painLocation?.frontSide.rightLeg
                      }
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.rightLeg',
                            !values.painLocation?.frontSide.rightLeg,
                          );
                      }}
                    />
                    <FrontLeftLegSVG
                      className={'frontLeftLeg ' + values.painLocation?.frontSide.leftLeg}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.frontSide.leftLeg',
                            !values.painLocation?.frontSide.leftLeg,
                          );
                      }}
                    />
                  </S.Front>
                  <S.Back>
                    <BackHeadSVG
                      className={'backHead ' + values.painLocation?.backSide.head}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.head',
                            !values.painLocation?.backSide.head,
                          );
                      }}
                    />
                    <BackNeckSVG
                      className={'backNeck ' + values.painLocation?.backSide.neck}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.neck',
                            !values.painLocation?.backSide.neck,
                          );
                      }}
                    />
                    <BackChestSVG
                      className={'backChest ' + values.painLocation?.backSide.back}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.back',
                            !values.painLocation?.backSide.back,
                          );
                      }}
                    />
                    <BackLeftArmSVG
                      className={'backLeftArm ' + values.painLocation?.backSide.leftHand}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.leftHand',
                            !values.painLocation?.backSide.leftHand,
                          );
                      }}
                    />
                    <BackRightArmSVG
                      className={
                        'backRightArm ' + values.painLocation?.backSide.rightHand
                      }
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.rightHand',
                            !values.painLocation?.backSide.rightHand,
                          );
                      }}
                    />
                    <BackHipsSVG
                      className={'backHips ' + values.painLocation?.backSide.shin}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.shin',
                            !values.painLocation?.backSide.shin,
                          );
                      }}
                    />

                    <BackLeftLegSVG
                      className={'backLeftLeg ' + values.painLocation?.backSide.leftLeg}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.leftLeg',
                            !values.painLocation?.backSide.leftLeg,
                          );
                      }}
                    />
                    <BackRightLegSVG
                      className={'backRightLeg ' + values.painLocation?.backSide.rightLeg}
                      onClick={() => {
                        isEditable &&
                          setFieldValue(
                            'painLocation.backSide.rightLeg',
                            !values.painLocation?.backSide.rightLeg,
                          );
                      }}
                    />
                  </S.Back>
                </S.HumanView>
              </S.HumanContent>
            </S.Content>
          </>
        ) : (
          <NoTreatmentPlaceholder />
        )}
      </form>
    </S.Wrapper>
  );
};

export default TreatmentForm;
