import * as yup from 'yup';
import { VisitReason } from '../../../../components';
import { AppointmentTypeEnum } from '../../../../types/enums/AppointmentEnum';

export const AppointmentWithoutReg = yup.object().shape({
  firstName: yup.string().required('Is required'),
  lastName: yup.string().required('Is required'),
  phone: yup
    .string()
    .matches(/^\+[1-9]\d{1,14}$/, 'International format required: +1xxxxxxxxxx')
    .min(12, 'International format required: +1xxxxxxxxxx')
    .max(12, 'International format required: +1xxxxxxxxxx')
    .required('Is required'),
  email: yup
    .string()
    .matches(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
      'Set email in valid format',
    ),
  date: yup.string().required('Is required'),
  clinicId: yup.string().required('Is required'),
  visits: yup.array().of(
    yup.object().shape({
      reason: yup.mixed<AppointmentTypeEnum>().required('Is required'),
      startTime: yup.string().required('Is required'),
      visitLengthMinutes: yup.number().min(1, 'Is required'),
      specialistId: yup.string(),
    }),
  ),

  visitReason: yup.array().min(1, 'Is required'),
  paymentMethod: yup.string().required('Is required'),
});
