import { useState } from 'react';
import { ArrowLSVG, ArrowRSVG } from 'assets/icons';
import { CustomTable } from 'components';
import { useInvoicePrePaymentAllQuery } from 'store/api/invoices/invoiceApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import InvoicePrepaymentRow from './TableData/InvoicePrepaymentRow';

import * as S from './styles';

const headers = [
  { name: 'Year', key: 'Year' },
  { name: 'Clinic name', key: 'Clinic' },
  { name: 'Charged', key: 'Charged' },
  { name: 'Status', key: 'Status' },
  { name: 'Number of items', key: 'Number' },
];

const INVOICE_PRE_PAYMENT_LIMIT = 20;

const InvoicePrepayment = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [filter, setFilter] = useState('');

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(INVOICE_PRE_PAYMENT_LIMIT);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + INVOICE_PRE_PAYMENT_LIMIT);
    setSecondCount(secondCount + INVOICE_PRE_PAYMENT_LIMIT);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - INVOICE_PRE_PAYMENT_LIMIT);
    setSecondCount(secondCount - INVOICE_PRE_PAYMENT_LIMIT);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useInvoicePrePaymentAllQuery({
    q: debouncedSearchValue || undefined,
    page,
    take: INVOICE_PRE_PAYMENT_LIMIT,
    clinicId: selectedClinic.id,
  });

  const handleShowCount = () => {
    if (getList?.currentData?.count && getList.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getList?.currentData?.count && getList.currentData.count < secondCount
            ? getList.currentData.count
            : secondCount}{' '}
          of {getList?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getList?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.TableBody>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getList.currentData?.page === getList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching || getList.isLoading}>
        <InvoicePrepaymentRow
          clickable
          data={getList?.currentData?.rows}
          headers={headers}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default InvoicePrepayment;
