import { MenuItem } from '@mui/material';
import {
  Button,
  DatePicker,
  Dialog,
  Input,
  SelectInput,
} from '../../../../../../../../components';
import { Relation } from '../../../../../../../../types/enums/AppointmentEnum';
import * as S from './styles';
import { Insurance } from '../../../../../../../../types/InsuranceType';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import {
  useInsuranceAddMutation,
  useInsuranceUpdateMutation,
} from '../../../../../../../../store/api/insuranceRelative/insuranceRelativeApi';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../../store/slices/message';
import { SearchIconSVG } from '../../../../../../../../assets/icons';
import PayerSearchDialog from '../../../../../../../../components/molecules/PayerSearchDialog';
import { PayerRow } from '../../../../../../../../types/Payer';

type Props = {
  onClose: () => void;
  row: Insurance | null;
  userId?: string;
};

type FormValues = {
  effectiveStartDate: Dayjs | string;
  effectiveStopDate: Dayjs | string;
  primaryInsuranceName: string;
  companyPhone: string;
  subsriberName: string;
  payerId: string;
  relationship: string;
  ssn: string;
  insuranceId: string;
  groupNumber: string;
  groupName: string;
};

const AddInsuranceDialog = ({ onClose, row, userId }: Props) => {
  const [createInsurance] = useInsuranceAddMutation({});
  const [updateInsurance] = useInsuranceUpdateMutation({});

  const Schema = yup.object().shape({
    effectiveStartDate: yup.string().required('Is required'),
    effectiveStopDate: yup.string().nullable(),
    primaryInsuranceName: yup.string().trim().required('Is required'),
    subsriberName: yup.string().trim().required('Is required'),
    relationship: yup.string().required('Is required'),
    insuranceId: yup.string().trim().required('Is required'),
    groupNumber: yup.string().trim().required('Is required'),
    groupName: yup.string().trim().optional(),
  });

  const dispatch = useAppDispatch();

  const formik = useFormik<FormValues>({
    initialValues: {
      effectiveStartDate: '',
      effectiveStopDate: '',
      primaryInsuranceName: '',
      companyPhone: '',
      payerId: '',
      subsriberName: '',
      relationship: '',
      ssn: '',
      insuranceId: '',
      groupNumber: '',
      groupName: '',
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: () => {
      row
        ? updateInsurance({
            id: row.id,
            effectiveStartDate: formik.values.effectiveStartDate
              ? dayjs(formik.values.effectiveStartDate).format('YYYY-MM-DD')
              : null,
            effectiveStopDate: formik.values.effectiveStopDate
              ? dayjs(formik.values.effectiveStopDate).format('YYYY-MM-DD')
              : null,
            primaryInsuranceCompany: formik.values.primaryInsuranceName?.trim(),
            companyPhone: formik.values.companyPhone,
            payerId: formik.values.payerId?.trim(),
            subscriberName: formik.values.subsriberName?.trim(),
            relation: formik.values.relationship,
            ssn: formik.values.ssn?.trim(),
            insuranceId: formik.values.insuranceId?.trim(),
            groupNumber: formik.values.groupNumber?.trim(),
            groupName: formik.values.groupName?.trim(),
          })
        : createInsurance({
            effectiveStartDate: formik.values.effectiveStartDate
              ? dayjs(formik.values.effectiveStartDate).format('YYYY-MM-DD')
              : null,
            effectiveStopDate: formik.values.effectiveStopDate
              ? dayjs(formik.values.effectiveStopDate).format('YYYY-MM-DD')
              : null,
            payerId: formik.values.payerId?.trim(),
            primaryInsuranceCompany: formik.values.primaryInsuranceName?.trim(),
            companyPhone: formik.values.companyPhone,
            subscriberName: formik.values.subsriberName?.trim(),
            relation: formik.values.relationship,
            ssn: formik.values.ssn?.trim(),
            insuranceId: formik.values.insuranceId?.trim(),
            groupNumber: formik.values.groupNumber?.trim(),
            groupName: formik.values.groupName?.trim(),
            profileId: userId,
          })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Insurance was successfully created',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
      onClose();
    },
  });

  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        effectiveStartDate: dayjs(row.effectiveStartDate) || '',
        effectiveStopDate: dayjs(row.effectiveStopDate) || '',
        primaryInsuranceName: row?.primaryInsuranceCompany || '',
        companyPhone: row?.companyPhone || '',
        subsriberName: row?.subscriberName || '',
        relationship: row?.relation || '',
        ssn: row?.ssn || '',
        payerId: row?.payerId || '',
        insuranceId: row?.insuranceId || '',
        groupNumber: row?.groupNumber || '',
        groupName: row?.groupName || '',
      });
    }
  }, [row]);

  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PayerRow | null>(null);

  useEffect(() => {
    if (selectedRow) {
      formik.setValues({
        ...formik.values,
        payerId: selectedRow.payerId,
        primaryInsuranceName: selectedRow.payerName,
      });
    }
  }, [selectedRow]);

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Content>
        <Dialog
          open={isOpenSearchDialog}
          onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
        >
          <PayerSearchDialog
            onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Dialog>
        <S.Article>{row ? 'Edit Insurance ' : 'Add Insurance'}</S.Article>

        <S.MainContent>
          <S.InputItems>
            <S.InputRow>
              <S.InputWrapper>
                <DatePicker
                  isRequired
                  label="Effective Start Date"
                  id="effectiveStartDate"
                  name="effectiveStartDate"
                  error={!!formik.errors.effectiveStartDate}
                  helperText={formik.errors.effectiveStartDate}
                  value={formik.values.effectiveStartDate}
                  onChange={value => {
                    formik.setFieldError('effectiveStartDate', '');
                    formik.setFieldValue('effectiveStartDate', value);
                  }}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <DatePicker
                  isRequired
                  label="Effective Stop Date"
                  id="effectiveStopDate"
                  name="effectiveStopDate"
                  error={!!formik.errors.effectiveStopDate}
                  helperText={formik.errors.effectiveStopDate}
                  value={formik.values.effectiveStopDate}
                  onChange={value => {
                    formik.setFieldError('effectiveStopDate', '');
                    formik.setFieldValue('effectiveStopDate', value);
                  }}
                />
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <S.InputWrapper>
                <ReactInputMask
                  id="payerId"
                  name="payerId"
                  mask={'*****'}
                  value={formik.values.payerId}
                  maskPlaceholder={'_'}
                  // disabled={selectedRow ? true : false}
                  onChange={e => {
                    formik.setFieldError('payerId', '');
                    formik.handleChange(e);
                  }}
                >
                  <Input label="PayerID" />
                </ReactInputMask>
              </S.InputWrapper>
              <S.InputWrapper className="large">
                <Input
                  isRequired
                  label="Insurance Company"
                  id="primaryInsuranceName"
                  name="primaryInsuranceName"
                  error={!!formik.errors.primaryInsuranceName}
                  helperText={formik.errors.primaryInsuranceName}
                  value={formik.values.primaryInsuranceName}
                  onChange={e => {
                    formik.setFieldError('primaryInsuranceName', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
              <S.SearchBtn onClick={() => setIsOpenSearchDialog(true)}>
                <SearchIconSVG />
                Search
              </S.SearchBtn>
            </S.InputRow>
            <S.InputRow>
              <S.InputWrapper>
                <ReactInputMask
                  mask="+19999999999"
                  value={formik.values.companyPhone}
                  onChange={e => {
                    formik.setFieldError('companyPhone', '');
                    formik.handleChange(e);
                  }}
                >
                  <Input
                    label="Company phone"
                    id="companyPhone"
                    name="companyPhone"
                    error={!!formik.errors.companyPhone}
                    helperText={formik.errors.companyPhone}
                  />
                </ReactInputMask>
              </S.InputWrapper>
              <S.InputWrapper>
                <Input
                  isRequired
                  label="Insured/ Subscriber Name"
                  id="subsriberName"
                  name="subsriberName"
                  error={!!formik.errors.subsriberName}
                  helperText={formik.errors.subsriberName}
                  value={formik.values.subsriberName}
                  onChange={e => {
                    formik.setFieldError('subsriberName', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <SelectInput
                  isRequired
                  label="Relationship to Patient"
                  id="relationship"
                  name="relationship"
                  error={!!formik.errors.relationship}
                  helperText={formik.errors.relationship}
                  value={formik.values.relationship}
                  onChange={e => {
                    formik.setFieldError('relationship', '');
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value={Relation.SELF}>
                    <S.MenuItemContent>Self</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.CHILD}>
                    <S.MenuItemContent>Child</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.SPOUSE}>
                    <S.MenuItemContent>Spouse</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.OTHER}>
                    <S.MenuItemContent>Other</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
              <S.InputWrapper>
                <Input
                  label="Insured SSN"
                  id="ssn"
                  name="ssn"
                  error={!!formik.errors.ssn}
                  helperText={formik.errors.ssn}
                  value={formik.values.ssn}
                  onChange={e => {
                    formik.setFieldError('ssn', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <Input
                isRequired
                label="ID"
                id="insuranceId"
                name="insuranceId"
                error={!!formik.errors.insuranceId}
                helperText={formik.errors.insuranceId}
                value={formik.values.insuranceId}
                onChange={e => {
                  formik.setFieldError('insuranceId', '');
                  formik.handleChange(e);
                }}
              />
              <Input
                isRequired
                label="Group #"
                id="groupNumber"
                name="groupNumber"
                error={!!formik.errors.groupNumber}
                helperText={formik.errors.groupNumber}
                value={formik.values.groupNumber}
                onChange={e => {
                  formik.setFieldError('groupNumber', '');
                  formik.handleChange(e);
                }}
              />
              <Input
                label="Group name"
                id="groupName"
                name="groupName"
                value={formik.values.groupName}
                error={!!formik.errors.groupName}
                helperText={formik.errors.groupName}
                onChange={e => {
                  formik.setFieldError('groupName', '');
                  formik.handleChange(e);
                }}
              />
            </S.InputRow>
          </S.InputItems>
        </S.MainContent>
        <S.ButtonWrapper>
          <Button text={row ? 'Edit' : 'Add'} type="submit" />
        </S.ButtonWrapper>
      </S.Content>
    </form>
  );
};

export default AddInsuranceDialog;
