import {
  AddIconFailSVG,
  AddIconSVG,
  AvatarSVG,
  ClockPinSVG,
  EmailIconSVG,
  NoteIconSVG,
  PhoneIconSVG,
  PlacePinSVG,
} from '../../../assets/icons';
import StatusPin from '../../atoms/StatusPin';
import * as S from './styles';
import avatarmock from '../../../assets/images/avatarmock.jpg';
import { AppointmentStatus, VisitReasons } from '../../../types/StatusTypes';
import { getBackgroundColors } from '../../../helpers/getAppointmetColor';
import { getStatusColor } from '../../../helpers/getStatusColor';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { Clinics } from '../../../types/ClinicTypes';
import { Appointments } from '../../../types/AppointmentsTypes';
import {
  constantTimePicker,
  constantTimePickerOUT,
  dayNames,
} from '../../../constants/constants';
import dayjs from 'dayjs';
import { eventsApi } from '../../../store/api/events/eventsApi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Roles } from '../../../types/Roles';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import NotificationPopover from '../NotificationPopover';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showDrawer } from '../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../types/DrawerMode';
import HoldCard from '../../atoms/HoldCard';
import { AppointmentsNew } from '../../../types/AppointmentNewTypes';
import { convertAMtoPM } from '../../../helpers/functions/convertAMtoPM';
import { transformArticleText } from '../../../helpers/functions/transformArticleText';
import AppointmentPinV2 from '../../atoms/AppointmentPinV2';
import { transformNoteText } from '../../../helpers/functions/transformNoteText';
import AppointmentCard from '../AppointmentsView/components/PlannerView/components/AppointmentCard';

type Props = {
  row: AppointmentsNew;
  onClick: MouseEventHandler<HTMLDivElement>;
  onTreatmentClick: (e: MouseEvent<HTMLDivElement>) => void;
  isUserView?: boolean;
};

const RecordItem: React.FC<Props> = ({ onClick, row, onTreatmentClick, isUserView }) => {
  const { role } = useAppSelector(state => state.auth);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);

  const [isShowModal, setIsShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnchorProfile = (e: MouseEvent<HTMLDivElement>) => {
    if (anchorElProfile) {
      e.stopPropagation();
      setAnchorElProfile(null);
    } else {
      setAnchorElProfile(e.currentTarget);
    }
  };

  const background = getBackgroundColors(
    VisitReasons.ACUPUNCTURE,

    false,
  ).backgroundColor;
  const border = getBackgroundColors(VisitReasons.ACUPUNCTURE, false).borderColor;

  return (
    <S.RecordItemWrapper
      background={background}
      border={border}
      onClick={e => {
        !isShowModal && onClick(e);
      }}
    >
      <S.LeftBlock>
        <S.DateDiv>
          <S.NumberDay>{dayjs(row.date).format('MMM DD, YYYY')}</S.NumberDay>
          <S.WeekDay>{dayjs(row.date).format('dddd')}</S.WeekDay>
        </S.DateDiv>
        <S.Line />
        <S.RecordInformation>
          {row.visits.map(visit => {
            return (
              <AppointmentCard
                key={visit.id}
                appointment={row}
                visit={visit}
                isOnlyView
                isUserView
              />
            );
          })}
        </S.RecordInformation>
      </S.LeftBlock>
    </S.RecordItemWrapper>
  );
};

export default RecordItem;
