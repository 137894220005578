import { FormikProps } from 'formik';
import AutoCompleteInput from '../../../../../atoms/AutoComplete';
import Input from '../../../../../atoms/Input';
import OptionContent from '../OptionContent';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { AppointmentsNew } from '../../../../../../types/AppointmentNewTypes';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useUserDeepSearchMutMutation } from '../../../../../../store/api/searchApi/searchApi';
import { OptionType } from '../OptionContent/OptionContent';
import * as S from '../styles';
import { Autocomplete, TextField } from '@mui/material';

type Props = {
  formik: FormikProps<any>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
  clinic?: string;
  error?: string;
  appointmentInformation?: AppointmentsNew;
};

const FirstName = forwardRef<Record<string, unknown>, Props>(
  (
    {
      formik,
      byClick,
      userProfile,
      selectedAppointment,
      duplAppointment,
      selectedValue,
      setSelectedValue,
      clinic,
      error,
      appointmentInformation,
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);

    const [allPatientsGet, allPatientsStatus] = useUserDeepSearchMutMutation({});

    const [data, setData] = useState([]);

    const handleChangeInput = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      formik.setFieldError(e.target.id, '');
      formik.handleChange(e);
    };

    useEffect(() => {
      console.log(clinic);
      if (
        !userProfile &&
        !duplAppointment &&
        !appointmentInformation &&
        (!selectedAppointment || selectedAppointment?.isPossibleToUpdate)
      ) {
        allPatientsGet({
          firstName: debouncedSearchValue,
          page: 1,
          ...(clinic && { clinicId: clinic }),
        })
          .unwrap()
          .then(res => setData(res?.rows));
      }
    }, [debouncedSearchValue, clinic]);

    useEffect(() => {
      if (selectedValue) {
        formik.setValues({
          ...formik.values,
          firstName: selectedValue.profile?.firstName || '',
          lastName: selectedValue.profile?.lastName || '',
          phone: selectedValue?.phone || '',
          email: selectedValue?.email || '',
          userId: selectedValue?.id || '',
          accountNumber: selectedValue?.clinics?.ClinicUser?.accountNumber || '',
          dateOfBirth: selectedValue.profile?.dateOfBirth || '',
        });
      }
    }, [selectedValue]);

    useEffect(() => {
      if (!selectedValue) {
        formik.setFieldValue('firstName', searchValue);
      }
      // console.log(selectedValue + " selval", searchValue + " searchvak");
    }, [searchValue]);

    return (
      <>
        {byClick ? (
          <S.CompleteWrapper>
            <S.Label htmlFor="input">
              First name
              <S.RequiredSpan>*</S.RequiredSpan>
            </S.Label>
            <Autocomplete
              value={selectedValue}
              onChange={(e, newValue) => {
                setSelectedValue(newValue);
              }}
              disabled={
                !!selectedValue || selectedAppointment?.isPossibleToUpdate === false
              }
              options={data || []}
              getOptionLabel={(option: any) =>
                !selectedValue
                  ? formik?.values?.firstName || ''
                  : option && option?.profile?.firstName
              }
              renderOption={(props: any, option: any) => {
                return (
                  <OptionContent
                    searchValue={debouncedSearchValue}
                    key={option.id}
                    textToHighlight={option.profile?.firstName || ''}
                    props={props}
                    type={OptionType.FIRSTNAME}
                    option={option}
                    loading={allPatientsStatus.isLoading}
                  />
                );
              }}
              inputValue={searchValue}
              onInputChange={(e, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  placeholder="Enter a search value"
                  name="firstName"
                  key={params.id}
                  FormHelperTextProps={{
                    focused: false,
                  }}
                  {...params}
                />
              )}
            />
          </S.CompleteWrapper>
        ) : (
          <Input
            label="First name"
            id="firstName"
            isRequired
            name="firstName"
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName as string}
            value={formik.values.firstName}
            onChange={handleChangeInput}
            disabled={
              !!userProfile?.profile?.firstName ||
              !!selectedAppointment?.patient?.profile?.firstName ||
              !!duplAppointment?.patient?.profile?.firstName
            }
          />
        )}
      </>
    );
  },
);

export default FirstName;
