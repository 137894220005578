import { FormikErrors, FormikProps, FormikValues } from 'formik';
import { ClearFiltersSVG, DeleteRedBtnSVG } from '../../../../../assets/icons';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { AppointmentTypeEnum } from '../../../../../types/enums/AppointmentEnum';
import { transformArticleText } from '../../../../../helpers/functions/transformArticleText';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  constantTimePicker,
  visitLengthPicker,
} from '../../../../../constants/constants';
import SelectInput from '../../../../atoms/SelectInput';
import { convertAMtoPM } from '../../../../../helpers/functions/convertAMtoPM';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import { AppointmentsNew, Visits } from '../../../../../types/AppointmentNewTypes';

import { useEffect } from 'react';
import { useGetAllDoctorsQuery } from '../../../../../store/api/staff/staffApi';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { UserProfile } from '../../../../../types/UserProfileTypes';
import { Patients } from '../../../../../types/ClinicTypes';
import { Roles } from '../../../../../types/Roles';

type Props = {
  visit: Visits;
  formik: FormikProps<AppointmentFormValues>;
  selectedAppointment?: AppointmentsNew;
  arrTime: string[] | null;
  index: number;
  recordTime?: {
    time?: string;
    date?: string;
  };
};

const VisitReasonCard = ({
  visit,
  formik,
  selectedAppointment,
  arrTime,
  index,
  recordTime,
}: Props) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  useEffect(() => {
    if (recordTime) {
      formik.setFieldValue(`visits.${index}.startTime`, recordTime.time?.slice(0, 5));
    } else if (arrTime && arrTime.length > 0 && !recordTime && !selectedAppointment) {
      formik.setFieldValue(`visits.${index}.startTime`, arrTime[0].slice(0, 5));
    }
  }, [recordTime, arrTime, selectedAppointment]);

  const getDoctors = useGetAllDoctorsQuery({
    id: selectedClinic.id,
    'userStatus[]': 'active',
    take: 500,
  });

  const me = useAppSelector(state => state.auth);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          {transformArticleText(visit.reason).text}
          {selectedAppointment?.status !== AppointmentStatus.CANCELED &&
            selectedAppointment?.status !== AppointmentStatus.ACTIVE &&
            selectedAppointment?.status !== AppointmentStatus.COMPLETED && (
              <DeleteRedBtnSVG
                onClick={() => {
                  if (visit.reason && visit.reason === formik.values.mainCoveredService) {
                    formik.setFieldValue('mainCoveredService', '');
                  }
                  const filteredReasons = formik.values.visitReason.filter(
                    item => item !== visit.reason,
                  );
                  const filteredVisits = formik.values.visits.filter(
                    item => item.reason !== visit.reason,
                  );
                  formik.setFieldValue('visitReason', filteredReasons);
                  formik.setFieldValue('visits', filteredVisits);
                }}
              />
            )}
        </S.Title>
        <S.InputRow>
          <S.SelectInputWrapper>
            <SelectInput
              label="Preferred Time"
              isRequired
              disabled={selectedAppointment ? true : false}
              id={`visits.${index}.startTime`}
              name={`visits.${index}.startTime`}
              value={visit.startTime}
              error={
                !!(formik.errors?.visits as FormikErrors<Visits[]>)?.[index]?.startTime
              }
              helperText={
                (formik.errors?.visits as FormikErrors<Visits[]>)?.[index]?.startTime
              }
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.setFieldError(`visits.${index}.startTime`, '');
                formik.handleChange(e);
              }}
            >
              {arrTime?.length && arrTime?.length > 0
                ? arrTime.map(time => (
                    <MenuItem key={time} value={time.slice(0, 5)}>
                      <S.MenuItemContent>{convertAMtoPM(time)}</S.MenuItemContent>
                    </MenuItem>
                  ))
                : constantTimePicker.map(time => (
                    <MenuItem key={time.value} value={time.value}>
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
            </SelectInput>
            {!formik.values.date && formik.values.clinicId && (
              <S.HelperText>To select a time, fill in the preffered day</S.HelperText>
            )}
          </S.SelectInputWrapper>
          <SelectInput
            label="Visit length"
            isRequired
            id={`visits.${index}.visitLengthMinutes`}
            name={`visits.${index}.visitLengthMinutes`}
            error={
              !!(formik.errors?.visits as FormikErrors<Visits[]>)?.[index]
                ?.visitLengthMinutes
            }
            helperText={
              (formik.errors?.visits as FormikErrors<Visits[]>)?.[index]
                ?.visitLengthMinutes
            }
            value={visit.visitLengthMinutes}
            onChange={(e: SelectChangeEvent<unknown>) => {
              formik.setFieldError(`visits.${index}.visitLengthMinutes`, '');
              formik.handleChange(e);
            }}
            disabled={selectedAppointment ? true : false}
          >
            {visitLengthPicker.map(time => (
              <MenuItem key={time.name} value={time.value}>
                <S.MenuItemContent>{time.name}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.InputRow>
        {me.role !== Roles.PATIENT &&
          visit.specialistId &&
          selectedAppointment?.status !== AppointmentStatus.CANCELED &&
          selectedAppointment?.status !== AppointmentStatus.COMPLETED && (
            <S.InputRow>
              <S.SVG
                onClick={() => formik.setFieldValue(`visits.${index}.specialistId`, '')}
              >
                <ClearFiltersSVG />
                Clear specialist
              </S.SVG>
            </S.InputRow>
          )}
        {me.role !== Roles.PATIENT && (
          <S.InputRow>
            <SelectInput
              label="Specialist name"
              id={`visits.${index}.specialistId`}
              name={`visits.${index}.specialistId`}
              value={visit.specialistId}
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.handleChange(e);
              }}
              disabled={
                selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                selectedAppointment?.status === AppointmentStatus.CANCELED
              }
            >
              {getDoctors.data?.rows.map((item: Patients) => {
                return (
                  <MenuItem key={item.id} value={item.userId}>
                    <S.MenuItemContent>
                      {item.user?.profile?.firstName + ' ' + item.user?.profile?.lastName}
                    </S.MenuItemContent>
                  </MenuItem>
                );
              })}
            </SelectInput>
          </S.InputRow>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default VisitReasonCard;
