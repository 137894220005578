import { UserProfile } from '../../../types/UserProfileTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    userProfileGet: builder.query<UserProfile, any>({
      query: arg => {
        return {
          url: '/user/profile',
          params: arg,
        };
      },
      providesTags: ['User'],
    }),
    usersGetAll: builder.query<Response<UserProfile>, any>({
      query: arg => {
        return {
          url: '/user',
          params: arg,
        };
      },
      providesTags: ['User'],
    }),
    userProfileGetOne: builder.query<UserProfile, any>({
      query: ({ id, ...arg }) => {
        return {
          url: `/user/${id}`,
          params: { ...arg },
        };
      },
      providesTags: ['User'],
    }),
    updateAvatar: builder.mutation({
      query: body => {
        const newData = new FormData();
        newData.append('avatar', body.avatar);
        return {
          url: `/user/update-avatar`,
          method: 'PATCH',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'User' }, { type: 'Staff' }],
    }),
    updateProfileAvatar: builder.mutation({
      query: body => {
        const newData = new FormData();
        newData.append('avatar', body.avatar);
        return {
          url: `/user/update-avatar/${body.id}`,
          method: 'PATCH',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'User' }, { type: 'Staff' }],
    }),
    updateProfile: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: () => [
        { type: 'User' },
        { type: 'Appointments' },
        { type: 'Staff' },
      ],
    }),

    changePassword: builder.mutation({
      query: credentials => ({
        url: '/user/change-password',
        method: 'PATCH',
        body: { ...credentials },
      }),
    }),
    blockUser: builder.mutation({
      query: ({ id }) => ({
        url: `/user/${id}/change-block-status`,
        method: 'PATCH',
      }),
      invalidatesTags: () => [{ type: 'User' }, { type: 'Staff' }],
    }),
    holdAppointment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/${id}/update-is-hold-appointment-status`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }, { type: 'Staff' }],
    }),
    holdBilling: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/${id}/update-is-hold-billing-status`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }, { type: 'Staff' }],
    }),
    lastSelectedClinic: builder.mutation({
      query: ({ ...body }) => ({
        url: `/user/last-selected-clinic`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: () => [{ type: 'User' }],
    }),
    uploadDoctorSignature: builder.mutation({
      query: ({ signature, ...body }) => {
        const newData = new FormData();
        newData.append('signature', signature);

        return {
          url: `/user/upload-doctor-signature`,
          method: 'PATCH',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'User' }, { type: 'Me' }],
    }),
    deleteDoctorSignature: builder.mutation({
      query: ({ id, ...params }) => {
        return {
          url: `/user/delete-doctor-signature/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'User' }],
    }),
    searchAllPatientsQ: builder.query<Response<any>, any>({
      query: arg => {
        return {
          url: '/user/search-by-fields',
          params: arg,
        };
      },
      providesTags: ['User'],
    }),
    updateCommonInformation: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `/profile/${id}`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: () => [{ type: 'User' }],
    }),
    uploadPhotoInsurance: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `/insurance/upload-files`,
          method: 'POST',
          body: { ...body },
        };
      },
      invalidatesTags: () => [{ type: 'User' }],
    }),
  }),
});

export const {
  useUserProfileGetQuery,
  useUpdateAvatarMutation,
  useChangePasswordMutation,
  useUserProfileGetOneQuery,
  useUpdateProfileMutation,
  useUpdateProfileAvatarMutation,
  useUsersGetAllQuery,
  useBlockUserMutation,
  useLastSelectedClinicMutation,
  useHoldAppointmentMutation,
  useHoldBillingMutation,
  useUploadDoctorSignatureMutation,
  useDeleteDoctorSignatureMutation,
  useSearchAllPatientsQQuery,
  useUpdateCommonInformationMutation,
  useUploadPhotoInsuranceMutation,
} = userApi;
