import { useState } from 'react';
import { AppointmentTypeEnum } from '../../types/enums/AppointmentEnum';

export const transformArticleText = (article: string) => {
  if (article === AppointmentTypeEnum.MASSAGE) {
    return {
      text: 'Massage',
    };
  }

  if (article === AppointmentTypeEnum.HERBS) {
    return {
      text: 'Herbs',
    };
  }

  if (article === AppointmentTypeEnum.ACUPUNCTURE) {
    return {
      text: 'Acupuncture',
    };
  }

  if (article === AppointmentTypeEnum.OTHER) {
    return {
      text: 'Other',
    };
  }

  if (article === AppointmentTypeEnum.PT) {
    return {
      text: 'PT',
    };
  }

  return {
    text: 'Unknown',
  };
};
