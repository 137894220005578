import { Relation } from '../../../../types/enums/AppointmentEnum';
import { Insurance } from '../../../../types/InsuranceType';

export const showIcon = (insuranceArr?: Insurance[]) => {
  // console.log(insuranceArr);

  if (insuranceArr && insuranceArr[0]?.relation === Relation.SELF) {
    return true;
  } else return false;
};

// profileOne.currentData?.profile?.insurances[0]?.relation ===
// Relation.SELF ||
// (profileOne.currentData?.profile?.insurances &&
// profileOne.currentData?.profile?.insurances.length > 0) ||
// (profileOne.currentData?.profile?.insurances[0]?.related &&
// profileOne.currentData?.profile?.insurances[0]?.related.length >
//   0)
