import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isShow: boolean }>`
  width: 0px;
  height: 100%;
  transition: 1s;

  ${props =>
    props.$isShow &&
    css`
      border-left: 1px solid var(--line, #d7d7d7);
      transition: 1s;
      display: flex;
      width: 270px;
      max-width: 270px;
    `}
`;

export const ClaimRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Content = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
`;

export const Title = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-wrap: break-word;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-self: center;
  border-radius: 5px;
  border: 1px solid var(--main, #0084b1);
  width: fit-content;
  user-select: none;
`;

export const ButtonText = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 13px 10px;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TopLine = styled.div`
  width: 100%;
  border-top: 0.5px solid var(--line, #d7d7d7);
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px;
  gap: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const CardWrapper = styled.div<{ $color: string; $clickable?: boolean }>`
  border-radius: 4px;
  display: flex;
  padding: 11px 6px 11px 16px;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;

  background: ${props => props.$color};
  cursor: ${props => (props.$clickable ? 'pointer' : 'auto')};
`;

export const CardText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
