import { useParams } from 'react-router-dom';
import { ArrowLSVG, ArrowRSVG, EyeVisibleSVG } from 'assets/icons';
import { CustomTable } from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useInvoicePrePaymentOneQuery } from 'store/api/invoices/invoiceApi';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { InvoicesMainType } from 'types/InvoiceType';
import InvInfPrepRow from './TableData/InvInfPrepRow';
import { useState } from 'react';

import * as S from './styles';

const headers = [
  { name: 'ID', key: 'ID' },
  { name: 'Patient Name', key: 'PatientName' },
  { name: 'Account #', key: 'Account' },
  { name: 'Claim #', key: 'Claim' },
  { name: 'Submitted on', key: 'SubmittedOn' },
  { name: 'DOS', key: 'DOS' },
  { name: 'Charged', key: 'Charged' },
  { name: 'Dr.', key: 'Dr' },
  { name: 'Employee', key: 'Employee' },
  { name: 'Network', key: 'Network' },
];

const ITEMS_PRE_PAYMENT_LIMIT = 20;

export const InvInformPrepaymentTable = () => {
  const dispatch = useAppDispatch();

  const params = useParams();

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(ITEMS_PRE_PAYMENT_LIMIT);
  const [page, setPage] = useState(1);

  const detailInvoice = useInvoicePrePaymentOneQuery({
    id: params.id,
    page,
    take: ITEMS_PRE_PAYMENT_LIMIT,
  });

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + ITEMS_PRE_PAYMENT_LIMIT);
    setSecondCount(secondCount + ITEMS_PRE_PAYMENT_LIMIT);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - ITEMS_PRE_PAYMENT_LIMIT);
    setSecondCount(secondCount - ITEMS_PRE_PAYMENT_LIMIT);
  };

  const handleShowCount = () => {
    if (detailInvoice?.currentData?.count && detailInvoice.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {detailInvoice?.currentData?.count &&
          detailInvoice.currentData.count < secondCount
            ? detailInvoice.currentData.count
            : secondCount}{' '}
          of {detailInvoice?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {detailInvoice?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={detailInvoice.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                detailInvoice.currentData?.page === detailInvoice.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {detailInvoice.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.ButtonShow
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.SHOW_INVOICE_HISTORY,
                props: { id: params.id, type: InvoicesMainType.PREPAYMENT },
              }),
            )
          }
        >
          <EyeVisibleSVG />
          <S.Text>Show Edit History</S.Text>
        </S.ButtonShow>
      </S.Header>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <InvInfPrepRow data={detailInvoice.currentData?.rows} headers={headers} />
        </CustomTable>
      </S.TableWrapper>
    </S.Wrapper>
  );
};
