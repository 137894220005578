import { useLocation, useParams } from 'react-router-dom';
import { UserProfile } from '../../../types/UserProfileTypes';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ApproveBtnSVG,
  AvatarSVG,
  CalendarIconSVG,
  CloseSVG,
  EditBtnSVG,
  EmailIconSVG,
  GenderIconSVG,
  PhoneIconSVG,
  ResetApproveBtnSVG,
} from '../../../assets/icons';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Input from '../../atoms/Input';
import UserStatusPin from '../../atoms/UserStatusPin';
import { getRoleName } from '../../../helpers/functions/roleName';
import { Roles } from '../../../types/Roles';
import SelectInput from '../../atoms/SelectInput';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import DatePicker from '../../atoms/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setMessage } from '../../../store/slices/message';
import {
  useUpdateProfileAvatarMutation,
  useUpdateProfileMutation,
} from '../../../store/api/user/profileApi';
import { ucFirst } from '../../../helpers/functions/toUpperCase';

type Props = {
  row?: UserProfile;
};

const StaffMainProfile: React.FC<Props> = ({ row }) => {
  const params = useParams();

  const [isEditable, setIsEditable] = useState(false);

  type FormValues = {
    email: string;
    firstName: string;
    lastName: string;
    cellPhone: string;
    sex: string | null;
    birthDate: Dayjs | string;
  };

  const dispatch = useAppDispatch();
  const [updateProfile] = useUpdateProfileMutation();
  const [updateAvatar] = useUpdateProfileAvatarMutation();

  const Schema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
        'Set email in valid format',
      ),
    firstName: yup.string().required('Is required'),
    lastName: yup.string().required('Is required'),
    cellPhone: yup
      .string()
      .matches(/^\+[1-9]\d{1,14}$/, 'International format required: +1xxxxxxxxxx')
      .required('International format required: +1xxxxxxxxxx')
      .min(12, 'International format required: +1xxxxxxxxxx')
      .max(12, 'International format required: +1xxxxxxxxxx'),
    birthDate: yup.date().required('Is required'),
    sex: yup.string().required('Is required'),
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = useFormik<FormValues>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      cellPhone: '',
      sex: '',
      birthDate: '',
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: async () => {
      avatar &&
        (await updateAvatar({
          id: params.profileId,
          avatar: avatar,
        })
          .unwrap()
          .then(res => {
            setIsEditable(false);
            resetForm();
            dispatch(
              setMessage({
                message: res.message,
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          }));

      await updateProfile({
        id: params.profileId,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.cellPhone,
        sex: values.sex,
        ...(values.email ? { email: values.email } : { email: null }),
        ...(values.birthDate && {
          dateOfBirth: dayjs(values.birthDate).format('YYYY-MM-DD'),
        }),
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  useEffect(() => {
    setValues({
      ...values,
      firstName: row?.profile?.firstName || '',
      lastName: row?.profile?.lastName || '',
      sex: row?.profile?.sex || '',
      email: row?.email || '',
      cellPhone: row?.phone || '',
      birthDate: row?.profile?.dateOfBirth ? dayjs(row?.profile?.dateOfBirth) : '',
    });
  }, [isEditable]);

  const [avatar, setAvatar] = useState(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = (e: any) => {
    const file = e.target.files.item(0);
    if (file) {
      setAvatar(file);
    }
  };

  const handleClearImg = (e: any) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setAvatar(null);
  };

  const location = useLocation();

  return (
    <S.Wrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {location.pathname !== '/profile' && (
          <S.EditBtnWrapper>
            {isEditable ? (
              <S.ButtonsEdit>
                <ResetApproveBtnSVG
                  onClick={() => {
                    setIsEditable(false);
                  }}
                />
                <ApproveBtnSVG
                  onClick={() => {
                    submitForm();
                  }}
                />
              </S.ButtonsEdit>
            ) : (
              <S.ButtonsEdit>
                <EditBtnSVG onClick={() => setIsEditable(true)} />
              </S.ButtonsEdit>
            )}
          </S.EditBtnWrapper>
        )}
        <S.AvatarDiv>
          {isEditable ? (
            <S.AvatarClickZone>
              <label htmlFor="file">
                <S.AvatarZone>
                  {avatar ? (
                    <>
                      <img src={URL.createObjectURL(avatar)} alt="" />
                    </>
                  ) : (
                    <AvatarSVG />
                  )}
                  <S.AvatarHelper>
                    Your new photo must be in JPEG, or PNG format before you upload this,
                    <S.TextHelper>max size 50MB</S.TextHelper>
                  </S.AvatarHelper>
                  <input
                    ref={inputRef}
                    type="file"
                    id="file"
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg"
                    hidden
                  ></input>
                </S.AvatarZone>
              </label>
              {avatar && (
                <S.DeleteCircle onClick={handleClearImg}>
                  <CloseSVG />
                </S.DeleteCircle>
              )}
            </S.AvatarClickZone>
          ) : row?.avatar ? (
            <img src={row?.avatar.url} alt="" />
          ) : (
            <AvatarSVG />
          )}
          <S.Name>
            {isEditable ? (
              <S.InfoValue>
                <Input
                  label=""
                  id="firstName"
                  name="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  onChange={handleChangeInput}
                  value={values.firstName}
                />
              </S.InfoValue>
            ) : (
              row?.profile?.firstName + ' '
            )}
            {isEditable ? (
              <S.InfoValue>
                <Input
                  label=""
                  id="lastName"
                  name="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  onChange={handleChangeInput}
                  value={values.lastName}
                />
              </S.InfoValue>
            ) : (
              row?.profile?.lastName
            )}
          </S.Name>
          <S.Role>{row?.role && getRoleName(row.role as Roles)}</S.Role>
          <UserStatusPin type={row?.isBlocked || row?.status} />
        </S.AvatarDiv>
        <S.MainInfoWrapper>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChangeInput}
                  value={values.email}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <EmailIconSVG />
                  <S.BlockText>
                    <S.RowText>Email</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{row?.email || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Cell phone"
                  id="cellPhone"
                  name="cellPhone"
                  error={!!errors.cellPhone}
                  helperText={errors.cellPhone}
                  onChange={handleChangeInput}
                  value={values.cellPhone}
                />
                <S.Note>
                  By changing this field, you will change the login information for this
                  user
                </S.Note>
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <PhoneIconSVG />
                  <S.BlockText>
                    <S.RowText>Cell phone</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{row?.phone || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <SelectInput
                  label="Sex"
                  id="sex"
                  name="sex"
                  error={!!errors.sex}
                  helperText={errors.sex}
                  value={values.sex}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    setFieldError('sex', '');
                    handleChange(e);
                  }}
                >
                  <MenuItem value={'male'}>
                    <S.MenuItemContent>{'Male'}</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={'female'}>
                    <S.MenuItemContent>{'Female'}</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <GenderIconSVG />
                  <S.BlockText>
                    <S.RowText>Sex</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>
                  {row?.profile?.sex ? ucFirst(row?.profile?.sex) : '-'}
                </S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <DatePicker
                  label="Birth date"
                  disableFuture
                  value={values.birthDate}
                  onChange={value => {
                    setFieldError('birthDate', '');
                    setFieldValue('birthDate', value);
                  }}
                  id="birthDate"
                  name="birthDate"
                  error={!!errors.birthDate}
                  helperText={errors.birthDate ? 'Not valid date format' : ''}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <CalendarIconSVG />
                  <S.BlockText>
                    <S.RowText>Birth date</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>
                  {row?.profile?.dateOfBirth
                    ? dayjs(row?.profile?.dateOfBirth).format('MM/DD/YYYY')
                    : '-'}
                </S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          {row?.isSpecialist && (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>SPECIALITY</S.UpperArticle>
                <S.UpperInfoText>Masseur</S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          )}
          <S.Row>
            <S.BlockRow>
              <S.UpperArticle>CREATED</S.UpperArticle>
              <S.UpperInfoText>
                <S.ReceptionistName>on </S.ReceptionistName>
                {row?.createdAt
                  ? dayjs(row?.createdAt.slice(0, 10)).format('MM/DD/YYYY')
                  : '-'}
              </S.UpperInfoText>
            </S.BlockRow>
          </S.Row>
          <S.Row>
            <S.BlockRow>
              <S.UpperArticle>UPDATED</S.UpperArticle>
              <S.UpperInfoText>
                <S.ReceptionistName>on </S.ReceptionistName>
                {row?.profile?.updatedAt
                  ? dayjs(row?.profile?.updatedAt.slice(0, 10)).format('MM/DD/YYYY')
                  : '-'}
              </S.UpperInfoText>
            </S.BlockRow>
          </S.Row>
        </S.MainInfoWrapper>
      </form>
    </S.Wrapper>
  );
};

export default StaffMainProfile;
