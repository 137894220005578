import styled from 'styled-components';
import { devices } from '../../../../constants/device';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-left: 0px;
    margin-right: 0px;
    height: none;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubtitleItems = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 210px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  margin-top: 8px;
  gap: 8px;

  @media ${devices.xs} {
    margin-left: 0px;
    flex-direction: column;
  }
`;

export const TabInformation = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const TabWrapper = styled.div`
  padding-left: 12px;

  & .demographics {
    display: none;
  }

  @media ${devices.xs} {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .demographics {
      display: inherit;
    }
  }
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
  }
`;

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  min-width: 330px;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  @media ${devices.xs} {
    width: 90%;
    background: #fff;
    border: none;
    margin-left: 20px;

    &.hide {
      display: none;
    }
  }
`;
