import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px 15px;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ColorBox = styled.div<{ color: string; border: string }>`
  width: 11px;
  height: 11px;
  border-radius: 3px;
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
  ${props =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `}
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
