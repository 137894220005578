import { forwardRef, useEffect, useState } from 'react';
import FirstName from '../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/FirstName';
import * as S from './styles';
import { FormikProps } from 'formik';
import LastName from '../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/LastName';
import DOB from '../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/DOB';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { InitialVisitFormType } from '../../pages/InitialVisitFormPage/InitialVisitiFormType';
import { ucFirst } from '../../../../../../../helpers/functions/toUpperCase';
import dayjs from 'dayjs';
import { PageACAInsuranceType } from '../../pages/PageACAInsurance/PageACAInsuranceType';
import { AppointmentsNew, Step } from '../../../../../../../types/AppointmentNewTypes';
import { ClearFiltersSVG } from '../../../../../../../assets/icons';

type Props = {
  formik: FormikProps<InitialVisitFormType>;
  stepperValues?: Step;
  appointmentInformation?: AppointmentsNew;
  setIsDirty?: () => void;
};

const UserInformation = forwardRef<Record<string, unknown>, Props>(
  ({ formik, appointmentInformation, stepperValues, setIsDirty }, ref) => {
    const [selectedValue, setSelectedValue] = useState<any>('');

    useEffect(() => {
      if (appointmentInformation) {
        setSelectedValue(appointmentInformation.patient);
      }
    }, [appointmentInformation]);

    useEffect(() => {
      if (selectedValue) {
        formik.setValues({
          ...formik.values,
          userId: selectedValue?.id,
          gender: selectedValue.profile?.sex,
          birthDate: dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY'),
          patientName:
            selectedValue.profile?.firstName + ' ' + selectedValue.profile?.lastName,
        });
      } else
        formik.setValues({
          ...formik.values,
          userId: '',
          gender: '',
          birthDate: '',
          patientName: '',
        });
    }, [selectedValue]);

    const { id } = useAppSelector(state => state.selectedClinic);

    return (
      <>
        {selectedValue && !appointmentInformation && (
          <S.ResetButton onClick={() => setSelectedValue('')}>
            <ClearFiltersSVG />
            Change user
          </S.ResetButton>
        )}

        {formik.errors.patientName && (
          <S.Line>
            <S.ErrorText>{'USER INFORMATION IS REQUIRED!'}</S.ErrorText>
          </S.Line>
        )}

        <S.UserInformationWrapper>
          <S.InputWrapper className="px240">
            <FirstName
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <LastName
              byClick
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              formik={formik}
              clinic={id}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <DOB
              byClick
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              formik={formik}
              clinic={id}
            />
          </S.InputWrapper>
          {selectedValue && (
            <>
              <S.BoldText>
                Sex: <S.Span>{ucFirst(formik.values.gender)}</S.Span>
              </S.BoldText>
            </>
          )}
        </S.UserInformationWrapper>
      </>
    );
  },
);

export default UserInformation;
