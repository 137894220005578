import styled from 'styled-components';
import { AccessLevel } from 'types/enums/AppointmentEnum';
import { devices } from 'constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const CellWrapper = styled.div<{ editable?: AccessLevel }>`
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px 0px;
  max-width: 350px;
  word-break: normal;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  user-select: none;
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }

  &.min {
    min-width: 80px;
  }

  &.cursor {
    cursor: auto;
  }
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;

  & svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    stroke: #848a9b;
    opacity: 1;

    &.disabled {
      stroke: #bfc2cb;
      pointer-events: none;
    }
  }
`;

export const PaginationText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 15px;
`;

export const ButtonShow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    circle {
      stroke: #0084b1;
    }
    path {
      stroke: #0084b1;
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #4570de;
      }
      path {
        stroke: #4570de;
      }
    }
  }
`;

export const Text = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    color: #4570de;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const Link = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    color: #4570de;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  width: 200px;
  max-width: 200px;
`;

export const InputCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 2px;
  width: 100%;
  max-width: 100px;

  &:hover {
    color: #4570de;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dots = styled.div`
  cursor: pointer;
  justify-content: end;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px 8px 10px;
  width: 100%;
  cursor: pointer;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: #e5f0f6;
  }
`;

export const Content = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 30px; */
  padding: 20px 30px;
  gap: 21px;
  @media ${devices.xs} {
    width: auto;
    height: auto;
    padding: 10px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 450px;

  @media ${devices.xs} {
    font-size: 16px;
    margin-top: 29px;
  }
`;

export const HelperText = styled.div`
  width: 362px;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.xs} {
    width: 275px;
    margin-bottom: 21px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 176px;
  margin-top: 15px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const ButtonWrap = styled.div`
  width: 176px;
  margin-right: 15px;

  @media ${devices.xs} {
    width: 280px;
  }
`;

export const LinkWrapper = styled.a`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #0084b1;
  text-decoration: none;

  a {
    color: #0084b1;
    text-decoration: none;

    svg {
      path {
        stroke: #0084b1;
      }
      circle {
        stroke: #0084b1;
      }
    }

    &:hover {
      color: #7b0be3;
    }
  }

  &:hover {
    color: #7b0be3;

    svg {
      path {
        stroke: #7b0be3;
      }
      circle {
        stroke: #7b0be3;
      }
    }
  }
`;

export const EmptyDiv = styled.div``;
