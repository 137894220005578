import { getUserPin } from '../../../helpers/UserStatusPinColors/getUserPinColor';
import { getBackgroundColors } from '../../../helpers/getAppointmetColor';
import * as S from './styles';

type Props = {
  type?: string | boolean;
};

const UserStatusPin: React.FC<Props> = ({ type }) => {
  const background = getUserPin(type).backgroundColor;
  const border = getUserPin(type).borderColor;
  const font = getUserPin(type).fontColor;
  const text = getUserPin(type).text;

  return (
    <S.StatusPinWrapper border={border} background={background} font={font}>
      {text}
    </S.StatusPinWrapper>
  );
};

export default UserStatusPin;
