import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  CheckBox,
  CircularLoader,
  DatePicker,
  Dialog,
  Input,
  SelectInput,
} from '../../../../../../components';
import * as S from '../../styles';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Relation } from '../../../../../../types/enums/AppointmentEnum';
import {
  AddHealthRecordSVG,
  AddIconSVG,
  AddRectangleSVG,
  AddRectSVG,
  CloseSVG,
  DeleteSVG,
  DocumentSVG,
  JPGSVG,
  PDFSVG,
} from '../../../../../../assets/icons';
import { useDropzone } from 'react-dropzone';
import { AddClinicBtn } from '../../../../../../components/organismus/WorkSchedule/styles';
import {
  useDeleteFileMutation,
  useFilesCreateMutation,
} from '../../../../../../store/api/files/files';
import { FormikProps } from 'formik';
import { FormValuesAuth } from '../../SignUpDetails';
import { File, FileInsurance, ResponseFile } from '../../../../../../types/FileType';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ReactInputMask from 'react-input-mask';
import InformationDialog from '../../../../../../components/molecules/InformationDialog';
import { fromPairs } from 'lodash';

type Props = {
  formik: FormikProps<FormValuesAuth>;
  step: number;
};

const InsuranceSignUp: React.FC<Props> = ({ formik, step }) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  // const handleDeleteFile = (index: number) => {
  //   const newArr = [...formik.values.documents];
  //   newArr.splice(index, 1);
  //   formik.setFieldValue("documents", newArr);
  // };

  const [uploadFiles, statusUploadFiles] = useFilesCreateMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});

  const [filesValues, setFilesValues] = useState<Array<File>>([]);

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      uploadFiles({ files: [...file] })
        .unwrap()
        .then(res => {
          const newItemsArr: File[] = [];
          res.map((item: ResponseFile, index: number) => {
            // console.log(res);
            newItemsArr.push(item.value);
          });
          setFilesValues([...filesValues, ...newItemsArr]);
          formik.setFieldValue('files', [...formik.values.files, ...newItemsArr]);
        })
        .catch(error => console.log(error));
    }
  };

  const handleDelete = (id: string) => {
    deleteFile({ id: id })
      .unwrap()
      .then(res => {
        const filteredArr = filesValues.filter(element => element.id !== id);
        const filteredFormikArr = formik.values.files.filter(
          element => element.id !== id,
        );
        setFilesValues(filteredArr);
        formik.setFieldValue('files', filteredFormikArr);
      });
  };

  useEffect(() => {
    setOpen(true);
  }, [step]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <InformationDialog
          onClose={() => setOpen(!open)}
          helperText="If you have a primary insurance please fill out the fields and press next, otherwise press skip. By pressing skip you are confirming that you do not have primary and secondary insurance."
          titleText="You can skip this step"
          onSuccess={() => setOpen(!open)}
        />
      </Dialog>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.InsuranceInformation>
            <S.ArticleInsurance>
              <S.Article>Insurance information</S.Article>
              <S.SubArticle>
                Please, fill out this form in its entirety, we can not verify or accept
                insurance with any missing information.
              </S.SubArticle>
            </S.ArticleInsurance>
            <S.CheckBoxes>
              <S.CheckBoxWrapper>
                <S.StyledSpan>Is this insurance a No-Fault insurance?</S.StyledSpan>
                <CheckBox
                  id="isNoFault"
                  name="isNoFault"
                  checked={formik.values.isNoFault}
                  value={formik.values.isNoFault}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
              <S.CheckBoxWrapper>
                <S.StyledSpan>
                  Is this insurance Workers' Compensation Insurance?
                </S.StyledSpan>
                <CheckBox
                  checked={formik.values.isWorkersCompensation}
                  id="isWorkersCompensation"
                  name="isWorkersCompensation"
                  value={formik.values.isWorkersCompensation}
                  onChange={formik.handleChange}
                />
              </S.CheckBoxWrapper>
            </S.CheckBoxes>
          </S.InsuranceInformation>
          <S.ArticleH3>Insurance info</S.ArticleH3>
          <S.Inputs>
            <S.InputRow className="label">
              <Input
                id="primaryInsurance"
                name="primaryInsurance"
                value={formik.values.primaryInsurance}
                onChange={handleChangeInput}
                error={!!formik.errors.primaryInsurance}
                helperText={formik.errors.primaryInsurance}
                isRequired
                label="Primary Insurance Company"
                background="#fff"
              />
              <ReactInputMask
                mask="+19999999999"
                value={formik.values.companyPhone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  label="Company phone"
                  background="#fff"
                  id="companyPhone"
                  name="companyPhone"
                  error={!!formik.errors.companyPhone}
                  helperText={formik.errors.companyPhone}
                />
              </ReactInputMask>
            </S.InputRow>
            <S.InputRow className="label">
              <Input
                id="subscriberName"
                name="subscriberName"
                value={formik.values.subscriberName}
                onChange={handleChangeInput}
                error={!!formik.errors.subscriberName}
                helperText={formik.errors.subscriberName}
                label="Insured/ Subscriber Name"
                isRequired
                background="#fff"
              />
              <S.InputRow>
                <DatePicker
                  disableFuture
                  id="subscriberDOB"
                  name="subscriberDOB"
                  value={formik.values.subscriberDOB}
                  onChange={value => {
                    formik.setFieldError('subscriberDOB', '');
                    formik.setFieldValue('subscriberDOB', value);
                  }}
                  error={!!formik.errors.subscriberDOB}
                  helperText={formik.errors.subscriberDOB}
                  label="Date of Birth"
                  isRequired
                  background="#fff"
                />
                <SelectInput
                  id="relationshipOfPatient"
                  name="relationshipOfPatient"
                  value={formik.values.relationshipOfPatient}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('relationshipOfPatient', '');
                    formik.handleChange(e);
                  }}
                  error={!!formik.errors.relationshipOfPatient}
                  helperText={formik.errors.relationshipOfPatient}
                  isRequired
                  label="Relationship to Patient"
                  background="#fff"
                >
                  <MenuItem value={Relation.SELF}>
                    <S.MenuItemContent>Self</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.CHILD}>
                    <S.MenuItemContent>Child</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.SPOUSE}>
                    <S.MenuItemContent>Spouse</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.OTHER}>
                    <S.MenuItemContent>Other</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputRow>
            </S.InputRow>

            <S.InputRow className="devide">
              <ReactInputMask
                mask="9999"
                value={formik.values.insuredSSN}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  label="Last 4 of SSN"
                  background="#fff"
                  id="insuredSSN"
                  name="insuredSSN"
                  error={!!formik.errors.insuredSSN}
                  helperText={formik.errors.insuredSSN}
                />
              </ReactInputMask>
              <Input
                id="insuredID"
                name="insuredID"
                value={formik.values.insuredID}
                onChange={handleChangeInput}
                error={!!formik.errors.insuredID}
                helperText={formik.errors.insuredID}
                label="Subscriber ID"
                isRequired
                background="#fff"
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="insuredGroup"
                name="insuredGroup"
                type="number"
                value={formik.values.insuredGroup}
                onChange={handleChangeInput}
                error={!!formik.errors.insuredGroup}
                helperText={formik.errors.insuredGroup}
                label="Group #"
                isRequired
                background="#fff"
              />
              <Input
                id="insuredGroupName"
                name="insuredGroupName"
                value={formik.values.insuredGroupName}
                onChange={handleChangeInput}
                error={!!formik.errors.insuredGroupName}
                helperText={formik.errors.insuredGroupName}
                label="Group name"
                background="#fff"
              />
            </S.InputRow>
          </S.Inputs>
          <S.UploadPhotoWrapper>
            <S.PhotoWrapper>
              <S.PhotoLabel>
                Please upload photo of your Insurance card
                <S.RequiredSpan> *</S.RequiredSpan>
              </S.PhotoLabel>
              <S.SubArticle>Available formats JPG, JPEG, PNG, max size 50MB</S.SubArticle>
              {!!formik.errors?.files && (
                <S.ErrorText>{'You must upload at least 1 photo'}</S.ErrorText>
              )}
            </S.PhotoWrapper>
            <label htmlFor="file">
              <S.AddPhotoBtn>
                <AddRectSVG />
                Upload photo
              </S.AddPhotoBtn>
              <input
                type="file"
                id="file"
                onChange={e => {
                  formik.setFieldError('files', '');
                  handleChangeFile(e);
                }}
                accept="image/png, image/jpeg"
                hidden
                multiple={true}
              ></input>
            </label>
          </S.UploadPhotoWrapper>
          <S.UploadedPhotos>
            <PhotoProvider>
              {formik.values.files?.map((item: File) => {
                return (
                  <PhotoView key={item.id} src={item?.url}>
                    <S.ImgWrapper>
                      <S.DeleteCircleSmall
                        onClick={e => {
                          e.stopPropagation();
                          handleDelete(item?.id);
                        }}
                      >
                        <CloseSVG />
                      </S.DeleteCircleSmall>
                      <img src={item?.url} alt=""></img>
                    </S.ImgWrapper>
                  </PhotoView>
                );
              })}
            </PhotoProvider>
            {statusUploadFiles.isLoading && (
              <S.LoaderWrapper>
                <CircularLoader color="#0084B1" />
              </S.LoaderWrapper>
            )}
          </S.UploadedPhotos>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default InsuranceSignUp;
