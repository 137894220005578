import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ConfirmMessage from './pages/Auth/components/ConfirmMessage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          <Route path="/*" element={<App />} />
          <Route path="/confirm" element={<ConfirmMessage />} />
        </Routes>
      </Router>
    </DndProvider>
  </Provider>,
);
