import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  AddMsgIconSVG,
  ArrowHideSVG,
  PlaceHolderChatSVG,
} from '../../../../../assets/icons';
import { CircularLoader, MessageInput, MessageItem } from '../../../../../components';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import * as S from '../styles';
import {
  setCurrentChat,
  setMessages,
  setNewMessage,
} from '../../../../../store/slices/chatSlice';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { getChats, readMessage, sendMessage } from '../../../../../hooks/useChat';
import { useDropzone } from 'react-dropzone';
import FileItem from '../FileItem';
import { CircularProgress, Tabs } from '@mui/material';
import {
  useDeleteFileMutation,
  useFilesCreateMutation,
} from '../../../../../store/api/files/files';
import { filter } from 'lodash';
import { status } from '../../../../../store/api/auth/authSlice';
import { MessageType, NewMessagesResponse } from '../../../../../types/ChatTypes';

type ResponseFile = {
  status: string;
  value: {
    id: string;
    name: string;
  };
};

type Props = {
  searchValue: string;
};

const MessagesBox: React.FC<Props> = ({ searchValue }) => {
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const messageResponse = useAppSelector(({ chats }) => chats.messageResponse);
  const newMessage = useAppSelector(({ chats }) => chats.newMessage);
  const currentChat = useAppSelector(({ chats }) => chats.currentChat);
  const myId = useAppSelector(state => state.auth.id);

  const [inputValue, setInputValue] = useState('');
  const [newMessages, setNewMessages] = useState<Array<NewMessagesResponse>>([]);

  const handleHideView = () => {
    dispatch(setMessages(null));
    dispatch(setCurrentChat(null));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
    dispatch(setNewMessage(null));
  }, [currentChat]);

  useEffect(() => {
    if (newMessage && newMessage.newMessage.chatId === currentChat) {
      setNewMessages([newMessage, ...newMessages]);
      readMessage(newMessage.newMessage.id, currentChat);
      // getChats(1, searchValue);
    } else {
      setNewMessages([]);
    }
  }, [newMessage]);

  const [uploadFiles, statusUpload] = useFilesCreateMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});

  const [filesValues, setFilesValues] = useState<Array<ResponseFile>>([]);

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      uploadFiles({ files: [...file] })
        .unwrap()
        .then(res => {
          setFilesValues([...filesValues, ...res]);
        })
        .catch(error => console.log(error));
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleDelete = (id: string) => {
    deleteFile({ id: id })
      .unwrap()
      .then(res => {
        const filteredArr = filesValues.filter(element => element.value.id !== id);
        setFilesValues(filteredArr);
      });
  };

  const handleSendMsg = () => {
    const ids: Array<string> = [];
    filesValues.map(file => {
      ids.push(file.value.id);
    });

    if (currentChat) {
      setInputValue('');
      sendMessage(inputValue, currentChat, ids);
      setFilesValues([]);
    }
  };

  return (
    <S.Messages>
      <S.MessagePlaceWrapper
        $hasFiles={
          (filesValues.length > 0 || statusUpload.isLoading || statusDelete.isLoading) &&
          true
        }
      >
        <S.Circle onClick={handleHideView}>
          <ArrowHideSVG />
        </S.Circle>
        <S.ScrollContainer className="messages">
          <>
            <div ref={messagesEndRef}></div>
            {newMessages.map(newMsg => {
              return (
                <MessageItem
                  isMine={newMsg.newMessage.userId === myId ? true : false}
                  text={newMsg.newMessage.text}
                  msg={newMsg.newMessage}
                  key={newMsg.newMessage.id}
                />
              );
            })}
            {messageResponse?.messages?.rows &&
            messageResponse.messages.rows.length > 0 ? (
              messageResponse?.messages?.rows?.map(msg => {
                return (
                  <MessageItem
                    isMine={msg.userId === myId ? true : false}
                    text={msg.text}
                    msg={msg}
                    key={msg.id}
                  />
                );
              })
            ) : (
              <S.NoMessageWrapper>
                <PlaceHolderChatSVG />
                <S.PlaceText>No new messages</S.PlaceText>
              </S.NoMessageWrapper>
            )}
          </>
        </S.ScrollContainer>
      </S.MessagePlaceWrapper>
      {(filesValues.length > 0 || statusUpload.isLoading || statusDelete.isLoading) && (
        <S.FileZone>
          <S.FileItems>
            <S.AddMsg onClick={handleClick}>
              {statusUpload.isLoading || statusDelete.isLoading ? (
                <CircularLoader />
              ) : (
                <AddMsgIconSVG />
              )}
            </S.AddMsg>
            {filesValues.length > 0 && (
              <>
                {filesValues.map(file => {
                  return (
                    <FileItem
                      key={file.value.id}
                      name={file.value.name}
                      onDelete={() => handleDelete(file.value.id)}
                    />
                  );
                })}
              </>
            )}
          </S.FileItems>
        </S.FileZone>
      )}
      <S.MessageInputWrapper>
        <MessageInput
          handleChangeFile={handleChangeFile}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          sendMsg={handleSendMsg}
          loading={statusUpload.isLoading || statusDelete.isLoading ? true : false}
          hasFiles={filesValues.length > 0 ? true : false}
        />
      </S.MessageInputWrapper>
      <label htmlFor="file">
        <input
          ref={inputRef}
          type="file"
          id="file"
          onChange={handleChangeFile}
          accept="image/png, image/jpeg, application/pdf"
          hidden
          multiple={true}
        ></input>
      </label>
    </S.Messages>
  );
};

export default MessagesBox;
