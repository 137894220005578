import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as S from './styles';
import {
  AllNotesDrawer,
  Button,
  Dialog,
  Drawer,
  MainProfileInformation,
  SuccessDialog,
  TabsItem,
} from '../../../../components';
import {
  AddPatientsSVG,
  ApproveBtnSVG,
  ArrowLSVG,
  ArrowLeftSVG,
  CloseBtnSVG,
  ConfirmBtnSVG,
  InfoCircleSVG,
  NoteSVG,
  PauseSVG,
  StartSVG,
} from '../../../../assets/icons';
import { useDispatch } from 'react-redux';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useBlockUserMutation,
  useHoldAppointmentMutation,
  useHoldBillingMutation,
  useUserProfileGetOneQuery,
  useUserProfileGetQuery,
} from '../../../../store/api/user/profileApi';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import ProfileInfo from '../../../Users/components/Profile/components/ProfileInfo';
import InsuranceInformation from '../../../Users/components/Profile/components/InsuranceInformation';
import { useApproveMutation } from '../../../../store/api/profile/profileApi';
import { setMessage } from '../../../../store/slices/message';
import { UserStatus } from '../../../../types/UserStatuses';
import Documents from '../../../Users/components/Profile/components/Documents';
import Payment from '../../../../components/organismus/ProfileViews/Payment';
import NewAppointmentDrawer from '../../../../components/organismus/NewAppointmentDrawer';
import VisitHistory from '../../../../components/organismus/ProfileViews/VisitHistory';
import { DrawerMode } from '../../../../types/DrawerMode';
import { Appointments, EventType } from '../../../../types/AppointmentsTypes';
import TreatmentForm from '../../../../components/organismus/ProfileViews/TreatmentForm';
import { default as TreatmentFormDrawer } from '../../../../components/organismus/Appointments/components/TreatmentForm';
import { Roles } from '../../../../types/Roles';
import HealthRecords from '../../../Users/components/Profile/components/HealthRecords';
import NoTreatmentPlaceholder from '../../../../components/organismus/ProfileViews/TreatmentForm/NoTreatmentPlaceholder/NoTreatmentPlaceholder';
import HealthAddDialog from './HealthAddDialog';
import ReasonAddDialog from './ReasonAddDialog';
import AdditionalHealthInfo from '../../../../components/organismus/ProfileViews/AdditionalHealthInfo';
import { Relation } from '../../../../types/enums/AppointmentEnum';
import { AppointmentsNew } from '../../../../types/AppointmentNewTypes';
import { showIcon } from './showIcon';
import InsuranceInformationV2 from '../../../Users/components/Profile/components/InsuranceInformation_v2';

type Props = {
  showIndex?: number;
};

const PatientProfile: React.FC<Props> = ({ showIndex }) => {
  const params = useParams();
  const isShowDrawer = useAppSelector(state => state.drawer.show);
  const userInfo = useAppSelector(state => state.auth);
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const profileOne = useUserProfileGetQuery({
    userId: params?.patientId,
  });

  const userProfile = profileOne?.data;

  const [searchParam, setSearchParam] = useSearchParams();

  const [approve] = useApproveMutation({});

  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentsNew | null>(
    null,
  );
  const [drawerMode, setDrawerMode] = useState('');

  const handleChangeDrawerMode = (
    mode: string,
    selectedAppointment: AppointmentsNew | null,
  ) => {
    setDrawerMode(mode);
    setSelectedAppointment(selectedAppointment);
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state?.event) {
      handleShowDialogCashBox();
    }
    if (state?.event || state?.startedAppointment || state?.startedAppointmentDocuments) {
      setTabIndex(7);
    } else if (state?.templateDocuments) {
      setTabIndex(6);
    }
  }, []);
  const [isShowHold, setIsShowHold] = useState(false);

  const [successAppointment, setSuccessAppointment] = useState(false);

  const [isShowHealthRecordDialog, setIsShowHealthRecordDialog] = useState(false);

  const handleShowDialogCashBox = () => {
    setIsShowHealthRecordDialog(!isShowHealthRecordDialog);
  };

  const [blockUser] = useBlockUserMutation({});

  const [holdAppointment] = useHoldAppointmentMutation({});
  const [holdBilling] = useHoldBillingMutation({});

  const [typeHold, setTypeHold] = useState('');

  return (
    <S.PatientProfileWrapper>
      <Dialog open={isShowHealthRecordDialog} onClose={handleShowDialogCashBox}>
        <HealthAddDialog
          isEditable={state?.isEditable ? true : false}
          event={state?.event}
          onClose={handleShowDialogCashBox}
        />
      </Dialog>
      <Dialog open={isShowHold} onClose={() => setIsShowHold(!isShowHold)}>
        <ReasonAddDialog
          onClose={() => setIsShowHold(false)}
          type={typeHold}
          id={params.patientId}
        />
      </Dialog>
      <Dialog
        open={successAppointment}
        onClose={() => setSuccessAppointment(!successAppointment)}
      >
        <SuccessDialog
          helperText="Are you sure, that you want to request an appointment?"
          successText="Continue"
          onClose={() => setSuccessAppointment(!successAppointment)}
          titleText="Appointments are holded"
          reason={profileOne.currentData?.isHoldAppointment}
          onSuccess={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.NEW_APPOINTMENT,
                props: userProfile,
              }),
            )
          }
        />
      </Dialog>
      <S.SubtitleBar>
        <S.SubtitleText
          onClick={() => {
            if (state?.isShowAllPatients) {
              navigate('/patients', { state: { isShowAllPatients: true } });
            } else {
              navigate('/patients');
            }
          }}
        >
          <ArrowLeftSVG /> Client Profile
        </S.SubtitleText>
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              backgroundColor={
                profileOne.currentData?.isHoldBilling ? '#79A030' : '#DD8500'
              }
              text={
                profileOne.currentData?.isHoldBilling ? 'Unhold Billing' : 'Hold Billing'
              }
              onClick={() => {
                profileOne.currentData?.isHoldBilling
                  ? holdBilling({
                      id: params.patientId,
                      text: null,
                    })
                      .unwrap()
                      .then(res =>
                        dispatch(setMessage({ message: res.message, type: 'success' })),
                      )
                      .catch(error => {
                        dispatch(
                          setMessage({
                            message: error.data.message,
                            type: 'error',
                          }),
                        );
                      })
                  : setIsShowHold(true);
                setTypeHold('Billing');
              }}
            >
              {profileOne.currentData?.isHoldBilling ? (
                <StartSVG className="white" />
              ) : (
                <PauseSVG className="white" />
              )}
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              backgroundColor={
                profileOne.currentData?.isHoldAppointment ? '#79A030' : '#DD8500'
              }
              text={
                profileOne.currentData?.isHoldAppointment
                  ? 'Unhold Appointments'
                  : 'Hold Appointments'
              }
              onClick={() => {
                profileOne.currentData?.isHoldAppointment
                  ? holdAppointment({
                      id: params.patientId,
                      text: null,
                    })
                      .unwrap()
                      .then(res =>
                        dispatch(setMessage({ message: res.message, type: 'success' })),
                      )
                      .catch(error => {
                        dispatch(
                          setMessage({
                            message: error.data.message,
                            type: 'error',
                          }),
                        );
                      })
                  : setIsShowHold(true);
                setTypeHold('Appointments');
              }}
            >
              {profileOne.currentData?.isHoldAppointment ? (
                <StartSVG className="white" />
              ) : (
                <PauseSVG className="white" />
              )}
            </Button>
          </S.ButtonWrapper>
          {(userInfo.role === Roles.SUPER_ADMIN ||
            userInfo.role === Roles.LOCATION_MANAGER) && (
            <S.ButtonWrapper>
              <Button
                backgroundColor={'#C66060'}
                text={
                  profileOne.currentData?.isBlocked === false
                    ? 'Ban Patient'
                    : 'Unlock Patient'
                }
                onClick={() => {
                  blockUser({
                    id: params.patientId,
                  })
                    .unwrap()
                    .then(res =>
                      dispatch(setMessage({ message: res.message, type: 'success' })),
                    )
                    .catch(error => {
                      dispatch(
                        setMessage({
                          message: error.data.message,
                          type: 'error',
                        }),
                      );
                    });
                }}
              >
                {profileOne.currentData?.isBlocked === false ? (
                  <CloseBtnSVG className="white" />
                ) : (
                  <ConfirmBtnSVG className="white" />
                )}
              </Button>
            </S.ButtonWrapper>
          )}

          {profileOne.currentData?.status === UserStatus.VERIFIED_FULL && (
            <>
              <S.ButtonWrapper className="large">
                <Button
                  text="Request an Appointment"
                  onClick={() => {
                    profileOne?.currentData?.isHoldAppointment
                      ? setSuccessAppointment(!successAppointment)
                      : dispatch(
                          showDrawer({
                            show: true,
                            mode: DrawerMode.NEW_APPOINTMENT,
                            props: userProfile,
                          }),
                        );
                  }}
                >
                  <AddPatientsSVG />
                </Button>
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  text="All notes"
                  backgroundColor="#475BA3"
                  onClick={() => {
                    dispatch(
                      showDrawer({
                        show: true,
                        mode: DrawerMode.ALL_NOTES,
                        props: userProfile,
                      }),
                    );
                  }}
                >
                  <NoteSVG />
                </Button>
              </S.ButtonWrapper>
            </>
          )}
          {profileOne.currentData?.status !== UserStatus.VERIFIED_FULL && (
            <S.ButtonWrapper>
              <Button
                text="Approve"
                onClick={() => {
                  approve({ id: profileOne.currentData?.profile.id })
                    .unwrap()
                    .then(res =>
                      dispatch(setMessage({ message: res.message, type: 'success' })),
                    )
                    .catch(error => {
                      dispatch(
                        setMessage({
                          message: error.data.message,
                          type: 'error',
                        }),
                      );
                    });
                }}
              >
                <ConfirmBtnSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.TabWrapper>
        <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
          <Tab disableRipple label="Profile Info"></Tab>
          <Tab disableRipple label="Demographics" className="demographics"></Tab>
          <Tab disableRipple label="Additional Health Info"></Tab>
          <Tab
            disableRipple
            label="Insurance"
            icon={
              showIcon(profileOne.currentData?.profile?.insurances) ? (
                <></>
              ) : (
                <InfoCircleSVG />
              )
            }
            className="warning"
          ></Tab>
          <Tab disableRipple label="Treatment Form"></Tab>
          <Tab disableRipple label="Visit history"></Tab>
          <Tab disableRipple label="Documents"></Tab>
          <Tab disableRipple label="Health records"></Tab>
        </TabsItem>
      </S.TabWrapper>
      <S.Content>
        <S.ProfileInformation className={tabIndex !== 0 ? 'hide' : 'default'}>
          <MainProfileInformation rows={profileOne.currentData} />
        </S.ProfileInformation>
        <S.TabInformation>
          {tabIndex === 0 && (
            <ProfileInfo rows={profileOne.currentData} className="hide" />
          )}
          {tabIndex === 1 && <ProfileInfo rows={profileOne.currentData} />}
          {tabIndex === 2 && <AdditionalHealthInfo rows={profileOne.currentData} />}
          {tabIndex === 3 && <InsuranceInformationV2 rows={profileOne.currentData} />}
          {tabIndex === 4 && (
            <>
              {profileOne.currentData?.lastTreatmentId ? (
                <TreatmentForm rows={profileOne.currentData} />
              ) : (
                <NoTreatmentPlaceholder />
              )}
            </>
          )}
          {tabIndex === 5 && (
            <VisitHistory
              rows={profileOne.currentData}
              // setSelectedAppointment={() => setSelectedAppointment()}
              onHandleChangeDrawerMode={handleChangeDrawerMode}
            />
          )}
          {tabIndex === 6 && <Documents rows={profileOne.currentData} />}
          {tabIndex === 7 && <HealthRecords />}
        </S.TabInformation>
      </S.Content>
    </S.PatientProfileWrapper>
  );
};

export default PatientProfile;
