import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  :hover {
    z-index: 100;
  }

  &.rr {
    box-shadow: 10px 5px 5px red !important;
  }
  //overflow: hidden;
`;

export const EventWrapper = styled.div<{ background: string; border: string }>`
  width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* overflow: hidden; */
  gap: 10px;
  border: 0.5px solid;
  position: relative;

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${props =>
    props.border &&
    css`
      border-color: ${props.border};
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  gap: 13px;
  overflow: hidden;
`;

export const SmallContent = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  align-items: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  gap: 3px;
`;

export const Time = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Type = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StatusBar = styled.div<{
  status: string;
}>`
  min-width: 6px;
  height: 100%;
  overflow: hidden;

  ${props =>
    props.status &&
    css`
      background: ${props.status};
    `}
  &.small {
    width: 4px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Username = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 6px;
  overflow: hidden;
`;

export const AvatarDiv = styled.div`
  img {
    width: 14px;
    height: 14px;
    object-fit: cover;
    border-radius: 14px;
  }
`;

export const ClinicsName = styled.div`
  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Atention = styled.div`
  padding-right: 14px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div`
  padding: 11px 30px 11px 13px;
  width: 100%;
  cursor: pointer;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: #e5f0f6;
    z-index: 100;
  }
`;

export const ButtonWrapper = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const ButtonAdd = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: -10px;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background: var(--main, #0084b1);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ButtonAddGreen = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: -10px;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background: var(--main, #79a030);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;
