import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  LinearLoader,
  MainProfileInformation,
  SelectInput,
  TabsItem,
  WorkSchedule,
} from '../../../../components';
import * as S from './styles';
import { ArrowLeftSVG, CloseBtnSVG, ConfirmBtnSVG } from '../../../../assets/icons';
import {
  useBlockUserMutation,
  useUserProfileGetQuery,
} from '../../../../store/api/user/profileApi';
import { Roles } from '../../../../types/Roles';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../store/slices/message';
import { useEffect, useMemo, useState } from 'react';
import { Clinics } from '../../../../types/ClinicTypes';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MenuItem, SelectChangeEvent, Tab } from '@mui/material';
import {
  useClinicsGetQuery,
  useStaffAssignToClinicMutation,
} from '../../../../store/api/clinics/clinicApi';
import { profile } from 'console';
import AssignTo from './components/AssignTo';
import InsuranceTo from './components/InsuranceTo';
import BillingAccess from './components/BillingAccess';
import StaffMainProfile from '../../../../components/organismus/StaffMainProfile';
import CredentialsDoctor from './components/CredentialsDoctor';
import StaffHealthRecords from './components/StaffHealthRecords';

const ProfileStaff = () => {
  const params = useParams();
  const navigate = useNavigate();

  const profileStaff = useUserProfileGetQuery({
    userId: params.profileId,
  });

  type FormValues = {
    clinics: Array<string>;
  };

  const Schema = yup.object().shape({});
  const [arr, setArr] = useState<string[]>([]);

  const formik = useFormik<FormValues>({
    initialValues: {
      clinics: [],
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: async () => {
      await assignToClinic({
        clinicIds: values.clinics,
        userId: params.profileId,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = formik;

  const [blockUser] = useBlockUserMutation({});
  const [assignToClinic] = useStaffAssignToClinicMutation({});
  const dispatch = useDispatch();

  useEffect(() => {
    const arr: string[] = [];
    profileStaff.currentData?.clinics.map((item: Clinics) => {
      arr.push(item.id);
    });
    setValues({ clinics: arr });
  }, [profileStaff]);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate('/staff')}>
          <ArrowLeftSVG /> Back
        </S.SubtitleText>
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              backgroundColor={
                profileStaff.currentData?.isBlocked ? '#79A030' : '#C66060'
              }
              text={
                profileStaff.currentData?.isBlocked
                  ? 'Activate account'
                  : 'Deactivate account'
              }
              onClick={() => {
                blockUser({
                  id: params.profileId,
                })
                  .unwrap()
                  .then(res =>
                    dispatch(
                      setMessage({
                        message: res.message,
                        type: 'success',
                      }),
                    ),
                  )
                  .catch(error => {
                    dispatch(
                      setMessage({
                        message: error.data.message,
                        type: 'error',
                      }),
                    );
                  });
              }}
            >
              {profileStaff.currentData?.isBlocked ? (
                <ConfirmBtnSVG className="white" />
              ) : (
                <CloseBtnSVG className="white" />
              )}
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.TabWrapper>
        <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
          <Tab disableRipple label="Profile Info"></Tab>
          {profileStaff.currentData?.role === Roles.DOCTOR && (
            <Tab disableRipple label="Credentials"></Tab>
          )}
          {profileStaff.currentData?.role === Roles.DOCTOR && (
            <Tab disableRipple label="Schedule"></Tab>
          )}
          {profileStaff.currentData?.role === Roles.DOCTOR && (
            <Tab disableRipple label="Insurance"></Tab>
          )}
          {profileStaff.currentData?.role === Roles.DOCTOR && (
            <Tab disableRipple label="Health records"></Tab>
          )}
        </TabsItem>
      </S.TabWrapper>
      <S.Content>
        <S.ProfileInformation>
          <StaffMainProfile row={profileStaff.currentData} />
        </S.ProfileInformation>
        <S.TabInformation>
          {profileStaff.isFetching || profileStaff.isLoading ? (
            <LinearLoader />
          ) : (
            <>
              {tabIndex === 0 && profileStaff.currentData?.role !== Roles.BILLING && (
                <AssignTo profile={profileStaff.currentData} />
              )}
              {tabIndex === 0 && profileStaff.currentData?.role === Roles.BILLING && (
                <BillingAccess billingInfo={profileStaff.currentData} />
              )}
              {tabIndex === 1 && <CredentialsDoctor profile={profileStaff.currentData} />}
              {tabIndex === 2 && (
                <WorkSchedule
                  staffId={params.profileId}
                  assignClinics={profileStaff.currentData?.clinics}
                />
              )}
              {tabIndex === 3 && (
                <InsuranceTo assignClinics={profileStaff.currentData?.clinics} />
              )}
              {tabIndex === 4 && <StaffHealthRecords />}
            </>
          )}
        </S.TabInformation>
      </S.Content>
    </S.Wrapper>
  );
};

export default ProfileStaff;
