import { MouseEvent, MouseEventHandler, useState } from 'react';
import { getBackgroundColors } from '../../../../../../../helpers/getAppointmetColor';
import { AppointmentsNew } from '../../../../../../../types/AppointmentNewTypes';
import * as S from './styles';
import { DeleteAppointmentSVG, DuplicateSVG } from '../../../../../../../assets/icons';
import { DrawerMode } from '../../../../../../../types/DrawerMode';
import { showDrawer } from '../../../../../../../store/slices/drawerSlice';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import ConfirmDeleteDialog from '../../../../../../molecules/ConfirmDeleteDialog';
import { Dialog, NotificationPopover, RecordItem } from '../../../../../..';
import { AppointmentStatus } from '../../../../../../../types/StatusTypes';
import { setMessage } from '../../../../../../../store/slices/message';
import { useAppointmentDeleteMutation } from '../../../../../../../store/api/appointment/appointmentApi';
import { convertAMtoPM } from '../../../../../../../helpers/functions/convertAMtoPM';
import dayjs from 'dayjs';
import AppointmentPinV2 from '../../../../../../atoms/AppointmentPinV2';
import ChangeStatusPopoverContent from '../../../PlannerView/components/ChangeStatusPopoverContent';
import AppointmentCard from '../../../PlannerView/components/AppointmentCard';

type Props = {
  row: AppointmentsNew;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const PendingRecordItem: React.FC<Props> = ({ row, onClick }) => {
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);

  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const handleAppointmentClick = (item: AppointmentsNew) => {
    dispatch(showDrawer({ show: true, mode: DrawerMode.INFO_APPOINTMENT, props: item }));
  };

  const handleTreatmentClick = (e: MouseEvent<HTMLDivElement>, item: AppointmentsNew) => {
    e.stopPropagation();
    dispatch(showDrawer({ show: true, mode: DrawerMode.NEW_TREATMENT, props: item }));
  };

  return (
    <S.RecordItemWrapper>
      <S.BtnColumn>
        {row.status === AppointmentStatus.PENDING && (
          <>
            <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
              <ConfirmDeleteDialog
                onClose={() => setOpenDialog(!openDialog)}
                titleText={'appointment'}
                onSuccess={() =>
                  deleteAppointment({ id: row.id })
                    .unwrap()
                    .then(res => {
                      dispatch(
                        setMessage({
                          message: 'Appointment was successfully deleted',
                          type: 'success',
                        }),
                      );
                    })
                    .catch(error => {
                      dispatch(
                        setMessage({
                          message: error.data.message,
                          type: 'error',
                        }),
                      );
                    })
                }
              />
            </Dialog>
            <DeleteAppointmentSVG onClick={() => setOpenDialog(!openDialog)} />
          </>
        )}
      </S.BtnColumn>
      <S.BtnColumn>
        <DuplicateSVG
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.DUPL_APPOINTMENT,
                props: row,
              }),
            )
          }
        />
      </S.BtnColumn>
      <RecordItem
        row={row}
        onClick={e => {
          e.stopPropagation();
          handleAppointmentClick(row);
        }}
        onTreatmentClick={e => {
          handleTreatmentClick(e, row);
        }}
      />
      {/* <AppointmentCard appointment={row} isOnlyPending /> */}
    </S.RecordItemWrapper>
  );
};

export default PendingRecordItem;
