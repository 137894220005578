import dayjs from 'dayjs';
import MultiDatePicker from '../../../../molecules/MultiDatePicker';
import * as S from './styles';
import {
  AppointmentTypeEnum,
  ModeEnum,
} from '../../../../../types/enums/AppointmentEnum';
import { handleChangeDate } from '../../../../../helpers/CalendarHelpers/handleDates';
import { DateObject } from 'react-multi-date-picker';
import { useState } from 'react';
import { useClinicScheduleWeekendsQuery } from '../../../../../store/api/clinic-schedule/clinicScheduleApi';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { FilterSVG } from '../../../../../assets/icons';
import SelectInput from '../../../../atoms/SelectInput';
import {
  appointmentStatuses,
  visitReasonsList,
} from '../../../../../constants/constants';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import CheckBox from '../../../../atoms/CheckBox';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import CheckBoxWithLabel from '../../../../atoms/CheckboxWithLabel';
import { useGetAllDoctorsQuery } from '../../../../../store/api/staff/staffApi';
import { Patients } from '../../../../../types/ClinicTypes';

type Props = {
  mode: ModeEnum;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedDate: DateObject[];
  setSelectedDate: React.Dispatch<React.SetStateAction<DateObject[]>>;
  reasonType: AppointmentTypeEnum[];
  setReasonType: React.Dispatch<React.SetStateAction<AppointmentTypeEnum[]>>;
  appointmentStatus: any;
  setAppointmentStatus: React.Dispatch<
    React.SetStateAction<AppointmentStatus[] | string>
  >;
  isFetching: boolean;
  isModePending: boolean;
  setIsModePending: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredDoctor: React.Dispatch<React.SetStateAction<string[]>>;
  filteredDoctor: string[];
};

const CalendarBar: React.FC<Props> = ({
  mode,
  setPage,
  selectedDate,
  setSelectedDate,
  reasonType,
  setReasonType,
  appointmentStatus,
  isFetching,
  setAppointmentStatus,
  isModePending,
  setIsModePending,
  filteredDoctor,
  setFilteredDoctor,
}) => {
  const selClinic = useAppSelector(state => state.selectedClinic);

  const [currentMonth, setCurrentMonth] = useState<DateObject>(new DateObject());

  const weekends = useClinicScheduleWeekendsQuery({
    clinicId: selClinic.id,
    date: currentMonth.format('YYYY-MM-01'),
  });

  const handleChangeSelect = (event: SelectChangeEvent<typeof appointmentStatus>) => {
    const {
      target: { value },
    } = event;
    setAppointmentStatus(typeof value === 'string' ? value.split(',') : value);
    setPage(1);
  };

  const getDoctors = useGetAllDoctorsQuery({
    take: 500,
    'userStatus[]': 'active',
  });

  return (
    <S.CalendarBarWrapper>
      <MultiDatePicker
        disabled={weekends.isLoading || weekends.isFetching || isModePending}
        disabledClick={weekends.isLoading || weekends.isFetching || isModePending}
        onMonthChange={date => setCurrentMonth(date)}
        weekPicker={mode === ModeEnum.WEEK ? true : false}
        onlyMonthPicker={mode === ModeEnum.MONTH ? true : false}
        onlyYearPicker={mode === ModeEnum.YEAR ? true : false}
        mapDays={({ date }) => {
          let color;
          if (
            weekends.currentData &&
            weekends.currentData.map((item: string) => {
              return (
                dayjs(item.slice(0, 10)).format('MM/DD/YYYY') ===
                  date.format('MM/DD/YYYY') && (color = '#C66060')
              );
            })
          )
            if (color)
              return {
                style: { color: '#C66060' },
                className: 'colored',
              };
            else return;
        }}
        value={selectedDate}
        onChange={(value: any) => {
          handleChangeDate(mode, setSelectedDate, value, setPage);
        }}
        multiple={false}
      />
      <S.Filters>
        <S.FilterWrapper>
          <SelectInput
            label="Status filter"
            multiple
            disabled={isFetching || isModePending}
            value={appointmentStatus}
            renderValue={(selected: any) => (
              <S.SelectInputOutput>
                <FilterSVG />
                <S.SelectInputOutputText>
                  {selected?.length === 0
                    ? 'Status not set'
                    : selected?.join(', ').toUpperCase()}
                </S.SelectInputOutputText>
              </S.SelectInputOutput>
            )}
            onChange={handleChangeSelect}
          >
            {appointmentStatuses.map(status => (
              <MenuItem key={status} value={status} disabled={isFetching}>
                <S.MenuItemContent>
                  <CheckBox checked={appointmentStatus.indexOf(status) > -1} />
                  {status.toUpperCase()}
                </S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.FilterWrapper>

        <S.FilterWrapper>
          <SelectInput
            label="Visit reason filter"
            multiple
            disabled={isFetching}
            value={reasonType}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setReasonType(e.target.value as AppointmentTypeEnum[]);
            }}
            renderValue={(selected: any) => (
              <S.SelectInputOutput>
                <FilterSVG />
                <S.SelectInputOutputText>
                  {selected?.length === 0
                    ? 'Filter not set'
                    : selected?.join(', ').toUpperCase()}
                </S.SelectInputOutputText>
              </S.SelectInputOutput>
            )}
          >
            {visitReasonsList.map(reason => (
              <MenuItem key={reason.value} value={reason.value} disabled={isFetching}>
                <S.MenuItemContent>
                  <CheckBox
                    checked={reasonType.indexOf(reason.value as AppointmentTypeEnum) > -1}
                  />
                  {reason.name.toUpperCase()}
                </S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.FilterWrapper>
        <S.FilterWrapper>
          <SelectInput
            label="Selected doctor filter"
            multiple
            disabled={isFetching}
            value={filteredDoctor}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setFilteredDoctor(e.target.value as string[]);
            }}
            renderValue={(selected: any) => {
              return (
                <S.SelectInputOutput>
                  <FilterSVG />
                  <S.SelectInputOutputText>
                    {selected?.length === 0
                      ? 'All doctors'
                      : `Filtered doctors: ${selected?.length}`}
                  </S.SelectInputOutputText>
                </S.SelectInputOutput>
              );
            }}
          >
            {getDoctors.data?.rows.map((doctor: Patients) => (
              <MenuItem key={doctor.id} value={doctor.userId} disabled={isFetching}>
                <S.MenuItemContent>
                  <CheckBox
                    checked={filteredDoctor.indexOf(doctor.userId) > -1 || false}
                  />
                  {doctor.user?.profile?.firstName + ' ' + doctor.user?.profile?.lastName}
                </S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.FilterWrapper>
        <S.CheckboxWrapper>
          <CheckBoxWithLabel
            label="Show pending appointments at all times"
            gap="15px"
            checked={isModePending}
            onChange={() => {
              setPage(1);
              setIsModePending(!isModePending);
            }}
            value={isModePending}
          />
        </S.CheckboxWrapper>
      </S.Filters>
    </S.CalendarBarWrapper>
  );
};

export default CalendarBar;
