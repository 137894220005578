import { useParams } from 'react-router-dom';
import {
  DeleteBlueBtnSVG,
  DeleteGrayBtnSVG,
  EditBlueBtnSVG,
  EditGrayBtnSVG,
  PlacePinSVG,
} from '../../../../../../../../assets/icons';
import {
  useStaffAssignToClinicMutation,
  useStaffRemoveFromClinicMutation,
} from '../../../../../../../../store/api/clinics/clinicApi';
import { Clinics } from '../../../../../../../../types/ClinicTypes';
import * as S from './styles';
import dayjs from 'dayjs';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../../../store/slices/message';
import { Dialog } from '../../../../../../../../components';
import AssignClinicDialog from '../AssignClinicDialog';
import { useState } from 'react';

type Props = {
  assignInfo: Clinics;
  clinicsList: Clinics[];
};

const AssignRecord: React.FC<Props> = ({ assignInfo, clinicsList }) => {
  const [deleteFromClinic] = useStaffRemoveFromClinicMutation({});
  const { profileId } = useParams();
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <S.Wrapper $isWorkNow={assignInfo.isWorkInClinic || false}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          userId={profileId || ''}
          assignInfo={assignInfo}
          onClose={() => setOpenDialog(!openDialog)}
        />
      </Dialog>
      <S.ClinicInfo>
        <PlacePinSVG />
        <S.ClinicName>{assignInfo.name}</S.ClinicName>
      </S.ClinicInfo>

      <S.WorkRange>
        <S.Date>
          Start date: <S.SpanData>{assignInfo.workingStartDate || '-'}</S.SpanData>
        </S.Date>
        <S.Date>
          End date: <S.SpanData>{assignInfo.workingEndDate || 'till now'}</S.SpanData>
        </S.Date>
      </S.WorkRange>
      <S.Buttons>
        <S.ButtonWrap
          $isWorkNow={assignInfo.isWorkInClinic || false}
          onClick={() => setOpenDialog(!openDialog)}
        >
          {assignInfo.isWorkInClinic ? <EditBlueBtnSVG /> : <EditGrayBtnSVG />}
        </S.ButtonWrap>
        <S.ButtonWrap
          $isWorkNow={assignInfo.isWorkInClinic || false}
          onClick={() =>
            deleteFromClinic({
              clinicId: assignInfo.id,
              staffId: profileId,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Clinic was succesfully unassigned from user',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              })
          }
        >
          {assignInfo.isWorkInClinic ? <DeleteBlueBtnSVG /> : <DeleteGrayBtnSVG />}
        </S.ButtonWrap>
      </S.Buttons>
    </S.Wrapper>
  );
};

export default AssignRecord;
