import { FormikProps } from 'formik';
import Input from '../../../../atoms/Input';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { ChangeEvent, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { UserProfile } from '../../../../../types/UserProfileTypes';
import { AppointmentsNew } from '../../../../../types/AppointmentNewTypes';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import AutoCompleteInput from '../../../../atoms/AutoComplete';
import { useAllPatientsGetMutation } from '../../../../../store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { Data } from '../../../../../pages/Billing/components/Claims/components/ClaimItem/styles';
import {
  useUserDeepSearchMutMutation,
  useUsersGetDeepSearchQuery,
} from '../../../../../store/api/searchApi/searchApi';
import OptionContent from './OptionContent';
import { ClearFiltersSVG, UnlockSVG } from '../../../../../assets/icons';
import FirstName from './FirstName';
import LastName from './LastName';
import Phone from './Phone';
import Email from './Email';
import DOB from './DOB';
import dayjs from 'dayjs';
import { Dialog, SuccessDialog } from '../../../..';
import { useUnLockPendingAppointmentMutation } from '../../../../../store/api/appointment/appointmentApi';
import { showDrawer } from '../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../types/DrawerMode';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../store/slices/message';
import { Roles } from '../../../../../types/Roles';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
};

const PatientInformationBlock: React.FC<Props> = ({
  formik,
  userProfile,
  selectedAppointment,
  duplAppointment,
  byClick,
}) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    if (userProfile) {
      console.log('userprifle', userProfile);
      formik.setValues({
        ...formik.values,
        firstName: userProfile.profile?.firstName,
        lastName: userProfile.profile?.lastName,
        phone: userProfile.phone,
        email: userProfile.email,
        dateOfBirth: dayjs(userProfile.profile?.dateOfBirth).format('MM/DD/YYYY'),
        userId: userProfile.id || '',
      });
    }
  }, [userProfile]);

  const [selectedValue, setSelectedValue] = useState<any>('');

  const handleClearFilters = () => {
    formik.setValues({
      ...formik.values,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      dateOfBirth: '',
      userId: '',
    });
    setSelectedValue('');
  };

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [unlockAppointment] = useUnLockPendingAppointmentMutation({});
  const dispatch = useAppDispatch();

  const me = useAppSelector(state => state.auth);

  return (
    <S.PatientInformation>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(!openSuccessDialog)}
      >
        <SuccessDialog
          onClose={() => setOpenSuccessDialog(!openSuccessDialog)}
          titleText="Are you sure you want to make a change to previously entered information?"
          helperText="This will cause a modification of the corresponding fields in Appointment"
          onSuccess={() => {
            unlockAppointment({
              id: selectedAppointment?.id,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.INFO_APPOINTMENT,
                    props: res,
                  }),
                );
              });
          }}
        />
      </Dialog>
      <S.SubArticle>
        Patient information{' '}
        {byClick &&
          (selectedValue || selectedAppointment?.isPossibleToUpdate === true) && (
            <S.SVG onClick={() => handleClearFilters()}>
              <ClearFiltersSVG />
              Clear user information
            </S.SVG>
          )}
        {selectedAppointment?.isPossibleToUpdate === false &&
          selectedAppointment?.status === AppointmentStatus.PENDING && (
            <S.SVG onClick={() => setOpenSuccessDialog(true)}>
              <UnlockSVG />
              Unlock for editing
            </S.SVG>
          )}
      </S.SubArticle>
      <S.InputRow>
        <FirstName
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <LastName
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      </S.InputRow>
      <S.InputRow>
        <Phone
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <Email
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      </S.InputRow>
      {me.role !== Roles.PATIENT && (
        <S.InputRow className="half">
          <DOB
            formik={formik}
            userProfile={userProfile}
            selectedAppointment={selectedAppointment}
            duplAppointment={duplAppointment}
            byClick={byClick}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </S.InputRow>
      )}
      {me.role !== Roles.PATIENT &&
        (userProfile?.clinics[0]?.accountNumber ||
          selectedAppointment?.patient?.clinics[0]?.ClinicUser?.accountNumber) &&
        !selectedAppointment?.isPossibleToUpdate && (
          <S.AccountNumber>
            Patient account number:{' '}
            {userProfile
              ? userProfile.clinics?.[0]?.accountNumber
              : selectedAppointment?.patient?.clinics?.[0]?.ClinicUser?.accountNumber}
          </S.AccountNumber>
        )}
      {me.role !== Roles.PATIENT &&
        (userProfile?.profile?.insurances?.[0]?.primaryInsuranceCompany ||
          selectedAppointment?.patient?.profile?.insurances?.[0]
            ?.primaryInsuranceCompany) &&
        !selectedAppointment?.isPossibleToUpdate && (
          <S.AccountNumber>
            Primary Insurance:{' '}
            {userProfile
              ? userProfile.profile?.insurances?.filter(
                  insurance => insurance.isPrimary === true,
                )[0]?.primaryInsuranceCompany
              : selectedAppointment?.patient?.profile?.insurances?.filter(
                  insurance => insurance.isPrimary === true,
                )[0]?.primaryInsuranceCompany}
          </S.AccountNumber>
        )}
      {(me.role === Roles.PATIENT ||
        (selectedAppointment &&
          selectedAppointment.patientNote &&
          selectedAppointment?.status !== AppointmentStatus.ACTIVE &&
          selectedAppointment?.status !== AppointmentStatus.COMPLETED)) && (
        <S.InputRow>
          <Input
            label="Patient note"
            id="patientNote"
            name="patientNote"
            error={!!formik.errors.patientNote}
            helperText={formik.errors.patientNote as string}
            value={formik.values.patientNote}
            onChange={handleChangeInput}
            disabled={selectedAppointment?.status === AppointmentStatus.CANCELED}
            multiline
            rows={4}
          />
        </S.InputRow>
      )}
    </S.PatientInformation>
  );
};

export default PatientInformationBlock;
