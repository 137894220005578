import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--line, #d7d7d7);
  background: #fafafa;

  &:hover {
    border: 1px solid var(--line, #0084b1);
    background: #e5f0f6;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  margin-right: 10px;
`;

export const Content = styled.div`
  padding: 6px 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

export const Span = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const CardType = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  height: 20px;
  max-height: 20px;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  /* max-width: 65px; */
  width: fit-content;
  line-height: normal;
  padding: 2px 20px;
  border-radius: 3px;
  background: ${props => props.color && props.color};
`;

export const Down = styled.div`
  display: flex;

  justify-content: end;
  svg {
    width: 18px;
    height: 18px;
  }
  width: 100%;
`;

export const DeleteWrapper = styled.div`
  padding: 0px 5px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AccNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Avatar = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;

  img {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    object-fit: cover;
  }

  svg {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
`;

export const Name = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-left: 3px;
`;

export const Data = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ACCNumber = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-style: italic;
  font-size: 13px;

  font-weight: 500;
  line-height: normal;
`;

export const PayerTitle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
