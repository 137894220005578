import { useState } from 'react';
import { AddClinicIconSVG, ArrowLSVG, ArrowRSVG } from '../../../../assets/icons';
import { Button, CustomTable, SearchInput } from '../../../../components';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  useAllClinicsGetQuery,
  useClinicsGetQuery,
} from '../../../../store/api/clinics/clinicApi';
import ClinicsRow from './TableData/ClinicsRow';
import { DrawerMode } from '../../../../types/DrawerMode';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { useDispatch } from 'react-redux';

const Clinics = () => {
  const headers = [
    { name: 'Clinic name', key: 'ClinicName' },
    { name: 'Status', key: 'Status' },
    { name: 'Phone', key: 'Phone' },
    { name: 'Email', key: 'Email' },
    { name: 'Address', key: 'Address' },
    { name: 'Local Admin', key: 'LocalAdmin' },
    { name: 'Chat link', key: 'Chatlink' },
  ];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const listResult = useAllClinicsGetQuery({
    q: debouncedSearch,
  });
  const dispatch = useDispatch();

  //   const cashResult = useCashboxGetQuery({
  //     clinicId: selectedClinic.id,
  //   });

  const handleShowCount = () => {
    if (listResult?.currentData?.count && listResult.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {listResult?.currentData?.count && listResult.currentData.count < secondCount
            ? listResult.currentData.count
            : secondCount}{' '}
          of {listResult?.currentData?.count} clinics{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {listResult?.currentData?.count} clinics{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>All Clinics</S.SubtitleText>
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              text="Add Clinic"
              onClick={() => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.ADD_CLINIC,
                    props: null,
                  }),
                );
              }}
            >
              <AddClinicIconSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <S.Content>
          <S.HeaderTable>
            <S.PaginationWrapper>
              <S.Arrows>
                <ArrowLSVG
                  onClick={handleBack}
                  className={listResult.currentData?.page === 1 ? 'disabled' : ''}
                />
                <ArrowRSVG
                  onClick={handleForward}
                  className={
                    listResult.currentData?.page === listResult.currentData?.lastPage
                      ? 'disabled'
                      : ''
                  }
                />
              </S.Arrows>
              {listResult.isFetching ? <div>...</div> : handleShowCount()}
            </S.PaginationWrapper>
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.HeaderTable>
          <S.TableBody>
            <CustomTable headers={headers} isFetching={listResult.isFetching}>
              <ClinicsRow
                clickable
                data={listResult?.currentData?.rows}
                headers={headers}
              />
            </CustomTable>
          </S.TableBody>
        </S.Content>
      </S.Content>
    </S.Wrapper>
  );
};

export default Clinics;
