import { ChangeEvent, useEffect, useState } from 'react';
import { Input, SelectInput } from '../../../../../../components';
import * as S from '../../styles';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { FormikProps } from 'formik';
import { FormValuesAuth } from '../../SignUpDetails';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from '../../../../../../constants/statesUSA';
import ReactInputMask from 'react-input-mask';

type Props = {
  formik: FormikProps<FormValuesAuth>;
};

const ContactSignUp: React.FC<Props> = ({ formik }) => {
  const userInfo = useAppSelector(state => state.auth);
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    userInfo?.phone && formik.setFieldValue('cellPhone', userInfo?.phone);
    userInfo?.email && formik.setFieldValue('email', userInfo?.email);
  }, [userInfo]);

  return (
    <>
      <S.StepContent>
        <S.ContactInfoWrapper>
          <S.ArticleH3>Contact Info</S.ArticleH3>
          <S.Inputs>
            <S.InputRow>
              <Input
                isRequired
                id="address"
                name="address"
                value={formik.values.address}
                error={!!formik.errors.address}
                helperText={formik.errors.address}
                onChange={handleChangeInput}
                label="Address line 1"
                placeholder="House name, number and street, P.O. box"
                background="#fff"
              />
              <Input
                id="address2"
                name="address2"
                value={formik.values.address2}
                error={!!formik.errors.address2}
                helperText={formik.errors.address2}
                onChange={handleChangeInput}
                label="Address line 2"
                background="#fff"
                placeholder="Apartment, suite, unit, building, floor, etc."
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                isRequired
                label="City"
                background="#fff"
                id="city"
                name="city"
                value={formik.values.city}
                error={!!formik.errors.city}
                helperText={formik.errors.city}
                onChange={handleChangeInput}
              />
              <SelectInput
                label="State"
                background="#fff"
                isRequired
                id="state"
                name="state"
                value={formik.values.state}
                error={!!formik.errors.state}
                helperText={formik.errors.state}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  formik.setFieldError('state', '');
                  formik.handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.name} value={state.name}>
                    <S.MenuItemContent>{state.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>

              <Input
                isRequired
                label="Zipcode"
                background="#fff"
                id="zipcode"
                name="zipcode"
                value={formik.values.zipcode}
                error={!!formik.errors.zipcode}
                helperText={formik.errors.zipcode}
                onChange={handleChangeInput}
              />
            </S.InputRow>

            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={formik.values.cellPhone}
                onChange={handleChangeInput}
                disabled={userInfo?.phone ? true : false}
              >
                <Input
                  label="Cell Phone "
                  background="#fff"
                  id="cellPhone"
                  isRequired
                  name="cellPhone"
                  // disabled={userInfo?.phone ? true : false}
                  error={!!formik.errors.cellPhone}
                  helperText={formik.errors.cellPhone}
                />
              </ReactInputMask>

              <ReactInputMask
                mask="+19999999999"
                value={formik.values.homePhone}
                onChange={handleChangeInput}
                disabled={userInfo?.phone ? true : false}
              >
                <Input
                  label="Home Phone "
                  background="#fff"
                  id="homePhone"
                  name="homePhone"
                  error={!!formik.errors.homePhone}
                  helperText={formik.errors.homePhone}
                />
              </ReactInputMask>
              <Input
                isRequired
                label="Email"
                background="#fff"
                id="email"
                name="email"
                disabled={userInfo?.email ? true : false}
                value={userInfo?.email || formik.values.email}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                onChange={handleChangeInput}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                label="Emergency contact"
                background="#fff"
                id="emergencyContact"
                name="emergencyContact"
                value={formik.values.emergencyContact}
                error={!!formik.errors.emergencyContact}
                helperText={formik.errors.emergencyContact}
                onChange={handleChangeInput}
              />
              <ReactInputMask
                mask="+19999999999"
                value={formik.values.emergencyPhone}
                onChange={handleChangeInput}
              >
                <Input
                  label="Emergency contact phone"
                  background="#fff"
                  id="emergencyPhone"
                  name="emergencyPhone"
                  error={!!formik.errors.emergencyPhone}
                  helperText={formik.errors.emergencyPhone}
                />
              </ReactInputMask>
            </S.InputRow>
          </S.Inputs>
        </S.ContactInfoWrapper>
      </S.StepContent>
    </>
  );
};

export default ContactSignUp;
