import { Clinics, ClinicsExtend, Patients } from '../../../types/ClinicTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id?: string;
  take?: number;
};

export const clinicApi = api.injectEndpoints({
  endpoints: builder => ({
    clinicsGet: builder.query<Response<ClinicsExtend>, any>({
      query: () => ({
        url: '/clinic?take=20',
      }),
      providesTags: ['Appointments', 'User', 'Files', 'Clinics'],
    }),
    allClinicsGet: builder.query<Response<Clinics>, any>({
      query: ({ q, take, ...params }) => ({
        url: '/clinic',
        params: { q: q, take: take || 20, ...params },
      }),
      providesTags: ['Appointments', 'User', 'Files', 'Clinics'],
    }),
    clinicsGetOne: builder.query<ClinicsExtend, any>({
      query: ({ id }) => ({
        url: `/clinic/${id}?take=20`,
      }),
      providesTags: [
        'Appointments',
        'User',
        'Files',
        'Clinics',
        'ClinicSchedule',
        'Staff',
      ],
    }),
    clinicsCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/clinic`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Clinics'],
    }),
    patientsGet: builder.query<Response<Patients>, Request>({
      query: ({ id, q, page, take }) => ({
        url: `/clinic/${id}/patient?page=${page}&take=${take ?? 20}`,
        params: { q },
      }),
      providesTags: ['User'],
    }),
    allPatientsInClinicGet_v2: builder.mutation({
      query({ ...params }) {
        return {
          url: `/clinic/${params.id}/patient?take=20`,
          params: { ...params },
          method: 'GET',
        };
      },
    }),
    allPatientsGet_v2: builder.mutation({
      query({ ...params }) {
        return {
          url: `/user/patients/all?take=20`,
          params: { ...params },
          method: 'GET',
        };
      },
    }),

    allPatientsGet: builder.mutation({
      query({ id, q }) {
        return {
          url: `/clinic/${id}/patient?take=5`,
          method: 'GET',
          params: { q },
        };
      },
    }),
    downloadCSV: builder.query<any, Request>({
      query: ({ id }) => ({
        url: `/clinic/${id}/patient/csv`,
      }),
    }),
    csvDownload: builder.mutation({
      query({ id }) {
        return {
          url: `/clinic/${id}/patient/csv`,
          method: 'GET',
          responseHandler: async response =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        };
      },
    }),
    addPatient: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/clinic/${id}/add-patient`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['User'],
    }),
    staffGet: builder.query<Response<any>, any>({
      query: ({ id, ...params }) => ({
        url: `/clinic/${id}/staff`,
        params: { ...params },
      }),
      providesTags: ['Staff'],
    }),
    billingsGet: builder.query<Response<any>, any>({
      query: ({ ...params }) => ({
        url: `/clinic/billings?take=20`,
        params: { ...params },
      }),
      providesTags: ['Staff'],
    }),
    staffCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/clinic/add-staff`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Staff'],
    }),
    updateLogoClinic: builder.mutation({
      query: body => {
        const newData = new FormData();
        newData.append('logo', body.logo);
        return {
          url: `clinic/${body.id}/update-logo`,
          method: 'PATCH',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Clinics' }],
    }),
    clinicUpdate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/clinic/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Clinics'],
    }),
    StaffRemoveFromClinic: builder.mutation({
      query: ({ clinicId, staffId }) => ({
        url: `/clinic/${clinicId}/remove-staff-from-clinic/${staffId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Staff', 'Clinics', 'User'],
    }),
    staffAssignToClinic: builder.mutation({
      query: body => ({
        url: `/clinic/assign-staff-to-clinic`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Staff', 'Clinics', 'User'],
    }),
  }),
});

export const {
  useClinicsGetQuery,
  usePatientsGetQuery,
  useLazyDownloadCSVQuery,
  useCsvDownloadMutation,
  useAddPatientMutation,
  useAllPatientsGetMutation,
  useStaffGetQuery,
  useStaffCreateMutation,
  useClinicsCreateMutation,
  useClinicsGetOneQuery,
  useAllClinicsGetQuery,
  useUpdateLogoClinicMutation,
  useClinicUpdateMutation,
  useBillingsGetQuery,
  useStaffRemoveFromClinicMutation,
  useStaffAssignToClinicMutation,
  useAllPatientsGet_v2Mutation,
  useAllPatientsInClinicGet_v2Mutation,
} = clinicApi;
