import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isWorkNow: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;
  padding: 10px 20px;
  opacity: 0.8;

  ${props =>
    props.$isWorkNow &&
    css`
      border: 0.5px solid var(--main, #0084b1);
      background: var(--hover-1, rgba(0, 132, 177, 0.08));

      opacity: 1;
    `}
`;

export const ClinicInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ClinicName = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 300px;
`;

export const WorkRange = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Date = styled.div`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SpanData = styled.span`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonWrap = styled.div<{ $isWorkNow: boolean }>`
  display: flex;
  padding: 7px;
  min-height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 0.5px solid var(--line, #d7d7d7);
  background: #f9f9fc;

  &:hover {
    cursor: pointer;
    background: var(--hover-1, rgba(215, 215, 215, 0.4));
  }

  ${props =>
    props.$isWorkNow &&
    css`
      cursor: pointer;
      &:hover {
        background: var(--hover-1, rgba(0, 132, 177, 0.15));
      }

      border: 0.5px solid var(--main, #0084b1);
      background: var(--hover-1, rgba(0, 132, 177, 0.08));
      /* border: 0.5px solid var(--main, #0084b1);
      background: var(--hover-1, rgba(0, 132, 177, 0.08));
      padding: 20px; */
    `}
`;
