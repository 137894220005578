import styled from 'styled-components';
import { devices } from '../../../../../constants/device';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 30px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  @media ${devices.xs} {
    width: 375px;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 538px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 2px;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #f4f4f4;

  @media ${devices.xs} {
    width: 100%;
    background: none;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 18px;
  }
`;

export const BtnWrapper = styled.div`
  margin-right: 18px;
  width: 174px;

  @media ${devices.xs} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
  }
`;

export const TreatmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
  margin-left: 6px;
  width: 502px;

  @media ${devices.xs} {
    width: 346px;
  }
`;

export const CheckBoxLabel = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  min-width: 102px;
`;

export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 11px 134px;
  align-items: center;
  margin-top: 11px;
  margin-bottom: 28px;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const Front = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;

  & .true {
    fill: #d8e3e6;
  }

  & .frontHead {
    left: -14px;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    z-index: 1000;
  }

  & .frontNeck {
    cursor: pointer;
    position: absolute;
    top: 26px;
    left: -21px;
    z-index: 100;
  }

  & .frontChest {
    position: absolute;
    top: 41px;
    left: -23px;
    cursor: pointer;
  }

  & .frontRightArm {
    position: absolute;
    top: 41px;
    left: -50px;
    cursor: pointer;
  }

  & .frontLeftArm {
    position: absolute;
    top: 41px;
    left: 18px;
    cursor: pointer;
  }

  & .frontStomach {
    position: absolute;
    top: 98px;
    left: -24px;
    cursor: pointer;
  }

  & .frontHips {
    position: absolute;
    top: 129px;
    left: -25px;
    cursor: pointer;
  }

  & .frontRightLeg {
    position: absolute;
    top: 180px;
    left: -44px;
    cursor: pointer;
  }

  & .frontLeftLeg {
    position: absolute;
    top: 179px;
    left: 13px;
    cursor: pointer;
  }
`;

export const Back = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;

  & .true {
    fill: #d8e3e6;
  }

  & .backHead {
    position: absolute;
    cursor: pointer;
  }

  & .backNeck {
    position: absolute;
    top: 26px;
    left: -7px;
    cursor: pointer;
  }

  & .backChest {
    position: absolute;
    top: 40px;
    left: -9px;
    cursor: pointer;
  }

  & .backLeftArm {
    position: absolute;
    top: 40px;
    left: -37px;
    cursor: pointer;
  }

  & .backRightArm {
    position: absolute;
    top: 40px;
    left: 31px;
    cursor: pointer;
  }

  & .backHips {
    position: absolute;
    top: 117px;
    left: -12px;
    cursor: pointer;
  }

  & .backRightLeg {
    position: absolute;
    top: 184px;
    left: 25px;
    cursor: pointer;
  }

  & .backLeftLeg {
    position: absolute;
    top: 183px;
    left: -15px;
    cursor: pointer;
  }
`;

export const HumanView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 150px;
`;

export const HumanContent = styled.div`
  margin-top: 24px;
  height: auto;
  position: relative;
  height: 300px;
`;

const Side = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FrontSide = styled(Side)`
  font-size: 13px;
  position: absolute;
  left: -17px;
`;

export const BackSide = styled(Side)`
  font-size: 13px;
  position: absolute;
  left: 148px;
`;

export const FrontRight = styled(Side)`
  font-size: 10px;
  position: absolute;
  top: 30px;
  left: -55px;
`;

export const FrontLeft = styled(Side)`
  font-size: 10px;
  position: absolute;
  top: 30px;
  left: 30px;
`;

export const BackLeft = styled(Side)`
  font-size: 10px;
  position: absolute;
  top: 30px;
  left: 115px;
`;

export const BackRight = styled(Side)`
  font-size: 10px;
  position: absolute;
  top: 30px;
  left: 190px;
`;
