import React, { useEffect, useState } from 'react';
import { MenuItem, RadioGroup, SelectChangeEvent } from '@mui/material';
import { TooltipInput, RadioButton, TooltipSelectInput } from 'components';
import { StatesUSA } from 'constants/statesUSA';
import { FormikProps } from 'formik';
import { ProviderInfoFormType } from 'types/AppointmentNewTypes';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useGetAllDoctorsQuery } from 'store/api/staff/staffApi';
import AutoCompleteInput from 'components/atoms/AutoComplete';

import * as S from '../../styles';

type Props = {
  providerFormik: FormikProps<Partial<ProviderInfoFormType>>;
};

export const BillingCard = ({ providerFormik }: Props) => {
  const clinics = useClinicsGetQuery({});
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const getAll = useGetAllDoctorsQuery({
    q: debouncedSearchValue,
    page: 1,
  });

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const onChangeClinic = (clinicName: string) => {
    const currentClinic = clinics.currentData?.rows?.find(i => i.name === clinicName);

    if (currentClinic) {
      providerFormik.setValues({
        ...providerFormik.values,
        billingFacilityName: currentClinic?.name ?? '',
        billingStreet: currentClinic?.street ?? '',
        billingOffice: '',
        billingCity: currentClinic?.city ?? '',
        billingState: currentClinic?.state ?? '',
        billingZip: currentClinic?.zipCode ?? '',
        billingNpi: currentClinic?.npi ?? '',
        billingEin: '',
      });
    }
  };

  const setInitValue = () => {
    setSelectedValue(null);
    void providerFormik.setValues({
      ...providerFormik.values,
      billingFacilityName: '',
      billingStreet: '',
      billingOffice: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
      billingNpi: '',
      billingEin: '',
    });
  };

  useEffect(() => {
    if (selectedValue) {
      void providerFormik.setValues({
        ...providerFormik.values,
        billingFacilityName: selectedValue.user?.profile
          ? selectedValue.user?.profile?.firstName +
            ' ' +
            selectedValue.user?.profile?.lastName
          : '',
        billingNpi:
          providerFormik.values?.billingFacilityName ===
          selectedValue.user?.profile?.firstName +
            ' ' +
            selectedValue.user?.profile?.lastName
            ? providerFormik.values?.billingNpi
            : (selectedValue.user?.credentials?.npi ?? ''),
      });
    }
  }, [selectedValue]);

  return (
    <S.Card>
      <S.EmptyDiv>
        <S.Caption>33. BILLING PROVIDER</S.Caption>
        <RadioGroup
          value={providerFormik.values.isFacilityBilling}
          onChange={e => {
            setInitValue();
            providerFormik.setFieldValue('isFacilityBilling', e.target.value);
          }}
        >
          <S.Label>
            <S.LabelArticle></S.LabelArticle>
          </S.Label>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Facility" value={'isFacilityBilling'} />
              <RadioButton label="Physican" value={'isFhysicanBilling'} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>

        <S.InputWrapper className="all">
          {providerFormik.values.isFacilityBilling === 'isFacilityBilling' ? (
            <>
              <S.EmptyDiv className={'smallGap'}>
                <TooltipSelectInput
                  label="Facility Name"
                  value={providerFormik.values.billingFacilityName}
                  onChange={e => onChangeClinic(e.target.value as string)}
                  error={!!providerFormik.errors.billingFacilityName}
                  tooltipHelperText={providerFormik.errors.billingFacilityName}
                >
                  {clinics.currentData?.rows.map(clinic => (
                    <MenuItem key={clinic.id} value={clinic.name}>
                      <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </TooltipSelectInput>
              </S.EmptyDiv>

              <S.Line>
                <S.InputWrapper className="percent50">
                  <TooltipInput
                    label=""
                    placeholder={'Street'}
                    {...providerFormik.getFieldProps('billingStreet')}
                    error={!!providerFormik.errors.billingStreet}
                    tooltipHelperText={providerFormik.errors.billingStreet}
                  />
                </S.InputWrapper>
                <S.InputWrapper className="percent50">
                  <TooltipInput
                    label=""
                    placeholder={'Office'}
                    {...providerFormik.getFieldProps('billingOffice')}
                    error={!!providerFormik.errors.billingOffice}
                    tooltipHelperText={providerFormik.errors.billingOffice}
                  />
                </S.InputWrapper>
              </S.Line>

              <S.Line>
                <S.InputWrapper className="percent33">
                  <TooltipInput
                    label=""
                    placeholder={'City'}
                    {...providerFormik.getFieldProps('billingCity')}
                    error={!!providerFormik.errors.billingCity}
                    tooltipHelperText={providerFormik.errors.billingCity}
                  />
                </S.InputWrapper>
                <S.InputWrapper className="percent33">
                  <TooltipSelectInput
                    label=""
                    id="state"
                    name="state"
                    placeholder={'State'}
                    value={providerFormik.values.billingState}
                    onChange={(e: SelectChangeEvent<unknown>) =>
                      providerFormik.setFieldValue('billingState', e.target.value)
                    }
                    error={!!providerFormik.errors.billingState}
                    tooltipHelperText={providerFormik.errors.billingState}
                  >
                    {StatesUSA.map(state => (
                      <MenuItem key={state.name} value={state.name}>
                        <S.MenuItemContent>{state.name}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </TooltipSelectInput>
                </S.InputWrapper>
                <S.InputWrapper className="percent33">
                  <TooltipInput
                    label=""
                    placeholder={'Zip'}
                    {...providerFormik.getFieldProps('billingZip')}
                    error={!!providerFormik.errors.billingZip}
                    tooltipHelperText={providerFormik.errors.billingZip}
                  />
                </S.InputWrapper>
              </S.Line>

              <S.Line>
                <S.InputWrapper className="percent50">
                  <TooltipInput
                    label="EIN"
                    {...providerFormik.getFieldProps('billingEin')}
                    error={!!providerFormik.errors.billingEin}
                    tooltipHelperText={providerFormik.errors.billingEin}
                  />
                </S.InputWrapper>
                <S.InputWrapper className="percent50">
                  <TooltipInput
                    label="NPI"
                    {...providerFormik.getFieldProps('billingNpi')}
                    error={!!providerFormik.errors.billingNpi}
                    tooltipHelperText={providerFormik.errors.billingNpi}
                  />
                </S.InputWrapper>
              </S.Line>
            </>
          ) : (
            <>
              <S.InputAutoCompleteWrapper>
                <AutoCompleteInput
                  data={getAll?.currentData?.rows || []}
                  mainLabel={'Name'}
                  loading={!!getAll.isLoading}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  defaultValue={providerFormik.values?.billingFacilityName ?? undefined}
                  error={!!providerFormik.errors.billingFacilityName}
                  helperText={providerFormik.errors.billingFacilityName}
                />
              </S.InputAutoCompleteWrapper>

              <S.Gap />
              <S.InputWrapper className="all">
                <TooltipInput
                  label="NPI"
                  {...providerFormik.getFieldProps('billingNpi')}
                  error={!!providerFormik.errors.billingNpi}
                  tooltipHelperText={providerFormik.errors.billingNpi}
                />
              </S.InputWrapper>
            </>
          )}
        </S.InputWrapper>
      </S.EmptyDiv>
    </S.Card>
  );
};
