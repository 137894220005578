import { useState } from 'react';
import { ArrowToBottomSVG, PlaySVG } from '../../../assets/icons';
import NotificationPopover from '../../organismus/NotificationPopover';
import * as S from './styles';
import { AppointmentsNew } from '../../../types/AppointmentNewTypes';
import UserStatusPin from '../../atoms/UserStatusPin';
import StatusPin from '../../atoms/StatusPin';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { VisitReasons } from '../../../types/StatusTypes';
import { setIsDirty } from '../../../store/slices/isDirtyFormSlice';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';

type Props = {
  appointmentsList?: AppointmentsNew[];
};

const StartedAppointmentButton: React.FC<Props> = ({ appointmentsList }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAnchor = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDirty } = useAppSelector(state => state.isDirty);

  const url = useLocation().pathname;

  const handleClickOnRow = () => {
    if (url.includes('appointment-process')) {
      const id = url.split('/').pop();
      return id;
    } else return;
  };

  return (
    <S.Wrapper
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        handleAnchor(e);
      }}
    >
      <S.Content>
        <PlaySVG color="#fff" />
        <S.Text>Continue Appointment</S.Text>
        <ArrowToBottomSVG />
      </S.Content>
      <NotificationPopover
        article=""
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <S.RowList>
          <S.RowContent>
            {appointmentsList?.map(item => {
              return (
                <S.Row
                  $isCurrent={handleClickOnRow() === item.id ? true : false}
                  key={item.id}
                  onClick={() => {
                    isDirty
                      ? dispatch(
                          setIsDirty({
                            isShowDialog: true,
                            isDirty: true,
                            action: () => navigate(`/appointment-process/${item.id}`),
                          }),
                        )
                      : navigate(`/appointment-process/${item.id}`);
                  }}
                >
                  <S.RowText>Appointment #{item?.id.slice(0, 8)}</S.RowText>
                  <StatusPin type={VisitReasons.ACUPUNCTURE} />
                </S.Row>
              );
            })}
          </S.RowContent>
        </S.RowList>
      </NotificationPopover>
    </S.Wrapper>
  );
};

export default StartedAppointmentButton;
