import { FormikProps } from 'formik';
import { AppointmentFormValues } from './AppointmentDrawer';
import dayjs from 'dayjs';
import { add15min } from '../../../helpers/functions/add15min';
import { AppointmentStatus } from '../../../types/StatusTypes';

export const dataForCRUD = (formik: FormikProps<AppointmentFormValues>, type: string) => {
  const createData = {
    userData: {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      phone: formik.values.phone,
      email: formik.values.email,
    },
    paymentMethod: formik.values.paymentMethod,
    clinicId: formik.values.clinicId,
    // reason: formik.values.visitReason,
    // visitLengthMinutes: Number(formik.values.visitLength),
    repeatCount: Number(formik.values.repeat),
    ...(formik.values.repeat && {
      daysBetweenVisits: formik.values.daysBetween,
    }),
    date: dayjs(formik.values.date).format('YYYY-MM-DD'),

    visits: formik.values.visits.map(visit => ({
      reason: visit.reason,
      startTime: visit.startTime,
      visitLengthMinutes: visit.visitLengthMinutes,
      specialistId: visit.specialistId || null,
    })),
    // startTime: formik.values.time.slice(0, 5),
    // endTime: add15min(formik.values.time),
    note: formik.values.note,
    patientNote: formik.values.patientNote,
    isReevaluation: formik.values.isReevaluation,
    authSigs: formik.values.authSigs,
    ...(formik.values.mainCoveredService && {
      mainVisitReason: formik.values.mainCoveredService,
    }),
    globalNote:
      formik.values.patientNote && formik.values.patientNote.length > 0
        ? `Patient note: ${formik.values.patientNote}\n${formik.values.note}`
        : `${formik.values.note}`,
    ...(formik.values.coupon && { couponNumber: formik.values.coupon }),
    // ...(formik.values.specialist && { specialistId: formik.values.specialist }),
  };

  const updateData = {
    userData: {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      phone: formik.values.phone,
      email: formik.values.email,
    },
    paymentMethod: formik.values.paymentMethod,
    visits: formik.values.visits.map(visit => ({
      ...(visit.id && { id: visit.id }),
      reason: visit.reason,
      startTime: visit.startTime,
      visitLengthMinutes: visit.visitLengthMinutes,
      specialistId: visit.specialistId || null,
    })),
    clinicId: formik.values.clinicId,
    // reason: formik.values.visitReason,
    isReevaluation: formik.values.isReevaluation,
    // visitLengthMinutes: Number(formik.values.visitLength),
    date: dayjs(formik.values.date).format('YYYY-MM-DD'),
    // startTime: formik.values.time.slice(0, 5),
    authSigs: formik.values.authSigs,
    // endTime: add15min(formik.values.time),
    ...(formik.values.mainCoveredService && {
      mainVisitReason: formik.values.mainCoveredService,
    }),
    note: formik.values.note,
    cashSum: Number(formik.values.cashSum),
    cashNote: formik.values.cashNote,
    patientNote: formik.values.patientNote,
    globalNote:
      formik.values.status === AppointmentStatus.PENDING
        ? formik.values.patientNote && formik.values.patientNote.length > 0
          ? `Patient note: ${formik.values.patientNote}; \n${formik.values.note}`
          : `${formik.values.note}`
        : formik.values.globalNote,
    ...(formik.values.coupon &&
      formik.values.discountText && { couponNumber: formik.values.coupon }),
    // ...(formik.values.specialist && { specialistId: formik.values.specialist }),
    status: formik.values.status,
    isPossibleToUpdate: false,
  };

  switch (type) {
    case 'create':
      return createData;
    case 'update':
      return updateData;
    default:
      return;
  }
};
