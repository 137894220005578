import { MenuItem, SelectChangeEvent } from '@mui/material';
import CheckBox from '../../atoms/CheckBox';
import SelectInput from '../../atoms/SelectInput';
import * as S from './styles';
import {
  appointmentStatuses,
  constantTimePicker,
  daysBetweenVisits,
  repeatPicker,
  visitLengthPicker,
  visitReasonsList,
} from '../../../constants/constants';
import DatePicker from '../../atoms/DatePicker';
import Button from '../../atoms/Button';
import { AddRectSVG, ResetApproveBtnSVG } from '../../../assets/icons';
import Input from '../../atoms/Input';
import { FormikProps } from 'formik';
import { VisitReasons } from '../../../types/StatusTypes';
import dayjs, { Dayjs } from 'dayjs';
import { Events, FormValues } from '../NewAppointmentDrawer/NewAppointmentDrawer';
import { ChangeEvent, useEffect, useState } from 'react';
import { add15min } from '../../../helpers/functions/add15min';
import { addXmin } from '../../../helpers/functions/addXMin';
import { Appointments, EventType } from '../../../types/AppointmentsTypes';
import { ucFirst } from '../../../helpers/functions/toUpperCase';
import { DateObject } from 'react-multi-date-picker';
import {
  useGetClinicWeekendsMutation,
  useGetClinicWorkingTimeMutation,
} from '../../../store/api/clinic-schedule/clinicScheduleApi';
import { convertAMtoPM } from '../../../helpers/functions/convertAMtoPM';
import ReactInputMask from 'react-input-mask';
import { useCouponCheckMutation } from '../../../store/api/coupons/couponsApi';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { UserProfile } from '../../../types/UserProfileTypes';

type Props = {
  index: number | string;
  isNotDelete?: boolean;
  onDeleteCounter?: () => void;
  formik: FormikProps<FormValues>;
  selectedEvent?: EventType;
  expanded?: string | false;
  onlyOneView?: boolean;
  visitNumber?: number;
  clinicId?: string;
  userProfile?: UserProfile;
  // selectedAppointment?: Appointments;
};

const VisitReason: React.FC<Props> = ({
  index,
  onDeleteCounter,
  formik,
  isNotDelete,
  selectedEvent,
  expanded,
  onlyOneView,
  visitNumber,
  clinicId,
  userProfile,
  // selectedAppointment,
}) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    formik.setFieldError(e.target.name, '');
    formik.handleChange(e);
    // console.log(formik.values[`reason_${index}`]);
  };

  useEffect(() => {
    formik.values[`startTime_${index}`] &&
      formik.values[`visitLengthMinutes_${index}`] &&
      formik.setFieldValue(
        `endTime_${index}`,
        addXmin(
          formik.values[`startTime_${index}`],
          Number(formik.values[`visitLengthMinutes_${index}`]),
        ),
      );
  }, [formik.values[`startTime_${index}`], formik.values[`visitLengthMinutes_${index}`]]);

  // useEffect(() => {
  //   if (selectedEvent) {
  //     formik.setValues({
  //       ...formik.values,

  //       // [`startTime_${index}`] :
  //     });
  //   }
  //   console.log("selectedEvent ", selectedEvent);
  // }, [selectedEvent]);

  const [currentMonth, setCurrentMonth] = useState<DateObject>(new DateObject());

  const [getWeekends, getWeekendsStatus] = useGetClinicWeekendsMutation({});
  const [getTime, getTimeStatus] = useGetClinicWorkingTimeMutation({});
  const [checkCoupon, checkCouponStatus] = useCouponCheckMutation({});

  const [arrWeekends, setArrWeekends] = useState<string[] | null>(null);
  const [arrTime, setArrTime] = useState<string[] | null>(null);

  const [discountText, setDiscountText] = useState<string>('');
  const [couponText] = useDebounce(formik.values[`couponNumber_${index}`], 500);

  useEffect(() => {
    setCurrentMonth(new DateObject());
    if (clinicId && !selectedEvent) {
      formik.setValues({
        ...formik.values,
        date: '',
      });
    }
  }, [clinicId]);

  useEffect(() => {
    if (currentMonth && clinicId) {
      getWeekends({
        clinicId: clinicId,
        date: currentMonth.format('YYYY-MM-01'),
      })
        .unwrap()
        .then((res: Array<string>) => setArrWeekends(res));
    }
  }, [currentMonth, clinicId]);

  useEffect(() => {
    if (!dayjs(formik.values[`date_${index}`] as Dayjs).isValid()) {
      setCurrentMonth(new DateObject());
    } else {
      getTime({
        clinicId: clinicId,
        date: dayjs(formik.values[`date_${index}`]).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          setArrTime(res.time);
        });
    }
  }, [formik.values[`date_${index}`]]);

  useEffect(() => {
    if (selectedEvent) {
    } else if (
      userProfile &&
      couponText &&
      couponText.length === 8 &&
      !(couponText as string).includes('_')
    ) {
      checkCoupon({
        patientId: userProfile.id,
        value: couponText,
        clinicId: clinicId,
      })
        .unwrap()
        .then(res => setDiscountText('asd'))
        .catch(err => {
          // console.log(err);
          setDiscountText(err.data.message);
        });
    } else {
      setDiscountText('');
    }
  }, [couponText]);

  return (
    <S.VisitReasonWrapper>
      <S.ArticleWrap>
        <S.SubArticle>
          Visit Reason #{''}
          {Number(typeof visitNumber === 'undefined' ? index : visitNumber)}
        </S.SubArticle>

        <S.ResetButtonWrapper
          onClick={() => {
            // formik.setValues({
            //   ...formik.values,
            //   [`visitReason_${index}`]: "",
            // });
            onDeleteCounter && onDeleteCounter();
          }}
        >
          <ResetApproveBtnSVG />
        </S.ResetButtonWrapper>
      </S.ArticleWrap>

      <S.CheckboxWrapper>
        <S.LabelCheckbox>Insurance coverage</S.LabelCheckbox>
        <CheckBox
          id={`isInsuranceCoverage_${index}`}
          name={`isInsuranceCoverage_${index}`}
          checked={Boolean(formik.values[`isInsuranceCoverage_${index}`])}
          onChange={() => {
            formik.setFieldValue(
              `isInsuranceCoverage_${index}`,
              !Boolean(formik.values[`isInsuranceCoverage_${index}`]),
            );
          }}
        />
      </S.CheckboxWrapper>

      {selectedEvent && (
        <S.InputRow>
          <SelectInput
            label="Change status"
            isRequired
            id={`status_${index}`}
            name={`status_${index}`}
            error={!!formik.errors[`status_${index}`]}
            helperText={formik.errors[`status_${index}`]}
            value={formik.values[`status_${index}`] ?? ''}
            onChange={handleChangeSelect}
          >
            {appointmentStatuses.map(status => (
              <MenuItem key={status} value={status}>
                <S.MenuItemContent>{ucFirst(status)}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.InputRow>
      )}
      <S.InputRow>
        <SelectInput
          label="Visit reason"
          isRequired
          id={`reason_${index}`}
          name={`reason_${index}`}
          error={!!formik.errors[`reason_${index}`]}
          helperText={formik.errors[`reason_${index}`]}
          value={formik.values[`reason_${index}`] ?? ''}
          onChange={handleChangeSelect}
          disabled={onlyOneView ? true : false}
        >
          {visitReasonsList.map(reason => (
            <MenuItem key={reason.name} value={reason.value}>
              <S.MenuItemContent>{reason.name}</S.MenuItemContent>
            </MenuItem>
          ))}
        </SelectInput>
        <SelectInput
          label="Visit length"
          isRequired
          id={`visitLengthMinutes_${index}`}
          name={`visitLengthMinutes_${index}`}
          error={!!formik.errors[`visitLengthMinutes_${index}`]}
          helperText={formik.errors[`visitLengthMinutes_${index}`]}
          value={formik.values[`visitLengthMinutes_${index}`] ?? ''}
          onChange={handleChangeSelect}
        >
          {visitLengthPicker.map(time => (
            <MenuItem key={time.name} value={time.value}>
              <S.MenuItemContent>{time.name}</S.MenuItemContent>
            </MenuItem>
          ))}
        </SelectInput>
      </S.InputRow>
      <S.InputRow>
        <SelectInput
          label="Specialist name"
          id={`specialistName_${index}`}
          name={`specialistName_${index}`}
          error={!!formik.errors[`specialistName_${index}`]}
          helperText={formik.errors[`specialistName_${index}`]}
          value={formik.values[`specialistName_${index}`] ?? ''}
          onChange={(e: SelectChangeEvent<unknown>) => {
            formik.setFieldError(`specialistName_${index}`, '');
            formik.handleChange(e);
          }}
        >
          {/* {visitLengthPicker.map((time) => (
            <MenuItem key={time.name} value={time.value}>
              <S.MenuItemContent>{time.name}</S.MenuItemContent>
            </MenuItem>
          ))} */}
        </SelectInput>
      </S.InputRow>
      {!onlyOneView && (
        <S.InputRow>
          <SelectInput
            label="Repeat"
            isRequired
            id={`repeatCount_${index}`}
            name={`repeatCount_${index}`}
            error={!!formik.errors[`repeatCount_${index}`]}
            helperText={formik.errors[`repeatCount_${index}`]}
            value={formik.values[`repeatCount_${index}`] ?? ''}
            onChange={(e: SelectChangeEvent<unknown>) => {
              formik.setFieldError(`repeatCount_${index}`, '');
              formik.handleChange(e);
            }}
          >
            {repeatPicker.map(count => (
              <MenuItem key={count.name} value={count.value}>
                <S.MenuItemContent>{count.name}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            label="Days Between"
            isRequired
            id={`daysBetweenVisits_${index}`}
            name={`daysBetweenVisits_${index}`}
            error={!!formik.errors[`daysBetweenVisits_${index}`]}
            helperText={formik.errors[`daysBetweenVisits_${index}`]}
            value={formik.values[`daysBetweenVisits_${index}`] ?? ''}
            onChange={(e: SelectChangeEvent<unknown>) => {
              formik.setFieldError(`daysBetweenVisits_${index}`, '');
              formik.handleChange(e);
            }}
          >
            {daysBetweenVisits.map(day => (
              <MenuItem key={day.name} value={day.value}>
                <S.MenuItemContent>{day.name}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.InputRow>
      )}
      <S.InputRow>
        <S.SelectInputWrapper>
          <DatePicker
            label="Preferred Date"
            isRequired
            disabled={getWeekendsStatus.isLoading || !clinicId ? true : false}
            daysWeekends={arrWeekends}
            disablePast={onlyOneView ? false : true}
            value={
              formik.values[`date_${index}`] ? dayjs(formik.values[`date_${index}`]) : ''
            }
            onOpen={() => {
              !dayjs(formik.values[`date_${index}`] as Dayjs).isValid()
                ? setCurrentMonth(new DateObject())
                : setCurrentMonth(
                    new DateObject(
                      dayjs(formik.values[`date_${index}`] as Dayjs).format('YYYY-MM-DD'),
                    ),
                  );
            }}
            onMonthChange={date => setCurrentMonth(date)}
            onChange={value => {
              formik.setFieldError(`date_${index}`, '');
              formik.setFieldValue(`date_${index}`, value);
            }}
            id={`date_${index}`}
            name={`date_${index}`}
            error={
              !!formik.errors[`date_${index}`] ||
              !!(
                formik.values[`date_${index}`] &&
                !dayjs(formik.values[`date_${index}`] as Dayjs).isValid()
              )
            }
            helperText={
              formik.errors[`date_${index}`] ||
              (formik.values[`date_${index}`] !== '' &&
                !dayjs(formik.values[`date_${index}`] as Dayjs).isValid())
                ? 'Not valid format'
                : ''
            }
          />
        </S.SelectInputWrapper>
        <S.SelectInputWrapper>
          <SelectInput
            label="Preferred Time"
            isRequired
            disabled={!formik.values[`date_${index}`] || !clinicId ? true : false}
            id={`startTime_${index}`}
            name={`startTime_${index}`}
            error={!!formik.errors[`startTime_${index}`]}
            helperText={formik.errors[`startTime_${index}`]}
            value={
              formik.values[`startTime_${index}`] ??
              selectedEvent?.startTime?.slice(0, 5) ??
              ''
            }
            onChange={handleChangeSelect}
          >
            {arrTime?.length
              ? arrTime.map(time => (
                  <MenuItem key={time} value={time}>
                    <S.MenuItemContent>{convertAMtoPM(time)}</S.MenuItemContent>
                  </MenuItem>
                ))
              : constantTimePicker.map(time => (
                  <MenuItem key={time.value} value={time.value}>
                    <S.MenuItemContent>{time.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
          </SelectInput>
          {!formik.values[`date_${index}`] && clinicId && (
            <S.HelperText>To select a time, fill in the preffered day</S.HelperText>
          )}
        </S.SelectInputWrapper>
        <input
          hidden
          id={`endTime_${index}`}
          name={`endTime_${index}`}
          value={formik.values[`endTime_${index}`] ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // formik.setFieldError(`endTime_${index}`, "");
            // formik.setFieldValue(`endTime_${index}`);
            // console.log(
            //   dayjs(formik.values[`startTime_${index}`])
            //     .add(15, "minutes")
            //     .toString()
            // );
            // formik.handleChange(e);
          }}
        ></input>
      </S.InputRow>
      <S.InputRowSpace>
        <S.SubArticle>Coupon number</S.SubArticle>
        <S.InputWrapper>
          <ReactInputMask
            mask="********"
            value={formik.values[`couponNumber_${index}`]?.toUpperCase() ?? ''}
            onChange={handleChangeInput}
          >
            <Input
              label=""
              id={`couponNumber_${index}`}
              name={`couponNumber_${index}`}
              error={!!formik.errors[`couponNumber_${index}`]}
              helperText={formik.errors[`couponNumber_${index}`] as string}
            />
          </ReactInputMask>
          <S.HelperText>{discountText}</S.HelperText>
        </S.InputWrapper>
      </S.InputRowSpace>
      <S.InputRow>
        <Input
          multiline={true}
          rows={4}
          label="Note"
          id={`note_${index}`}
          name={`note_${index}`}
          error={!!formik.errors[`note_${index}`]}
          helperText={formik.errors[`note_${index}`] as string}
          value={formik.values[`note_${index}`] ?? ''}
          onChange={handleChangeInput}
        />
      </S.InputRow>
      {selectedEvent && (
        <S.InputRowSpace>
          <S.SubArticle>Cash sum</S.SubArticle>
          <S.InputWrapper>
            <Input
              label=""
              id={`cashSum_${index}`}
              name={`cashSum_${index}`}
              error={!!formik.errors[`cashSum_${index}`]}
              helperText={formik.errors[`cashSum_${index}`] as string}
              value={formik.values[`cashSum_${index}`] ?? ''}
              onChange={handleChangeInput}
            />
          </S.InputWrapper>
        </S.InputRowSpace>
      )}
      <S.InputRowSpace>
        {/* <S.SubArticle>Notice</S.SubArticle>
        <CheckBox /> */}
      </S.InputRowSpace>
    </S.VisitReasonWrapper>
  );
};

export default VisitReason;
