import * as S from './styles';

const Agenda = () => {
  return (
    <S.Wrapper>
      <S.Item>
        <S.ColorBox color="#EBF3DF" border="#79A030" />
        <S.Text>Massage</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#FFDBB0" border="#D17E00" />
        <S.Text>Massage (FT)</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#EADAFF" border="#9747FF" />
        <S.Text>PT</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#D0EBEF" border="#429AA7" />
        <S.Text>Herbs</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#fff" border="#848A9B" />
        <S.Text>ACU</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#FFDBB0" border="#D17E00" />
        <S.Text>ACU (FT)</S.Text>
      </S.Item>
      <S.Item>
        <S.ColorBox color="#FFB7E6" border="#DF27A1" />
        <S.Text>Other</S.Text>
      </S.Item>
    </S.Wrapper>
  );
};
export default Agenda;
