import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Input, StatusPin } from '../../../../../components';
import * as S from './styles';
import {
  useHealthrecordAddMutation,
  useHealthrecordUpdateMutation,
} from '../../../../../store/api/healthrecords/healthrecordApi';
import { EventView } from '../../../../../types/AppointmentsTypes';
import { ClockPinSVG } from '../../../../../assets/icons';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../store/slices/message';

type Props = {
  event?: EventView;
  onClose: () => void;
  isEditable: boolean;
};

const HealthAddDialog: React.FC<Props> = ({ event, onClose, isEditable }) => {
  const [note, setNote] = useState('');
  const [createHealthRecord] = useHealthrecordAddMutation();
  const [updateHealthRecord] = useHealthrecordUpdateMutation();
  const dispatch = useAppDispatch();
  const handleCreate = () => {
    createHealthRecord({
      clinicId: event?.resource.selectedEvent?.clinicId,
      patientId: event?.resource.selectedEvent?.patient?.id,
      note: note,
      eventId: event?.resource.selectedEvent.id,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
    onClose();
  };

  const handleUpdate = () => {
    updateHealthRecord({
      clinicId: event?.resource.selectedEvent?.clinicId,
      patientId: event?.resource.selectedEvent?.patient?.id,
      note: note,
      eventId: event?.resource.selectedEvent.id,
      id: event?.resource?.selectedEvent?.healthRecords[0]?.id,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
    onClose();
  };

  const disableButton = useMemo(() => !note, [note]);

  return (
    <S.Content>
      <S.Article>{isEditable ? 'Edit Health Record' : 'Add Health Record'}</S.Article>
      {event && (
        <S.EventInformation>
          <StatusPin
            type={event.resource.type}
            isFirstTimeVisit={event.resource.isFirstTimeVisit}
          />
          <S.TimeDiv>
            <ClockPinSVG />
            {dayjs(event.start).format('MM/DD/YYYY')} {dayjs(event.start).format('h:mm')}-
            {dayjs(event.end).format('h:mm')}
          </S.TimeDiv>
        </S.EventInformation>
      )}
      <S.InputWrapper>
        <Input
          multiline
          rows={8}
          label="Health record"
          value={note}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
        />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button
          text={isEditable ? 'Update Health Record' : 'Create Health Record'}
          onClick={isEditable ? handleUpdate : handleCreate}
          disabled={disableButton}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default HealthAddDialog;
