import { FormikProps } from 'formik';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { DatePicker, Input, RadioButton } from '../../../../../../../../components';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FirstName from '../../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/FirstName';
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector';
import LastName from '../../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/LastName';
import { ClearFiltersSVG } from '../../../../../../../../assets/icons';
import DOB from '../../../../../../../../components/organismus/AppointmentDrawer/components/PatientInformation/DOB';
import { getAge } from '../../../../../../../../helpers/functions/getAge';
import { ucFirst } from '../../../../../../../../helpers/functions/toUpperCase';
import { AppointmentsNew, Step } from '../../../../../../../../types/AppointmentNewTypes';
import * as S from '../../styles';
import { PageACAInsuranceType } from '../../../pages/PageACAInsurance/PageACAInsuranceType';

type Props = {
  formik: FormikProps<PageACAInsuranceType>;
  stepperValues?: Step;
  appointmentInformation?: AppointmentsNew;
  setIsDirty?: () => void;
};
const ACAInsuranceUserBlock = forwardRef<Record<string, unknown>, Props>(
  ({ formik, stepperValues, appointmentInformation, setIsDirty }, ref) => {
    const [selectedValue, setSelectedValue] = useState<any>('');
    const { id } = useAppSelector(state => state.selectedClinic);

    useEffect(() => {
      if (appointmentInformation) {
        setSelectedValue(appointmentInformation.patient);
      }
    }, [appointmentInformation]);

    useEffect(() => {
      if (selectedValue) {
        formik.setErrors({
          patientName: '',
        });
        formik.setValues({
          ...formik.values,
          userId: selectedValue?.id,
          age: getAge(selectedValue.profile?.dateOfBirth),
          gender: selectedValue.profile?.sex,
          birthDate: dayjs(selectedValue.profile?.dateOfBirth).format('MM/DD/YYYY'),
          patientPhone: selectedValue?.profile?.cellPhone,
          patientName:
            selectedValue.profile?.firstName + ' ' + selectedValue.profile?.lastName,
        });
      } else
        formik.setValues({
          ...formik.values,
          userId: '',
          age: 0,
          gender: '',
          patientPhone: '',
          patientName: '',
        });
    }, [selectedValue]);

    return (
      <>
        {selectedValue && !appointmentInformation && (
          <S.ResetButton onClick={() => setSelectedValue('')}>
            <ClearFiltersSVG />
            Change user
          </S.ResetButton>
        )}

        {formik.errors.patientName && (
          <S.Line>
            <S.ErrorText>{'USER INFORMATION IS REQUIRED!'}</S.ErrorText>
          </S.Line>
        )}

        <S.Line>
          <S.InputWrapper className="px240">
            <FirstName
              error={formik.errors.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <LastName
              error={formik.errors.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <DOB
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          {selectedValue && (
            <>
              <S.BoldText>
                Age: <S.Span>{formik.values.age}</S.Span>
              </S.BoldText>
              <S.BoldText>
                Sex: <S.Span>{ucFirst(formik.values.gender)}</S.Span>
              </S.BoldText>
              <S.BoldText>
                Phone: <S.Span>{ucFirst(formik.values.patientPhone)}</S.Span>
              </S.BoldText>
            </>
          )}
        </S.Line>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="Insurance Co"
              defaultValue={formik.values.insuranceCo}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'insuranceCo', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="Plan/Group #"
              defaultValue={formik.values?.planGroup}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(
                  e,
                  'planGroup',
                  ref,

                  setIsDirty,
                )
              }
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <Input
              label="Member I.D.#"
              defaultValue={formik.values?.memberId}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(
                  e,
                  'memberId',
                  ref,

                  setIsDirty,
                )
              }
            />
          </S.InputWrapper>
        </S.Line>
      </>
    );
  },
);

export default ACAInsuranceUserBlock;
