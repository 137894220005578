import { useState } from 'react';
import { AddPatientsSVG, ArrowLSVG, ArrowRSVG } from 'assets/icons';
import { Button, CustomTable, Dialog, SearchInput } from 'components';
import * as S from '../../styles';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import StaffLocalRow from './TableData/StaffLocalRow';
import { Roles } from 'types/Roles';
import { useParams } from 'react-router-dom';
import AssignClinicDialog from 'pages/SuperAdmin/components/ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { useAppDispatch } from 'hooks/useAppDispatch';

const headers = [
  { name: 'Doctor name', key: 'DoctorName' },
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Status', key: 'Status' },
];

type Props = {
  fromClinic?: boolean;
};
const StaffLocal = ({ fromClinic = true }: Props) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);

  const getList = useStaffGetQuery({
    position: Roles.LOCATION_MANAGER,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    q: debouncedSearchValue,
  });

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const handleShowCount = () => {
    if (getList?.currentData?.count && getList.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getList?.currentData?.count && getList.currentData.count < secondCount
            ? getList.currentData.count
            : secondCount}{' '}
          of {getList?.currentData?.count} local admins{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getList?.currentData?.count} local admins{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.LOCATION_MANAGER}
          clinicId={params.clinicId}
        />
      </Dialog>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getList.currentData?.page === getList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            {fromClinic ? (
              <Button
                text={'Assign Local Admin'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            ) : (
              <Button
                text="Add Local Admin"
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_LOCAL,
                      props: null,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            )}
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <StaffLocalRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffLocal;
