import { createSlice } from '@reduxjs/toolkit';
import { Clinics } from '../../types/ClinicTypes';

type SelectedClinics = {
  id: string;
};

const initialState: SelectedClinics = {
  id: '',
};

const selectedClinicsSlice = createSlice({
  name: 'selectedClinic',
  initialState,
  reducers: {
    setSelectedClinic: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
      };
    },
  },
});

const { reducer, actions } = selectedClinicsSlice;

export const { setSelectedClinic } = actions;
export default reducer;
