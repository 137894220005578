import { useParams } from 'react-router-dom';
import {
  ErrorHistoricalMessageSVG,
  ErrorMessageSVG,
} from '../../../../../../../../assets/icons';
import {
  useLazyGetClaimErrorsHistoryQuery,
  useLazyGetClaimErrorsMessagesHistoryQuery,
} from '../../../../../../../../store/api/claims/claimsApi';
import * as S from '../../styles';
import ClaimMessageErrorCard from '../ClaimMessageErrorCard';
import { useEffect, useState } from 'react';
import { CircularLoader } from '../../../../../../../../components';

type Props = {
  claimId: string;
};

const ClaimHistoryMessages = ({ claimId }: Props) => {
  const params = useParams();

  const [getAllErrorHistory, statusErrorHistory] = useLazyGetClaimErrorsHistoryQuery({});

  const [getAllErrorMessages, statusMessages] = useLazyGetClaimErrorsMessagesHistoryQuery(
    {},
  );

  const [page, setPage] = useState(1);

  const [pageHistoricalErrors, setPageHistoricalErrors] = useState(1);

  useEffect(() => {
    getAllErrorMessages({
      page: page,
      take: 30,
      claimId: claimId,
      type: 'Payer Messages',
    });
    getAllErrorHistory({
      page: page,
      take: 30,
      claimId: claimId,
      type: 'History Errors',
    });
  }, [claimId, page]);

  return (
    <>
      {statusMessages.isFetching || statusMessages.isLoading ? (
        <S.Loader>
          <CircularLoader color="#0084B1" size={20} />
        </S.Loader>
      ) : (
        <>
          <S.TopLine />
          <S.Line>
            <ErrorMessageSVG />
            <S.Title>Payer Messages ({statusMessages.data?.count || '0'})</S.Title>
          </S.Line>
          {/* <S.ButtonWrapper>#F4DFDF
        <S.ButtonText>History Errors</S.ButtonText>
      </S.ButtonWrapper> */}
          {statusMessages.data &&
            statusMessages.data.rows.map(item => {
              return (
                <ClaimMessageErrorCard
                  key={item.id}
                  color="#f2bfbf"
                  title={item.field}
                  description={item.description}
                />
              );
            })}

          <S.TopLine />
          <S.Line>
            <ErrorHistoricalMessageSVG />
            <S.Title>Historical Errors ({statusErrorHistory.data?.count || '0'})</S.Title>
          </S.Line>
          {statusErrorHistory.data &&
            statusErrorHistory.data.rows.map(item => {
              return (
                <ClaimMessageErrorCard
                  color="#faceb9"
                  key={item.id}
                  title={item.field}
                  description={item.description}
                />
              );
            })}
        </>
      )}
    </>
  );
};

export default ClaimHistoryMessages;
