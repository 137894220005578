import { useState } from 'react';
import { Button, CustomTable, Dialog, SearchInput } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { TabsEnum } from 'types/enums/TabsEnum';
import * as S from '../../styles';
import StaffRecepRow from './TableData/StaffRecepRow';
import { AddPatientsSVG, ArrowLSVG, ArrowRSVG } from 'assets/icons';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { Roles } from 'types/Roles';
import AssignClinicDialog from 'pages/SuperAdmin/components/ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';

const headers = [
  { name: 'Receptionist name', key: 'ReceptionistName' },
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Status', key: 'Status' },
];

type Props = {
  fromClinic?: boolean;
};

const StaffReceptionists = ({ fromClinic }: Props) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);

  const params = useParams();

  const getReceptionistsList = useStaffGetQuery({
    position: TabsEnum.RECEPTIONIST,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    q: debouncedSearchValue,
  });

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const handleShowCount = () => {
    if (
      getReceptionistsList?.currentData?.count &&
      getReceptionistsList.currentData.count > 0
    ) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getReceptionistsList?.currentData?.count &&
          getReceptionistsList.currentData.count < secondCount
            ? getReceptionistsList.currentData.count
            : secondCount}{' '}
          of {getReceptionistsList?.currentData?.count} receptionists{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getReceptionistsList?.currentData?.count} receptionists{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.RECEPTIONIST}
          clinicId={params.clinicId}
        />
      </Dialog>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getReceptionistsList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getReceptionistsList.currentData?.page ===
                getReceptionistsList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getReceptionistsList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            {fromClinic ? (
              <Button
                text={'Assign Receptionist'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            ) : (
              <Button
                text="Add Receptionists"
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_RECEPTIONIST,
                      props: Roles.RECEPTIONIST,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            )}
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getReceptionistsList.isFetching}>
        <StaffRecepRow
          clickable
          data={getReceptionistsList?.currentData?.rows}
          headers={headers}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffReceptionists;
