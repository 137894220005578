import { useFormik } from 'formik';
import * as yup from 'yup';
import VisitReason from '../VisitReason';
import * as S from './styles';
import { Event } from 'react-big-calendar';
import { FormValues } from '../NewAppointmentDrawer/NewAppointmentDrawer';
import Button from '../../atoms/Button';
import UserStatusPin from '../../atoms/UserStatusPin';
import { EventView } from '../../../types/AppointmentsTypes';
import {
  useEventsGetOneQuery,
  useEventsUpdateMutation,
} from '../../../store/api/events/eventsApi';
import { useDispatch } from 'react-redux';
import { showDrawer } from '../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../types/DrawerMode';
import { setMessage } from '../../../store/slices/message';
import LinearLoader from '../../atoms/LinearLoader';

type Props = {
  event: string;
};

const ViewEventDrawer: React.FC<Props> = ({ event }) => {
  const thisEvent = useEventsGetOneQuery({ id: event });
  // console.log(thisEvent);

  const validationSchema = yup.object().shape({});

  const [updateEvent] = useEventsUpdateMutation({});

  const dispatch = useDispatch();

  const formik = useFormik<FormValues>({
    initialValues: {},
    validateOnChange: false,
    validationSchema,
    onSubmit: async () => {
      const data = {
        ...(values.note_0 && { note: values.note_0 }),
        ...(values.cashSum_0 && { cashSum: Number(values.cashSum_0) }),
        ...(values.couponNumber_0 && { couponNumber: values.couponNumber_0 }),
        ...(values.endTime_0 && { endTime: values.endTime_0 }),
        ...(values.startTime_0 && { startTime: values.startTime_0 }),
        ...(values.status_0 && { status: values.status_0 }),
        ...(values.date_0 && { date: values.date_0 }),
        id: values.id,
      };
      await updateEvent(data)
        .unwrap()
        .then(res => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(
            setMessage({
              message: 'Event was successfullt updated',
              type: 'success',
            }),
          );
          resetForm();
        })
        .catch(error => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.AppointmentWrapper>
        <S.Content>
          {thisEvent.isFetching || thisEvent.isLoading ? (
            <LinearLoader />
          ) : (
            <>
              <S.ArticleWrap>
                <S.Article>
                  Visit reason #{thisEvent.currentData?.id?.slice(0, 8)}
                </S.Article>
                <UserStatusPin type={thisEvent.currentData?.status} />
              </S.ArticleWrap>
              <S.PaymentInformation>
                <VisitReason
                  formik={formik}
                  index={0}
                  selectedEvent={thisEvent.currentData}
                  onlyOneView={true}
                />
              </S.PaymentInformation>
            </>
          )}
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Update" type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.AppointmentWrapper>
    </form>
  );
};

export default ViewEventDrawer;
