import { MenuItem, SelectChangeEvent } from '@mui/material';
import SelectInput from '../../../../atoms/SelectInput';
import * as S from './styles';
import { useClinicsGetQuery } from '../../../../../store/api/clinics/clinicApi';
import { HorizontalLineSVG } from '../../../../../assets/icons';
import { constantTimePickerOUT } from '../../../../../constants/constants';
import Button from '../../../../atoms/Button';
import { FormikProps } from 'formik';
import {
  StaffSchedule,
  useStaffScheduleCreateMutation,
} from '../../../../../store/api/staff-schedule/staffScheduleApi';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../../store/slices/message';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTreatmentUpdateMutation } from '../../../../../store/api/treatment/treatmentApi';
import { compareTime } from '../../../../../helpers/functions/compareDates';
import { ScheduleValuesProps } from '../../WorkSchedule';
import { compareId } from '../../../../../helpers/functions/compareId';
import { Clinics } from '../../../../../types/ClinicTypes';

type Props = {
  formik: FormikProps<ScheduleValuesProps>;
  choosedWeekDay: number;
  onClose: () => void;
  todayList?: StaffSchedule[];
  staffId?: string;
  assignClinics?: Array<Clinics>;
};

const NewSchedDialog: React.FC<Props> = ({
  formik,
  choosedWeekDay,
  onClose,
  todayList,
  staffId,
  assignClinics,
}) => {
  const clinic = useClinicsGetQuery({});
  const dispatch = useDispatch();

  //console.log(todayList, "todayList");

  const userInfo = useAppSelector(state => state.auth);

  const [createSched, status] = useStaffScheduleCreateMutation({});

  const disableButton = useMemo(
    () =>
      !formik.values.workingHoursEnd ||
      !formik.values.workingHoursStart ||
      !formik.values.clinicId ||
      !compareTime(formik.values.workingHoursStart, formik.values.workingHoursEnd),
    [
      formik.values.clinicId,
      formik.values.workingHoursStart,
      formik.values.workingHoursEnd,
    ],
  );

  const handleCreate = () => {
    const data = {
      ...formik.values,
      isWorkDay: todayList && todayList?.length > 0 ? todayList[0].isWorkDay : false,
      doctorId: staffId ? staffId : userInfo?.id,
      weekDay: choosedWeekDay,
    };
    onClose();
    createSched(data)
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Your schedule has been added successfully',
            type: 'success',
          }),
        );
        formik.resetForm();
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
        formik.resetForm();
      });
  };

  return (
    <S.Content>
      <S.Article>Add schedule</S.Article>
      {/* <S.HelperText></S.HelperText> */}
      <S.SelectInputWrapper>
        <SelectInput
          label="Clinic name"
          isRequired
          id="clinicId"
          name="clinicId"
          value={formik.values.clinicId}
          onChange={(e: SelectChangeEvent<unknown>) => {
            formik.setFieldError('clinicId', '');
            formik.handleChange(e);
          }}
          error={!!formik.errors.clinicId}
          helperText={formik.errors.clinicId}
        >
          {assignClinics
            ? assignClinics?.map(item => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    disabled={todayList ? !!compareId(item.id, todayList as any) : false}
                  >
                    <S.MenuItemContent>{item.name}</S.MenuItemContent>
                  </MenuItem>
                );
              })
            : userInfo?.clinics?.map(item => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    disabled={todayList ? !!compareId(item.id, todayList as any) : false}
                  >
                    <S.MenuItemContent>{item.name}</S.MenuItemContent>
                  </MenuItem>
                );
              })}
        </SelectInput>
      </S.SelectInputWrapper>
      <S.WorkTime>
        <SelectInput
          label="Start"
          isRequired
          id="workingHoursStart"
          name="workingHoursStart"
          error={!!formik.errors.workingHoursStart}
          helperText={formik.errors.workingHoursStart}
          value={formik.values.workingHoursStart}
          onChange={(e: SelectChangeEvent<unknown>) => {
            formik.setFieldError('workingHoursStart', '');
            formik.handleChange(e);
          }}
        >
          {constantTimePickerOUT.map(time => (
            <MenuItem
              key={time.name}
              value={time.value}
              disabled={compareTime(formik.values.workingHoursEnd, time.value)}
            >
              <S.MenuItemContent>{time.name}</S.MenuItemContent>
            </MenuItem>
          ))}
        </SelectInput>
        <HorizontalLineSVG className="line" />
        <SelectInput
          label="End"
          isRequired
          id="workingHoursEnd"
          name="workingHoursEnd"
          error={!!formik.errors.workingHoursEnd}
          helperText={formik.errors.workingHoursEnd}
          value={formik.values.workingHoursEnd}
          onChange={(e: SelectChangeEvent<unknown>) => {
            formik.setFieldError('workingHoursEnd', '');
            formik.handleChange(e);
          }}
        >
          {constantTimePickerOUT.map(time => (
            <MenuItem
              key={time.name}
              value={time.value}
              disabled={compareTime(time.value, formik.values.workingHoursStart)}
            >
              <S.MenuItemContent>{time.name}</S.MenuItemContent>
            </MenuItem>
          ))}
        </SelectInput>
      </S.WorkTime>
      <S.ButtonWrapper>
        <Button text="Create" onClick={handleCreate} disabled={disableButton} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default NewSchedDialog;
