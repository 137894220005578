import { useState } from 'react';
import { Dialog } from '../../..';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
} from '../../../../store/api/appointment/appointmentApi';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import { setMessage } from '../../../../store/slices/message';
import { AppointmentsNew, Visits } from '../../../../types/AppointmentNewTypes';
import { DrawerMode } from '../../../../types/DrawerMode';
import { Roles } from '../../../../types/Roles';
import { AppointmentStatus } from '../../../../types/StatusTypes';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog';
import * as S from './styles';

type Props = {
  appointment: AppointmentsNew;
  visit: Visits;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const ClickEventMenu: React.FC<Props> = ({ appointment, visit, setOpenDialog }) => {
  const dispatch = useAppDispatch();

  const me = useAppSelector(state => state.auth);

  return (
    <S.ContentWrapper>
      <S.ContentRow
        onClick={() =>
          dispatch(
            showDrawer({
              show: true,
              mode: DrawerMode.DUPL_APPOINTMENT,
              props: appointment,
            }),
          )
        }
      >
        Duplicate
      </S.ContentRow>
      {appointment.status === AppointmentStatus.PENDING && (
        <S.ContentRow
          onClick={e => {
            e.stopPropagation();
            setOpenDialog(true);
          }}
        >
          Delete
        </S.ContentRow>
      )}
      {/* {me.role === Roles.DOCTOR && (
        <S.ContentRow>Show health record</S.ContentRow>
      )} */}
    </S.ContentWrapper>
  );
};

export default ClickEventMenu;
