import { useState } from 'react';
import { Dialog } from '../../../../../../../../components';
import * as S from '../../styles';
import DialogModifiedInformation from '../DialogModifiedInformation';
import { ClaimEventHistoryType } from '../../../../../../../../types/ClaimTypes';
import dayjs from 'dayjs';

type Props = {
  color: string;
  title: string;
  description?: string;
  clickable?: boolean;
  claimEvent?: ClaimEventHistoryType;
};

const ClaimMessageCard = ({
  color,
  title,
  description,
  clickable,
  claimEvent,
}: Props) => {
  const [isOpenModifiedDialog, setIsOpenModifiedDialog] = useState(false);
  return (
    <>
      <Dialog
        open={isOpenModifiedDialog}
        onClose={() => setIsOpenModifiedDialog(!isOpenModifiedDialog)}
      >
        <DialogModifiedInformation claimEvent={claimEvent} />
      </Dialog>
      <S.CardWrapper
        $color={color}
        $clickable={clickable}
        onClick={() => {
          clickable && setIsOpenModifiedDialog(!isOpenModifiedDialog);
        }}
      >
        <S.Title>{title}</S.Title>
        {description && <S.CardText>{description}</S.CardText>}
        {claimEvent && (
          <S.CardText>
            {claimEvent.modifier
              ? claimEvent.modifier.profile.firstName +
                ' ' +
                claimEvent.modifier.profile.lastName
              : 'System'}
            , {dayjs(claimEvent.createdAt).format('MM/DD/YYYY h:mm A')}
          </S.CardText>
        )}
      </S.CardWrapper>
    </>
  );
};

export default ClaimMessageCard;
