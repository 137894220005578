import styled from 'styled-components';
import { devices } from '../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media ${devices.xs} {
    min-height: 100vh;
  }
  max-height: 100vh;
  overflow: auto;
`;

export const SubtitleBar = styled.div`
  height: 62px;
  margin-left: 22px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ${devices.xs} {
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 5px;
  margin-top: 10px;
`;

export const Loader = styled.div`
  & .MuiLinearProgress-root {
    background-color: #e5f0f6;
    /* width: 100px !important;
  height: 100px !important; */
  }

  & .MuiLinearProgress-bar {
    background-color: #0084b1;
    /* width: 100px !important;
  height: 100px !important; */
  }
`;

export const TableBody = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 178px;

  @media ${devices.xs} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 100%;
  margin: 7px 10px;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

export const SearchWrapper = styled.div`
  width: 572px;
  margin-right: 6px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const PopoverWrapper = styled.div`
  border-radius: 5px;
  background: var(--icon, #848a9b);
  width: 98px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContentRow = styled.div`
  padding: 11px 30px 11px 13px;
  width: 100%;
  /* cursor: pointer; */
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  /* &:hover {
    background-color: #e5f0f6;
  } */
`;

export const HeaderTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 15px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;

  & svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    stroke: #848a9b;
    opacity: 1;

    &.disabled {
      stroke: #bfc2cb;
      pointer-events: none;
    }
  }
`;

export const PaginationText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MenuStaffWrapper = styled.div``;

export const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

export const AvatarCellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const CellColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
`;

export const ColumnText = styled.div``;

export const DotsWrapper = styled.div`
  max-width: 20px;
  svg {
    pointer-events: none;
    opacity: 0.3;
  }

  &.deletable {
    svg {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
`;

export const AlertText = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #848a9b;
`;
