import { useParams } from 'react-router-dom';
import { ArrowLSVG, ArrowRSVG, EyeVisibleSVG } from 'assets/icons';
import { Button, CustomTable, Dialog } from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useInvoicePostPaymentOneQuery } from 'store/api/invoices/invoiceApi';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { InvoicesMainType } from 'types/InvoiceType';
import { InvInfPostRow } from './TableData/InvInfPostRow';
import React, { useState } from 'react';
import { ReportsModal } from './ReportsModal';

import * as S from './styles';

const headers = [
  { name: 'ID', key: 'ID' },
  { name: 'Patient Name', key: 'PatientName' },
  { name: 'Account #', key: 'Account' },
  { name: 'Claim #', key: 'Claim' },
  { name: 'Submitted on', key: 'SubmittedOn' },
  { name: 'Paid $', key: 'Paid' },
  { name: 'Check #', key: 'Check' },
  { name: 'Issue Date', key: 'IssueDate' },
  { name: 'Payment Notes', key: 'PaymentNotes' },
  { name: 'EOB', key: 'EOB' },
  { name: 'Checked by', key: 'CheckedBy' },
  { name: 'Checked Date', key: 'CheckedDate' },
];

const ITEMS_POST_PAYMENT_LIMIT = 20;

export const InvInformPostPaymentTable = () => {
  const dispatch = useAppDispatch();

  const params = useParams();
  const [showReportsModal, setShowReportsModal] = useState(false);

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(ITEMS_POST_PAYMENT_LIMIT);
  const [page, setPage] = useState(1);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + ITEMS_POST_PAYMENT_LIMIT);
    setSecondCount(secondCount + ITEMS_POST_PAYMENT_LIMIT);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - ITEMS_POST_PAYMENT_LIMIT);
    setSecondCount(secondCount - ITEMS_POST_PAYMENT_LIMIT);
  };

  const detailInvoice = useInvoicePostPaymentOneQuery({
    id: params.id,
    page: page,
    take: ITEMS_POST_PAYMENT_LIMIT,
  });

  const handleShowCount = () => {
    if (detailInvoice?.currentData?.count && detailInvoice.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {detailInvoice?.currentData?.count &&
          detailInvoice.currentData.count < secondCount
            ? detailInvoice.currentData.count
            : secondCount}{' '}
          of {detailInvoice?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {detailInvoice?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={detailInvoice.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                detailInvoice.currentData?.page === detailInvoice.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {detailInvoice.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>

        <S.ButtonWrap>
          <Button
            text="Show Reports"
            backgroundColor="#0084b1"
            onClick={() => setShowReportsModal(true)}
          ></Button>
        </S.ButtonWrap>
        <S.ButtonShow
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.SHOW_INVOICE_HISTORY,
                props: { id: params.id, type: InvoicesMainType.POSTPAYMENT },
              }),
            )
          }
        >
          <EyeVisibleSVG />
          <S.Text>Show Edit History</S.Text>
        </S.ButtonShow>
      </S.Header>
      <S.TableWrapper>
        <CustomTable headers={headers}>
          <InvInfPostRow data={detailInvoice.currentData?.rows} headers={headers} />
        </CustomTable>
      </S.TableWrapper>

      <Dialog open={showReportsModal} onClose={() => setShowReportsModal(false)}>
        <ReportsModal />
      </Dialog>
    </S.Wrapper>
  );
};
