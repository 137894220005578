import { Fade, TableCell, TableRow, Tooltip } from '@mui/material';
import {
  AccTableInsuranceType,
  BillingAccTableType,
} from '../../../../../../../../types/AccTableTypes';

type Props = {
  data: BillingAccTableType;
  index: number;
};

const TableRowInsurance: React.FC<Props> = ({ data, index }) => {
  return (
    <TableRow>
      {index === 0 && (
        <TableCell className="white white-header white-header-top insurance"></TableCell>
      )}
      {data.clinics.map((clinic, index: number) => {
        return clinic.data.map((item: AccTableInsuranceType, indexnum: number) => {
          // console.log(indexnum, " ", data.clinics.length);

          return (
            <Tooltip
              key={item.insurance + data.doctorName + indexnum}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#0084B1',
                    fontFamily: 'Inter',
                  },
                },
              }}
              title={item.insurance}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 100 }}
            >
              <TableCell className="insurance">{item.insurance}</TableCell>
            </Tooltip>
          );
        });
      })}
    </TableRow>
  );
};

export default TableRowInsurance;
