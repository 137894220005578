import { ChangeEvent, useEffect, useState } from 'react';
import {
  AppointmentWithoutRegistration,
  Button,
  CheckBox,
  Input,
} from '../../../../../components';
import * as S from './styles';
import { PencilSVG } from '../../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import {
  useSignUpMutation,
  useVerifyMutation,
} from '../../../../../store/api/auth/authApi';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { setMessage } from '../../../../../store/slices/message';
import { AuthFieldType } from '../../../../../types/enums/AppointmentEnum';
import { isTypeAuthField } from '../../../../../helpers/isTypeAuthField';

type FormValues = {
  authField: string;
  password: string;
  confirmPassword: string;
  checked: boolean;
};

const SignUp = () => {
  const dispatch = useDispatch();
  const [authFieldType, setAuthFieldType] = useState<AuthFieldType>(AuthFieldType.PHONE);

  const SignUpSchema = yup.object().shape({
    authField:
      authFieldType === AuthFieldType.PHONE
        ? yup
            .string()
            .matches(/^\+[1-9]\d{1,14}$/, 'International format required: +1xxxxxxxxxx')
            .min(12, 'International format required: +1xxxxxxxxxx')
            .max(12, 'International format required: +1xxxxxxxxxx')
            .required('Is required')
        : yup
            .string()
            .matches(
              /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
              'Set email in valid format',
            )
            .required('Is required'),
    password: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .required('Is required'),
    confirmPassword: yup
      .string()
      .required('Is required')
      .oneOf([yup.ref('password')], 'Passwords must match'),
    checked: yup.boolean().oneOf([true]).required('Is required'),
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const { values, handleChange, handleSubmit, errors, setFieldError, setFieldValue } =
    useFormik<FormValues>({
      initialValues: {
        authField: '',
        password: '',
        confirmPassword: '',
        checked: false,
      },
      validateOnChange: false,
      validationSchema: SignUpSchema,
      onSubmit: async ({ authField, password, confirmPassword }) => {
        await verify({ authField, authFieldType })
          .unwrap()
          .then(res => {
            navigate('/verificationcode', {
              state: { authField, authFieldType, password, confirmPassword },
            });
          })
          .catch(error => {
            dispatch(setMessage({ message: error.data.message, type: 'error' }));
          });
      },
    });

  useEffect(() => {
    values.authField && setAuthFieldType(isTypeAuthField(values.authField));
  }, [values.authField]);

  const navigate = useNavigate();

  const [verify] = useVerifyMutation();

  return (
    <S.SignUpWrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.Inputs>
          <Input
            id="authField"
            name="authField"
            label="Phone or Email"
            value={values.authField}
            onChange={handleChangeInput}
            error={!!errors.authField}
            helperText={errors.authField}
            placeholder="+1xxxxxxxxxx or xxx@xxx.com"
          />
          <Input
            isPassword
            id="password"
            name="password"
            label="Password"
            value={values.password}
            onChange={handleChangeInput}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Input
            isPassword
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChangeInput}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
          <S.CheckBoxWrapper>
            <CheckBox
              checked={values.checked}
              value={values.checked}
              onChange={handleChangeInput}
              id="checked"
              name="checked"
            />
            <S.CheckBoxLabel>
              Accept <S.StyledSpan>Terms & Conditions</S.StyledSpan>
            </S.CheckBoxLabel>
          </S.CheckBoxWrapper>
        </S.Inputs>
        <S.Buttons>
          <S.ButtonWrapper>
            <Button
              text="Submit"
              type="submit"
              onClick={() => {
                values.authField.length === 11 &&
                  values.authField[0] === '1' &&
                  authFieldType === AuthFieldType.PHONE &&
                  setFieldValue('authField', '+' + values.authField);

                values.authField.length === 10 &&
                  authFieldType === AuthFieldType.PHONE &&
                  values.authField[0] !== '+' &&
                  setFieldValue('authField', '+1' + values.authField);
              }}
              disabled={!values.checked && true}
              backgroundColor={!values.checked ? '#848A9B' : ''}
            />
          </S.ButtonWrapper>
          <AppointmentWithoutRegistration />
        </S.Buttons>
      </form>
    </S.SignUpWrapper>
  );
};

export default SignUp;
