import dayjs from 'dayjs';
import * as S from '../styles';
import Highlighter from 'react-highlight-words';

export enum OptionType {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
  DOB = 'dob',
}

type Props = {
  props: any;
  option: any;
  loading: boolean;
  searchValue: string;
  otherText?: string;
  textToHighlight: string;
  type: OptionType;
};

const OptionContent: React.FC<Props> = ({
  props,
  option,
  loading,
  otherText,
  searchValue,
  textToHighlight,
  type,
}) => {
  const showOpt = () => {
    switch (type) {
      case OptionType.FIRSTNAME:
        return (
          <S.Content>
            <S.UserName>
              <span style={{ background: 'yellow' }}>
                {textToHighlight.substring(0, searchValue.length)}
              </span>
              {textToHighlight.substring(searchValue.length)}
              {' ' + option.profile?.lastName}
            </S.UserName>
            <S.DOB>
              {option?.profile?.dateOfBirth
                ? dayjs(option?.profile?.dateOfBirth).format('MM/DD/YYYY')
                : ''}
            </S.DOB>
          </S.Content>
        );
      case OptionType.LASTNAME:
        return (
          <S.Content>
            <S.UserName>
              {option.profile?.lastName + ' '}
              <span style={{ background: 'yellow' }}>
                {textToHighlight.substring(0, searchValue.length)}
              </span>
              {textToHighlight.substring(searchValue.length)}
            </S.UserName>
            <S.DOB>
              {option?.profile?.dateOfBirth
                ? dayjs(option?.profile?.dateOfBirth).format('MM/DD/YYYY')
                : ''}
            </S.DOB>
          </S.Content>
        );
      case OptionType.PHONE:
        return (
          <S.Content>
            <S.UserName>
              {option.profile?.firstName + ' ' + option.profile?.lastName}
            </S.UserName>
            <S.DOB>
              <span style={{ background: 'yellow' }}>
                {textToHighlight.substring(0, searchValue.length)}
              </span>
              {textToHighlight.substring(searchValue.length)}
            </S.DOB>
          </S.Content>
        );
      case OptionType.EMAIL:
        return (
          <S.Content>
            <S.UserName>
              {option.profile?.firstName + ' ' + option.profile?.lastName}
            </S.UserName>
            <S.DOB>
              <span style={{ background: 'yellow' }}>
                {textToHighlight.substring(0, searchValue.length)}
              </span>
              {textToHighlight.substring(searchValue.length)}
            </S.DOB>
          </S.Content>
        );
      case OptionType.DOB:
        return (
          <S.Content>
            <S.UserName>
              {option.profile?.firstName + ' ' + option.profile?.lastName}
            </S.UserName>
            <S.DOB>
              <span style={{ background: 'yellow' }}>
                {textToHighlight.substring(0, searchValue.length)}
              </span>
              {textToHighlight.substring(searchValue.length)}
            </S.DOB>
          </S.Content>
        );
      default:
        return;
    }
  };

  return <S.OptionWrap {...props}>{loading ? 'Searching...' : showOpt()}</S.OptionWrap>;
};

export default OptionContent;
