import { useFormik } from 'formik';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import * as S from './styles';
import * as yup from 'yup';
import {
  ApproveBtnSVG,
  EditBtnSVG,
  ResetApproveBtnSVG,
} from '../../../../../../assets/icons';
import { useEffect, useState } from 'react';
import EditableCredentials from './EditableCredentials';
import ViewCredentials from './ViewCredentials';
import { useUpdateProfileMutation } from '../../../../../../store/api/user/profileApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';

type Props = {
  profile?: UserProfile;
};

export type CredentialsFormValues = {
  NPI: string;
  TAX_ID: string;
  Taxonomy: string;
  Taxonomy_NAME: string;
  NYS_ACU: string;
  NYS_ACU_Lic_EXP: string;
  CAQH_ID: string;
  First_AID_Cert: string;
  First_AID_Cert_EXP: string;
  Aetna_ID: string;
  UHC_ID: string;
  OXPH_PIN: string;
};

const CredentialsDoctor: React.FC<Props> = ({ profile }) => {
  const Shape = yup.object().shape({});

  const [isEditable, setIsEditable] = useState(false);

  const dispatch = useAppDispatch();

  const [updateCred, updateCredStatus] = useUpdateProfileMutation({});

  const formik = useFormik<CredentialsFormValues>({
    initialValues: {
      NPI: '',
      TAX_ID: '',
      Taxonomy: '',
      Taxonomy_NAME: '',
      NYS_ACU: '',
      NYS_ACU_Lic_EXP: '',
      CAQH_ID: '',
      First_AID_Cert: '',
      First_AID_Cert_EXP: '',
      Aetna_ID: '',
      UHC_ID: '',
      OXPH_PIN: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {
      updateCred({
        id: profile?.id,
        credentials: {
          npi: formik.values.NPI,
          taxId: formik.values.TAX_ID,
          taxonomy: formik.values.Taxonomy,
          taxonomyName: formik.values.Taxonomy_NAME,
          nysAcuLicense: formik.values.NYS_ACU,
          nysAcuLicenseExpiration: formik.values.NYS_ACU_Lic_EXP,
          caqhId: formik.values.CAQH_ID,
          firstAidCertification: formik.values.First_AID_Cert,
          firstAidCertificationExpiration: formik.values.First_AID_Cert_EXP,
          aetnaId: formik.values.Aetna_ID,
          uhcId: formik.values.UHC_ID,
          oxphId: formik.values.OXPH_PIN,
        },
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'User was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  useEffect(() => {
    if (profile?.credentials) {
      formik.setValues({
        ...formik.values,
        NPI: profile.credentials.npi || '',
        TAX_ID: profile.credentials.taxId || '',
        Taxonomy: profile.credentials.taxonomy || '',
        Taxonomy_NAME: profile.credentials.taxonomyName || '',
        NYS_ACU: profile.credentials.nysAcuLicense || '',
        NYS_ACU_Lic_EXP: profile.credentials.nysAcuLicenseExpiration || '',
        CAQH_ID: profile.credentials.caqhId || '',
        First_AID_Cert: profile.credentials.firstAidCertification || '',
        First_AID_Cert_EXP: profile.credentials.firstAidCertificationExpiration || '',
        Aetna_ID: profile.credentials.aetnaId || '',
        UHC_ID: profile.credentials.uhcId || '',
        OXPH_PIN: profile.credentials.oxphId || '',
      });
    }
  }, [isEditable]);
  return (
    <S.Wrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <S.Content>
          <S.EditBtnWrapper>
            <S.Article>Credentials</S.Article>
            {isEditable ? (
              <S.ButtonsEdit>
                <ResetApproveBtnSVG
                  onClick={() => {
                    formik.resetForm();
                    setIsEditable(false);
                  }}
                />
                <ApproveBtnSVG
                  onClick={() => {
                    formik.submitForm();
                  }}
                />
              </S.ButtonsEdit>
            ) : (
              <EditBtnSVG onClick={() => setIsEditable(true)} />
            )}
          </S.EditBtnWrapper>
          {isEditable ? (
            <EditableCredentials formik={formik} />
          ) : (
            <ViewCredentials formik={formik} profile={profile} />
          )}
        </S.Content>
      </form>
    </S.Wrapper>
  );
};

export default CredentialsDoctor;
