import { useState } from 'react';
import { AddPatientsSVG, ArrowSVG } from '../../../../../../assets/icons';
import { Button, NotificationPopover } from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useCsvDownloadMutation } from '../../../../../../store/api/clinics/clinicApi';
import * as S from '../../styles';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';
import { useUploadCSVMutation } from '../../../../../../store/api/files/files';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';
import { DrawerMode } from '../../../../../../types/DrawerMode';

const ExportBtns = () => {
  const [downloadCSV] = useCsvDownloadMutation();
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const handleAnchorExport = (e: React.MouseEvent<HTMLElement>) => {
    anchorElExport ? setAnchorElExport(null) : setAnchorElExport(e.currentTarget);
  };

  const dispatch = useAppDispatch();
  const [uploadCSV, status] = useUploadCSVMutation();

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    await uploadCSV({
      id: selectedClinic,
      file: file,
    })
      .unwrap()
      .then(res => {
        setAnchorElExport(null);
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        setAnchorElExport(null);
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
  };

  return (
    <>
      <S.PopoverWrapper onClick={handleAnchorExport}>
        Export <ArrowSVG />
        <NotificationPopover
          open={Boolean(anchorElExport)}
          anchorEl={anchorElExport}
          onClose={() => setAnchorElExport(null)}
        >
          <S.ContentWrapper>
            <span className="icon-download download-csv-1" />
            <S.ContentRow
              onClick={() => {
                downloadCSV({ id: selectedClinic.id });
                setAnchorElExport(null);
              }}
            >
              Export Patient Data
            </S.ContentRow>
            <label htmlFor="fileclick">
              <S.ContentRow>Import Patient Data</S.ContentRow>
              <input
                id="fileclick"
                type="file"
                hidden
                multiple={false}
                accept=".csv"
                onChange={handleChangeFile}
              />
            </label>
          </S.ContentWrapper>
        </NotificationPopover>
      </S.PopoverWrapper>
      <S.ButtonWrapper>
        <Button
          text="Add Patients"
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.ADD_PATIENTS,
                props: selectedClinic.id,
              }),
            )
          }
        >
          <AddPatientsSVG />
        </Button>
      </S.ButtonWrapper>
    </>
  );
};

export default ExportBtns;
