import * as S from './styles';
import logo from '../../../assets/images/avatarmock.jpg';
import {
  AddHealthRecordSVG,
  AddPatientsSVG,
  AddRectSVG,
  AtentionSVG,
  AvatarSVG,
  EyeVisibleSVG,
  EyeVisibleWhiteSVG,
  PlacePinSVG,
} from '../../../assets/icons';
import { MouseEventHandler, useEffect, useState, MouseEvent } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCalendarTypes } from '../../../helpers/CalendarEventType/getCalendarType';
import { getStatusColor } from '../../../helpers/getStatusColor';
import { Event } from 'react-big-calendar';
import { EventView } from '../../../types/AppointmentsTypes';
import NotificationPopover from '../../organismus/NotificationPopover';
import { Popover, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import PopupState, { bindTrigger, bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverInformation from './HoverInformation';
import { replace } from 'lodash';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Roles } from '../../../types/Roles';
import { isEditable } from '@testing-library/user-event/dist/utils';
import ClickEventMenu from './ClickEventMenu';
import { Dialog } from '../..';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import { setMessage } from '../../../store/slices/message';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
} from '../../../store/api/appointment/appointmentApi';
import { DrawerMode } from '../../../types/DrawerMode';
import { showDrawer } from '../../../store/slices/drawerSlice';

type Props = {
  type: string;
  time: string;
  status: string;
  isFirstTimeVisit: boolean;
  fullEvent: Event;
};

const CalendarEvent: React.FC<Props> = ({
  type,
  time,
  status,
  isFirstTimeVisit,
  fullEvent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const calendarParams = getCalendarTypes(type, isFirstTimeVisit);

  const me = useAppSelector(state => state.auth);

  const statusColor = getStatusColor(status).status;

  // console.log(fullEvent);

  const [isHover, setIsHover] = useState(false);

  const navigate = useNavigate();
  const currentEvent = fullEvent as EventView;

  const handleAddHealthRecord = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (currentEvent.resource.selectedEvent?.patient?.id) {
      navigate('/patients/' + currentEvent.resource.selectedEvent?.patient?.id, {
        state: { event: currentEvent },
      });
    }
  };

  const handleShowHealthRecord = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (currentEvent.resource.selectedEvent?.patient?.id) {
      navigate('/patients/' + currentEvent.resource.selectedEvent?.patient?.id, {
        state: { event: currentEvent, isEditable: true },
      });
    }
  };

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();

  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [deleteVisit] = useDeleteVisitServiceMutation({});

  const handleDelete = () => {
    fullEvent.resource?.selectedEvent?.visits.length > 1
      ? deleteVisit({ id: fullEvent.resource?.visit?.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Service was successfully deleted from appointment',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          })
      : deleteAppointment({ id: fullEvent.resource?.selectedEvent.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Appointment was successfully deleted',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <S.MainWrapper {...bindHover(popupState)}>
        <HoverPopover
          className="rr"
          TransitionProps={{ timeout: 200 }}
          TransitionComponent={Fade}
          transitionDuration={1500}
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: -10,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <HoverInformation event={currentEvent} />
        </HoverPopover>
        <S.EventWrapper
          background={calendarParams.backgroundColor}
          border={isHover ? '#0084B1' : calendarParams.borderColor}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover && (
            <>
              <S.ButtonAdd onClick={handleAnchorReport}>
                <AddHealthRecordSVG />
                <NotificationPopover
                  open={Boolean(anchorElReport)}
                  anchorEl={anchorElReport}
                  onClose={() => {
                    setAnchorElReport(null);
                  }}
                >
                  <ClickEventMenu
                    setOpenDialog={setOpenDialog}
                    visit={fullEvent.resource?.visit}
                    appointment={fullEvent.resource?.selectedEvent}
                  />
                </NotificationPopover>
              </S.ButtonAdd>
            </>
          )}
          {mode === 'day' ? (
            <S.Content>
              <S.StatusBar status={statusColor} />
              <S.Time>{time}</S.Time>
              <S.Type>{calendarParams.title}</S.Type>
              <S.Username>
                <S.AvatarDiv>
                  {currentEvent.resource.selectedEvent?.patient?.avatar?.url ? (
                    <img
                      src={currentEvent.resource.selectedEvent?.patient?.avatar?.url}
                      alt="img"
                    />
                  ) : (
                    <AvatarSVG />
                  )}
                </S.AvatarDiv>
                {currentEvent.resource.selectedEvent?.patient?.profile?.firstName}{' '}
                {currentEvent.resource.selectedEvent?.patient?.profile?.lastName}
              </S.Username>
              <S.ClinicsName>
                <PlacePinSVG />
                {currentEvent.resource.selectedEvent?.clinic?.name}
              </S.ClinicsName>
            </S.Content>
          ) : (
            <S.SmallContent>
              <Dialog
                open={openDialog}
                onClose={() => {
                  dispatch(
                    showDrawer({
                      show: false,
                      mode: DrawerMode.DEFAULT,
                      props: null,
                    }),
                  );
                  handleChangeDialog();
                }}
              >
                <ConfirmDeleteDialog
                  onClose={handleChangeDialog}
                  fullTitle={
                    fullEvent.resource?.selectedEvent?.visits.length > 1
                      ? `Are you sure want to delete current service from appointment #${fullEvent.resource?.selectedEvent?.id.slice(
                          0,
                          8,
                        )}?`
                      : 'Are you sure you want to delete the entire appointment?'
                  }
                  helperText={
                    fullEvent.resource?.selectedEvent?.visits.length > 1
                      ? 'You will delete only one current service from appointment'
                      : 'You will delete the entire appointment and lose progress'
                  }
                  onSuccess={handleDelete}
                />
              </Dialog>
              <S.StatusBar className="small" status={statusColor} />
              <S.ContentWrap>
                <S.Type>{calendarParams.title}</S.Type>
                {/* <S.Username>Client name</S.Username> */}
              </S.ContentWrap>
            </S.SmallContent>
          )}
        </S.EventWrapper>
      </S.MainWrapper>
    </>
  );
};

export default CalendarEvent;
