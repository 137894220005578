import { useState } from 'react';
import { AddPatientsSVG, ArrowLSVG, ArrowRSVG } from '../../../../../../assets/icons';
import { Button, CustomTable, SearchInput } from '../../../../../../components';
import * as S from '../../styles';
import {
  useBillingsGetQuery,
  useStaffGetQuery,
} from '../../../../../../store/api/clinics/clinicApi';
import { TabsEnum } from '../../../../../../types/enums/TabsEnum';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import StaffBillingRow from './TableData/StaffBillingRow';
import { Roles } from '../../../../../../types/Roles';
import { DrawerMode } from '../../../../../../types/DrawerMode';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../store/slices/drawerSlice';

const StaffBilling = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const getList = useBillingsGetQuery({
    page: page,
    q: debouncedSearchValue,
  });

  const headers = [
    { name: 'Billing name', key: 'Name' },
    { name: 'Phone', key: 'Phone' },
    { name: 'Email', key: 'Email' },
    { name: 'Status', key: 'Status' },
  ];

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(20);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + 20);
    setSecondCount(secondCount + 20);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - 20);
    setSecondCount(secondCount - 20);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const handleShowCount = () => {
    if (getList?.currentData?.count && getList.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getList?.currentData?.count && getList.currentData.count < secondCount
            ? getList.currentData.count
            : secondCount}{' '}
          of {getList?.currentData?.count} billing specialists{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getList?.currentData?.count} billing specialists{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.TableBody>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getList.currentData?.page === getList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            <Button
              text="Add Billing Specialist"
              onClick={() =>
                dispatch(
                  showDrawer({
                    mode: DrawerMode.ADD_BILLING,
                    props: null,
                    show: true,
                  }),
                )
              }
            >
              <AddPatientsSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <StaffBillingRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffBilling;
