import { useEffect, useRef, useState } from 'react';
import { AppointmentSVG } from '../../../assets/icons';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Roles } from '../../../types/Roles';
import Button from '../../atoms/Button';
import SearchInput from '../../atoms/SearchInput';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import CalendarBar from './components/CalendarBar';
import { AppointmentTypeEnum, ModeEnum } from '../../../types/enums/AppointmentEnum';
import { getWeekInitial } from '../../../helpers/CalendarHelpers/getWeekInitial';
import { DateObject } from 'react-multi-date-picker';
import { AppointmentStatus } from '../../../types/StatusTypes';
import {
  useAppointmentGetQuery,
  useLazyAppointmentGetQuery,
} from '../../../store/api/appointment/appointmentApi';
import { getDay } from '../../../helpers/CalendarHelpers/getDay';
import AppointmentsToolbar from './components/AppointmentsToolbar';
import { handgleChangeMode } from '../../../helpers/CalendarHelpers/handleDates';
import { View } from 'react-big-calendar';
import PlannerView from './components/PlannerView';
import BigCalendar from './components/BigCalendar';
import PendingView from './components/PendingView';

const AppointmentsView = () => {
  const userInfo = useAppSelector(state => state.auth);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);

  const [page, setPage] = useState(1);

  const [selectedDate, setSelectedDate] = useState<DateObject[]>(
    getDay(new DateObject()),
  );

  const [mode, setMode] = useState(ModeEnum.DAY);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const [reasonType, setReasonType] = useState<AppointmentTypeEnum[]>([
    AppointmentTypeEnum.ACUPUNCTURE,
    AppointmentTypeEnum.HERBS,
    AppointmentTypeEnum.MASSAGE,
    AppointmentTypeEnum.PT,
    AppointmentTypeEnum.OTHER,
  ]);

  const [appointmentStatus, setAppointmentStatus] = useState<any>([
    AppointmentStatus.PENDING,
    AppointmentStatus.COMPLETED,
    AppointmentStatus.ACTIVE,
  ]);

  const [filteredDoctor, setFilteredDoctor] = useState<string[]>([]);

  useEffect(() => {
    handgleChangeMode(mode, setSelectedDate, setPage);
  }, [mode]);

  const [isModePending, setIsModePending] = useState(false);

  const [getAllAppointments, allAppointments] = useLazyAppointmentGetQuery({});

  useEffect(() => {
    setPage(1);
  }, [
    isModePending,
    reasonType,
    selectedDate,
    appointmentStatus,
    selectedClinic?.id,
    debouncedSearch,
    filteredDoctor,
  ]);

  useEffect(() => {
    if (selectedClinic?.id && !isModePending) {
      getAllAppointments({
        q: debouncedSearch,
        visitDateTimeStart: getDay(selectedDate[0])[0].format('YYYY-MM-DD'),
        visitDateTimeEnd: getDay(selectedDate[1])[1].format('YYYY-MM-DD'),
        ...(appointmentStatus && {
          'status[]': appointmentStatus,
        }),
        'reason[]': reasonType,
        page: page,
        take: 300,
        'clinicId[]': selectedClinic.id,
        ...(filteredDoctor.length > 0 && {
          'specialistId[]': filteredDoctor,
        }),
      });
    }
    if (selectedClinic?.id && isModePending) {
      getAllAppointments({
        q: debouncedSearch,
        'status[]': 'pending',
        page: page,
        take: 20,
        'reason[]': reasonType,
        'clinicId[]': selectedClinic.id,
        ...(filteredDoctor.length > 0 && {
          'specialistId[]': filteredDoctor,
        }),
      });
    }
  }, [
    selectedClinic?.id,
    debouncedSearch,
    page,
    reasonType,
    appointmentStatus,
    selectedDate,
    isModePending,
    filteredDoctor,
  ]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>All Appointments</S.SubtitleText>
        <S.SubtitleItems>
          <S.SearchWrapper>
            {userInfo.role !== Roles.PATIENT && (
              <SearchInput
                value={searchValue}
                onChange={handleSearch}
                placeholder="Search by username"
              />
            )}
          </S.SearchWrapper>

          {userInfo.role === Roles.PATIENT && (
            <S.ButtonWrapper>
              <Button text="Request an Appointment">
                <AppointmentSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <CalendarBar
          mode={mode}
          setSelectedDate={setSelectedDate}
          setPage={setPage}
          isModePending={isModePending}
          setIsModePending={setIsModePending}
          selectedDate={selectedDate}
          reasonType={reasonType}
          setReasonType={setReasonType}
          appointmentStatus={appointmentStatus}
          setAppointmentStatus={setAppointmentStatus}
          filteredDoctor={filteredDoctor}
          setFilteredDoctor={setFilteredDoctor}
          isFetching={
            allAppointments.isLoading || allAppointments.isFetching || !selectedClinic?.id
          }
        />
        {!isModePending ? (
          <S.Information>
            <AppointmentsToolbar
              isFetching={
                allAppointments.isLoading ||
                allAppointments.isFetching ||
                !selectedClinic?.id
              }
              mode={mode}
              setMode={setMode}
              selectedDate={selectedDate}
              setPage={setPage}
              setSelectedDate={setSelectedDate}
            />
            {mode === ModeEnum.DAY && (
              <PlannerView
                allAppointments={allAppointments.data}
                selectedDate={selectedDate}
                isFetching={
                  allAppointments.isLoading ||
                  allAppointments.isFetching ||
                  !selectedClinic?.id
                }
              />
            )}
            {(mode === ModeEnum.WEEK || mode === ModeEnum.MONTH) && (
              <BigCalendar
                setSelectedDate={setSelectedDate}
                mode={mode}
                selectedDate={selectedDate}
                allAppointments={allAppointments.data}
              />
            )}
          </S.Information>
        ) : (
          <S.Information>
            <AppointmentsToolbar
              isModePending={isModePending}
              isFetching={
                allAppointments.isLoading ||
                allAppointments.isFetching ||
                !selectedClinic?.id
              }
              mode={mode}
              setMode={setMode}
              selectedDate={selectedDate}
              setPage={setPage}
              setSelectedDate={setSelectedDate}
            />
            <PendingView
              page={page}
              setPage={setPage}
              isModePending={isModePending}
              allAppointments={allAppointments.data}
              isFetching={
                allAppointments.isLoading ||
                allAppointments.isFetching ||
                !selectedClinic?.id
              }
            />
          </S.Information>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default AppointmentsView;
