import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, CheckBox, Dialog, SelectInput } from '../../../../../../components';
import * as S from '../../styles';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import {
  AddClinicIconSVG,
  DeleteRedBtnSVG,
  JPGSVG,
} from '../../../../../../assets/icons';
import AssignRecord from './components/AssignRecord';
import AssignClinicDialog from './components/AssignClinicDialog';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useDeleteDoctorSignatureMutation } from '../../../../../../store/api/user/profileApi';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';

type FormValues = {
  clinics: Array<string>;
};

type Props = {
  profile?: UserProfile;
};

const AssignTo: React.FC<Props> = ({ profile }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openImgSignature, setOpenImgSignature] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const { profileId } = useParams();

  const [deleteDoctorSignature] = useDeleteDoctorSignatureMutation({});
  const dispatch = useAppDispatch();

  return (
    <S.AssignWrapper>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          userId={profileId || ''}
          onClose={() => setOpenDialog(!openDialog)}
        />
      </Dialog>
      <Dialog
        open={openConfirmDeleteDialog}
        onClose={() => setOpenConfirmDeleteDialog(!openConfirmDeleteDialog)}
      >
        <ConfirmDeleteDialog
          titleText="signature"
          onClose={() => setOpenConfirmDeleteDialog(!openConfirmDeleteDialog)}
          onSuccess={() => {
            deleteDoctorSignature({ id: profile?.id })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Signature was successfully deleted',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              });
          }}
        />
      </Dialog>
      <Dialog
        open={openImgSignature}
        onClose={() => setOpenImgSignature(!openImgSignature)}
      >
        <S.ContentDialog>
          <img src={profile?.signature?.url} alt="" />
        </S.ContentDialog>
      </Dialog>
      {profile?.signature?.id && (
        <S.BlockContent>
          <S.ArticleWrap>
            <S.Article>Signature</S.Article>
            <S.HelperText>Here you can manage doctor signature</S.HelperText>
          </S.ArticleWrap>

          <S.FileRow onClick={() => setOpenImgSignature(!openImgSignature)}>
            <S.InfoRow>
              <JPGSVG className="icon" />
              {'Doctor signature. Click to open view'}
            </S.InfoRow>
            <S.Date>
              Date: {dayjs(profile?.signature?.createdAt).format('MM/DD/YYYY')}
              <DeleteRedBtnSVG
                onClick={e => {
                  e.stopPropagation();
                  setOpenConfirmDeleteDialog(!openConfirmDeleteDialog);
                }}
              />
            </S.Date>
          </S.FileRow>
        </S.BlockContent>
      )}

      <S.HeaderWrapper>
        <S.ArticleWrap>
          <S.Article>Assign to Clinic</S.Article>
          <S.HelperText>Here you can choose clinic to assign user</S.HelperText>
        </S.ArticleWrap>
        <S.ButtonAssignWrapper>
          <Button
            text="Assign clinic"
            onClick={() => {
              setOpenDialog(!openDialog);
            }}
          >
            <AddClinicIconSVG />
          </Button>
        </S.ButtonAssignWrapper>
      </S.HeaderWrapper>
      <S.ContentRows>
        {profile?.clinics?.map(clinic => {
          return (
            <AssignRecord
              assignInfo={clinic}
              key={clinic.id}
              clinicsList={profile?.clinics}
            />
          );
        })}
      </S.ContentRows>
    </S.AssignWrapper>
  );
};

export default AssignTo;
