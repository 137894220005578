import { useParams } from 'react-router-dom';
import * as S from './styles';
import {
  useClinicUpdateMutation,
  useClinicsGetOneQuery,
  useUpdateLogoClinicMutation,
} from 'store/api/clinics/clinicApi';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  AddRectSVG,
  ApproveBtnSVG,
  ClinicPlaceholderSVG,
  CloseBtnSVG,
  CloseSVG,
  EditBtnSVG,
  EmailIconSVG,
  NotificationSmallSVG,
  PhoneIconSVG,
  ResetApproveBtnSVG,
} from 'assets/icons';
import { Input, SelectInput, SwitchCustom } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/slices/message';
import dayjs from 'dayjs';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import { Prefix } from 'types/ClinicTypes';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';

const ClinicProfileInformation = () => {
  const params = useParams();

  const clinicInfo = useClinicsGetOneQuery({
    id: params.clinicId,
  });

  const [avatar, setAvatar] = useState(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = (e: any) => {
    const file = e.target.files.item(0);
    if (file) {
      setAvatar(file);
    }
  };

  const handleClearImg = (e: any) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setAvatar(null);
  };

  const [isEditable, setIsEditable] = useState(false);

  type FormValues = {
    clinicName: string;
    email: string;
    phone: string;
    state: string;
    city: string;
    street: string;
    zipCode: string;
    isEventNotificationsEnabled: boolean;
    npi: string;
  };

  const Schema = yup.object().shape({});

  const [updateLogo] = useUpdateLogoClinicMutation({});
  const [updateClinic] = useClinicUpdateMutation({});
  const dispatch = useDispatch();

  const { role } = useAppSelector(state => state.auth);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = useFormik<FormValues>({
    initialValues: {
      clinicName: '',
      email: '',
      phone: '',
      state: '',
      city: '',
      npi: '',
      street: '',
      zipCode: '',
      isEventNotificationsEnabled: false,
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: async () => {
      const defaultPrefix = clinicInfo.currentData?.prefixes
        .map(prefix => {
          return {
            clinicId: clinicInfo.currentData?.id,
            isDefault: prefix.isDefault,
            value: prefix.value,
          };
        })
        .filter(item => item.isDefault === true);

      const additionalPrefix = newPrefixes
        .map(prefix => {
          return {
            clinicId: clinicInfo.currentData?.id,
            isDefault: false,
            value: prefix.value,
          };
        })
        .filter(item => item.value.length > 0);

      avatar &&
        (await updateLogo({
          id: params.clinicId,
          logo: avatar,
        })
          .unwrap()
          .then(res => {
            setIsEditable(false);
            resetForm();
            dispatch(
              setMessage({
                message: res.message,
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          }));
      await updateClinic({
        id: params.clinicId,
        name: values.clinicName,
        phone: [values.phone],
        email: values.email,
        state: values.state,
        city: values.city,
        npi: values.npi,
        prefixes: [...(defaultPrefix || []), ...additionalPrefix],
        street: values.street,
        zipCode: values.zipCode,
        isEventNotificationsEnabled: values.isEventNotificationsEnabled,
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const handleChangePrefix = (value: string, index: number) => {
    const clonedPref = [...newPrefixes];
    let obj = { ...clonedPref[index] };
    obj.value = value;
    clonedPref.splice(index, 1, obj);
    setNewPrefixes(clonedPref);
  };

  const handleDeletePrefix = (index: number) => {
    const newArr = [...newPrefixes];
    newArr.splice(index, 1);
    setNewPrefixes(newArr);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  const [prefixes, setPrefixes] = useState<Array<Prefix>>([]);
  const [newPrefixes, setNewPrefixes] = useState<Array<Prefix>>([]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setNewPrefixes([]);
    setValues({
      ...values,
      clinicName: clinicInfo.currentData?.name || '',
      email: clinicInfo.currentData?.email || '',
      phone: clinicInfo.currentData?.phone[0] || '',
      state: clinicInfo.currentData?.state || '',
      city: clinicInfo.currentData?.city || '',
      street: clinicInfo.currentData?.street || '',
      npi: clinicInfo.currentData?.npi || '',
      zipCode: clinicInfo.currentData?.zipCode || '',
      isEventNotificationsEnabled:
        clinicInfo.currentData?.isEventNotificationsEnabled || false,
    });
    clinicInfo.currentData?.prefixes &&
      setPrefixes(
        clinicInfo.currentData?.prefixes.filter(prefix => prefix.isDefault === false),
      );
    clinicInfo.currentData?.prefixes &&
      setNewPrefixes(
        clinicInfo.currentData?.prefixes.filter(prefix => prefix.isDefault === false),
      );
  }, [isEditable]);

  return (
    <S.Wrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.EditBtnWrapper>
          {isEditable ? (
            <S.ButtonsEdit>
              <ResetApproveBtnSVG
                onClick={() => {
                  setIsEditable(false);
                }}
              />
              <ApproveBtnSVG
                onClick={() => {
                  submitForm();
                }}
              />
            </S.ButtonsEdit>
          ) : (
            <S.ButtonsEdit>
              <EditBtnSVG onClick={() => setIsEditable(true)} />
            </S.ButtonsEdit>
          )}
        </S.EditBtnWrapper>
        <S.AvatarDiv>
          {isEditable ? (
            <S.AvatarClickZone>
              <label htmlFor="file">
                <S.AvatarZone>
                  {avatar ? (
                    <>
                      <img src={URL.createObjectURL(avatar)} alt="" />
                    </>
                  ) : (
                    <ClinicPlaceholderSVG />
                  )}
                  <S.AvatarHelper>
                    Your new photo must be in JPEG, or PNG format before you upload this,
                    <S.TextHelper>max size 50MB</S.TextHelper>
                  </S.AvatarHelper>
                  <input
                    ref={inputRef}
                    type="file"
                    id="file"
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg"
                    hidden
                  ></input>
                </S.AvatarZone>
              </label>
              {avatar && (
                <S.DeleteCircle onClick={handleClearImg}>
                  <CloseSVG />
                </S.DeleteCircle>
              )}
            </S.AvatarClickZone>
          ) : clinicInfo.currentData?.logo ? (
            <img src={clinicInfo.currentData.logo.url} alt="" />
          ) : (
            <ClinicPlaceholderSVG />
          )}
        </S.AvatarDiv>

        <S.MainInfoWrapper>
          <S.Name>
            {isEditable ? (
              <>
                <S.InputWrapper>
                  <Input
                    isRequired
                    label="Clinic name"
                    id="clinicName"
                    name="clinicName"
                    error={!!errors.clinicName}
                    helperText={errors.clinicName}
                    onChange={handleChangeInput}
                    value={values.clinicName}
                  />
                </S.InputWrapper>
              </>
            ) : (
              clinicInfo.currentData?.name
            )}
          </S.Name>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChangeInput}
                  value={values.email}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <EmailIconSVG />
                  <S.BlockText>
                    <S.RowText>Email</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{clinicInfo.currentData?.email || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Phone"
                  id="phone"
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onChange={handleChangeInput}
                  value={values.phone}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <PhoneIconSVG />
                  <S.BlockText>
                    <S.RowText>Phone</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{clinicInfo.currentData?.phone[0] || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          {isEditable ? (
            <>
              <S.InfoRow>
                <S.InputWrapper>
                  <SelectInput
                    label="State"
                    isRequired
                    id="state"
                    name="state"
                    value={values.state}
                    error={!!errors.state}
                    helperText={errors.state}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setFieldError('state', '');
                      handleChange(e);
                    }}
                  >
                    {StatesUSA.map(state => (
                      <MenuItem key={state.name} value={state.name}>
                        <S.MenuItemContent>{state.name}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </SelectInput>
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="City"
                    id="city"
                    name="city"
                    error={!!errors.city}
                    helperText={errors.city}
                    onChange={handleChangeInput}
                    value={values.city}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Street"
                    id="street"
                    name="street"
                    error={!!errors.street}
                    helperText={errors.street}
                    onChange={handleChangeInput}
                    value={values.street}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Zipcode"
                    id="zipCode"
                    name="zipCode"
                    error={!!errors.zipCode}
                    helperText={errors.zipCode}
                    onChange={handleChangeInput}
                    value={values.zipCode}
                  />
                </S.InputWrapper>
              </S.InfoRow>
            </>
          ) : (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>ADDRESS</S.UpperArticle>
                <S.UpperInfoText>
                  {clinicInfo.currentData?.state +
                    ',' +
                    clinicInfo.currentData?.city +
                    ',' +
                    clinicInfo.currentData?.street +
                    ',' +
                    clinicInfo.currentData?.zipCode}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          )}

          {isEditable ? (
            <>
              <S.InfoRow>
                <S.InputWrapper>
                  <S.Label>
                    <NotificationSmallSVG />
                    Automatic notification
                  </S.Label>
                  <S.SwitchCustomWrapper>
                    <SwitchCustom
                      checked={values.isEventNotificationsEnabled}
                      value={values.isEventNotificationsEnabled}
                      onChange={handleChange}
                      id="isEventNotificationsEnabled"
                      name="isEventNotificationsEnabled"
                    />
                  </S.SwitchCustomWrapper>
                </S.InputWrapper>
              </S.InfoRow>
            </>
          ) : (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>AUTOMATIC NOTIFICATION</S.UpperArticle>
                <S.UpperInfoText>
                  {clinicInfo.currentData?.isEventNotificationsEnabled ? 'On' : 'Off'}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          )}

          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="NPI"
                  id="npi"
                  name="npi"
                  error={!!errors.npi}
                  helperText={errors.npi}
                  onChange={handleChangeInput}
                  value={values.npi}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.Row>
                  <S.BlockRow>
                    <S.UpperArticle>NPI</S.UpperArticle>
                    <S.UpperInfoText>
                      {clinicInfo.currentData?.npi || '-'}
                    </S.UpperInfoText>
                  </S.BlockRow>
                </S.Row>
              </>
            )}
          </S.InfoRow>

          {isEditable ? (
            <S.PrefixMainWrapper>
              <S.Row>
                <S.BlockRow>
                  <S.UpperArticle>DEFAULT PREFIX</S.UpperArticle>
                  <S.UpperInfoText>
                    {
                      clinicInfo.currentData?.prefixes.find(
                        item => item.isDefault === true,
                      )?.value
                    }
                  </S.UpperInfoText>
                </S.BlockRow>
              </S.Row>

              <S.Row>
                <S.InputWrapper className="prefix">
                  <S.UpperArticle>ADDITIONAL PREFIX</S.UpperArticle>
                  <S.PrefixLabel>
                    <S.Btn
                      onClick={() =>
                        setNewPrefixes(prev => [
                          ...prev,
                          {
                            id: String(counter) + 'temp',
                            value: '',
                            isDefault: false,
                          },
                        ])
                      }
                    >
                      <AddRectSVG />
                      Add prefix
                    </S.Btn>
                  </S.PrefixLabel>
                </S.InputWrapper>
              </S.Row>
              <S.PrefixItems>
                {newPrefixes.map((prefix, index) => {
                  return (
                    <S.PrefixWrap key={prefix.id + index}>
                      <Input
                        label={`Prefix ${index + 1}`}
                        value={prefix.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChangePrefix(e.target.value, index)
                        }
                      >
                        {prefix.value}
                      </Input>
                      <S.PrefixRB onClick={() => handleDeletePrefix(index)}>
                        <CloseBtnSVG />
                      </S.PrefixRB>
                    </S.PrefixWrap>
                  );
                })}
              </S.PrefixItems>
            </S.PrefixMainWrapper>
          ) : (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>PREFIX</S.UpperArticle>
                <S.UpperInfoText>
                  {
                    clinicInfo.currentData?.prefixes.find(item => item.isDefault === true)
                      ?.value
                  }{' '}
                  <S.PrefixSpan>*</S.PrefixSpan>
                </S.UpperInfoText>
                {role === Roles.SUPER_ADMIN &&
                  Roles.BILLING &&
                  clinicInfo.currentData?.prefixes.map(
                    item =>
                      item.isDefault === false && (
                        <S.UpperInfoText key={item.id}>{item.value}</S.UpperInfoText>
                      ),
                  )}
              </S.BlockRow>
            </S.Row>
          )}

          <S.Row>
            <S.BlockRow>
              <S.UpperArticle>CREATED</S.UpperArticle>
              <S.UpperInfoText>
                <S.ReceptionistName>on </S.ReceptionistName>
                {dayjs(clinicInfo.currentData?.createdAt.slice(0, 10)).format(
                  'MM/DD/YYYY',
                ) || '-'}
              </S.UpperInfoText>
            </S.BlockRow>
          </S.Row>
          <S.Row>
            <S.BlockRow>
              <S.UpperArticle>UPDATED</S.UpperArticle>
              <S.UpperInfoText>
                <S.ReceptionistName>on </S.ReceptionistName>
                {dayjs(clinicInfo.currentData?.updatedAt.slice(0, 10)).format(
                  'MM/DD/YYYY',
                ) || '-'}
              </S.UpperInfoText>
            </S.BlockRow>
          </S.Row>
        </S.MainInfoWrapper>
      </form>
    </S.Wrapper>
  );
};

export default ClinicProfileInformation;
