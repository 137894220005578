import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  titleText?: string;
  fullTitle?: string;
  helperText?: string;
};

const ConfirmDeleteDialog: React.FC<Props> = ({
  onClose,
  titleText,
  onSuccess,
  fullTitle,
  helperText,
}) => {
  const handleConfirmClick = () => {
    onClose();
    onSuccess();
  };
  return (
    <S.Content>
      <S.Article>
        {fullTitle ? fullTitle : `Are you sure want to delete this ${titleText} ?`}
      </S.Article>
      {helperText && (
        <S.HelperText>
          <S.Span>NOTE: </S.Span>
          {helperText}
        </S.HelperText>
      )}
      <S.Buttons>
        <S.ButtonWrapper>
          <Button text="No" onClick={onClose} backgroundColor="#C66060" />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button text="Yes" onClick={handleConfirmClick} />
        </S.ButtonWrapper>
      </S.Buttons>
    </S.Content>
  );
};

export default ConfirmDeleteDialog;
