import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { ClaimValues, ProviderInfoFormType } from 'types/AppointmentNewTypes';
import { RenderingCard } from './RenderingCard';
import { ServiceCard } from './ServiceCard';
import { BillingCard } from './BillingCard';

import * as S from '../../styles';
import {
  providerClaimForm,
  providerClaimSchema,
} from 'pages/Billing/components/Claims/formikClaim';
import { ClaimActionEnum } from '../../ClaimForm';

type Props = {
  data?: ClaimValues;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  setIsDirty: (isDirty: boolean) => void;
};

export const ProviderBlock = memo(
  ({ data, getData, isSave, setIsSave, setIsDirty }: Props) => {
    const providerFormik = useFormik<Partial<ProviderInfoFormType>>({
      initialValues: providerClaimForm(data),
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: providerClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await providerFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(providerFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (providerFormik.dirty) {
        setIsDirty(true);
      }
    }, [providerFormik.dirty]);

    return (
      <S.BlockWrapper>
        <S.CardWrapper>
          <RenderingCard providerFormik={providerFormik} />
          <ServiceCard providerFormik={providerFormik} />
          <BillingCard providerFormik={providerFormik} />
        </S.CardWrapper>
      </S.BlockWrapper>
    );
  },
);
