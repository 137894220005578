import { TemplateNameType, TemplateType } from '../../../../types/TemplateType';

export const getCorrectTemplateName = (selectedItem: TemplateNameType) => {
  switch (selectedItem) {
    case TemplateNameType.CLIENT:
      return 'Forms';
    case TemplateNameType.DIGITAL:
      return 'Digital Form';
    case TemplateNameType.PDF:
      return 'PDF Form + Sign';
    default:
      return;
  }
};

export const getRouteTemplate = (row: TemplateType) => {
  switch (row.type) {
    case TemplateNameType.CLIENT:
      return '/templates';
    case TemplateNameType.DIGITAL:
      if (row.name === 'Client Registration Form') {
        return '/templates/client-registration-form';
      }
      if (row.name === 'Acupuncture Insurance Inquiry Questionnaire') {
        return '/templates/acupuncture-insurance';
      }
      if (row.name === 'PT Initial Evaluation') {
        return '/templates/pt-initial-evaluation';
      }
      if (row.name === 'Initial visit form') {
        return '/templates/initial-visit-form';
      }
      if (row.name === 'Physical Therapy Insurance Inquiry Questionnaire') {
        return '/templates/physical-therapy-insurance';
      } else return;
    case TemplateNameType.PDF:
      return '/templates';
    default:
      return '/templates';
  }
};
