import styled from 'styled-components';
import { AccessLevel } from '../../../../../../types/enums/AppointmentEnum';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
`;

export const CellWrapper = styled.div<{ editable?: AccessLevel }>`
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px 0px;
  max-width: 350px;
  word-break: break-all;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  user-select: none;
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }

  &.min {
    min-width: 80px;
  }

  &.cursor {
    cursor: auto;
  }
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Loader = styled.div``;

export const PaginationText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonShow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 11px;

  svg {
    circle {
      stroke: #0084b1;
    }
    path {
      stroke: #0084b1;
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #4570de;
      }
      path {
        stroke: #4570de;
      }
    }
  }
`;

export const Text = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    color: #4570de;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const Link = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    color: #4570de;
  }

  &.simply {
    cursor: default;
    &:hover {
      color: #0084b1;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Arrows = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;

  & svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    stroke: #848a9b;
    opacity: 1;

    &.disabled {
      stroke: #bfc2cb;
      pointer-events: none;
    }
  }
`;
