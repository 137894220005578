import { Tab } from '@mui/material';
import { TabsItem } from 'components';
import * as S from './styles';
import { useState } from 'react';
import ClaimHistoryMessages from './components/ClaimHistoryMessages';
import ClaimHistoryEvents from './components/ClaimHistoryEvents';

type Props = {
  isShow: boolean;
  claimId: string;
};

const ClaimHistory = ({ isShow, claimId }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <S.Wrapper $isShow={isShow}>
      {isShow && (
        <S.Content>
          <S.Header>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="Messages"></Tab>
              <Tab disableRipple label="Events"></Tab>
              {/*<Tab disableRipple label="Related"></Tab>*/}
            </TabsItem>
          </S.Header>
          <S.Body>
            {tabIndex === 0 && <ClaimHistoryMessages claimId={claimId} />}
            {tabIndex === 1 && <ClaimHistoryEvents claimId={claimId} />}
          </S.Body>
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default ClaimHistory;
