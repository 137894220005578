import { useDispatch } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import * as S from './styles';
import SchedBtn from './components/SchedBtn';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  StaffSchedule,
  useStaffScheduleMarkWorkDayMutation,
  useStaffScheduleOneGetQuery,
} from '../../../store/api/staff-schedule/staffScheduleApi';
import CheckBox from '../../atoms/CheckBox';
import {
  AddClinicIconSVG,
  ApproveBtnSVG,
  EditBtnSVG,
  ResetApproveBtnSVG,
} from '../../../assets/icons';
import Button from '../../atoms/Button';
import SchedRecord from './components/SchedRecord';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LinearLoader from '../../atoms/LinearLoader';
import { Dialog } from '../..';
import NewSchedDialog from './components/NewSchedDialog';
import { Clinics } from '../../../types/ClinicTypes';

type Props = {
  staffId?: string;
  assignClinics?: Array<Clinics>;
};

export type ScheduleValuesProps = {
  isWorkDay: boolean;
  clinicId: string;
  workingHoursStart: string;
  workingHoursEnd: string;
};

const WorkSchedule: React.FC<Props> = ({ staffId, assignClinics }) => {
  const dispatch = useDispatch();

  const [choosedWeekDay, setChoosedWeekDay] = useState(0);
  const userInfo = useAppSelector(state => state.auth);

  const getSchedule = useStaffScheduleOneGetQuery({
    doctorId: staffId ? staffId : userInfo.id,
  });

  const getSchedOneDay = useStaffScheduleOneGetQuery({
    doctorId: staffId ? staffId : userInfo.id,
    weekDay: choosedWeekDay,
  });

  const [markWorkDay] = useStaffScheduleMarkWorkDayMutation({});

  const [isMyWorkDay, setIsMyWorkDay] = useState(false);

  const textWeekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const [currentSched, setCurrentSched] = useState<Array<StaffSchedule>>([]);

  const Shape = yup.object().shape({});

  const formik = useFormik<ScheduleValuesProps>({
    initialValues: {
      isWorkDay: false,
      clinicId: '',
      workingHoursStart: '',
      workingHoursEnd: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: async () => {
      //console.log(values);
    },
  });

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;

  useEffect(() => {
    setCurrentSched([]);
    getSchedOneDay.currentData?.rows.map((oneDay: StaffSchedule, index) => {
      setCurrentSched((prevState: Array<StaffSchedule>) => [...prevState, oneDay]);
    });
    if (getSchedOneDay.currentData?.rows[0]?.isWorkDay === true) {
      setFieldValue('isWorkDay', true);
    } else {
      setFieldValue('isWorkDay', false);
    }
  }, [getSchedOneDay]);

  const handleOffWorkDay = (e: ChangeEvent<HTMLInputElement>) => {
    markWorkDay({
      doctorId: staffId ? staffId : userInfo.id,
      weekDay: choosedWeekDay,
    });
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
    // resetForm();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <NewSchedDialog
          staffId={staffId}
          assignClinics={assignClinics}
          formik={formik}
          choosedWeekDay={choosedWeekDay}
          onClose={handleClose}
          todayList={currentSched}
        />
      </Dialog>
      {(getSchedOneDay.isLoading ||
        getSchedOneDay.isFetching ||
        getSchedule.isLoading ||
        getSchedule.isFetching) && <LinearLoader />}
      <S.Wrapper>
        <S.EditBtnWrapper>
          <S.Article>Schedule</S.Article>
        </S.EditBtnWrapper>
        <S.SubArticle>Here you can edit your own schedule </S.SubArticle>
        <S.SchedLine>
          {textWeekDays.map((item, index) => {
            const res = getSchedule?.currentData?.rows?.find((row: StaffSchedule) => {
              return index === row.weekDay;
            });

            return (
              <S.SchedBtnWrapper
                disabled={
                  getSchedOneDay.isFetching || getSchedOneDay.isLoading ? true : false
                }
                key={item}
                onClick={() => setChoosedWeekDay(index)}
              >
                <SchedBtn
                  disabled={
                    getSchedOneDay.isFetching || getSchedOneDay.isLoading ? true : false
                  }
                  text={item}
                  active={choosedWeekDay === index}
                  isWorkDay={res && res.isWorkDay ? true : false}
                />
              </S.SchedBtnWrapper>
            );
          })}
        </S.SchedLine>
        <S.Content>
          <S.AddClinicBtnWrapper>
            <S.LeftBlock>
              <S.Article14>Clinics</S.Article14>
              <S.SubArticle>
                Here you can choose several clinics. To make this day active, please add a
                clinic
              </S.SubArticle>
            </S.LeftBlock>
            <S.AddClinicBtn>
              <Button text="Add Clinic" onClick={handleClose}>
                <AddClinicIconSVG />
              </Button>
            </S.AddClinicBtn>
          </S.AddClinicBtnWrapper>
          {currentSched?.length > 0 && (
            <S.ArticleWrap>
              <S.Article>Is this your work day?</S.Article>
              <CheckBox
                id="isWorkDay"
                name="isWorkDay"
                checked={values.isWorkDay}
                onChange={handleOffWorkDay}
              />
            </S.ArticleWrap>
          )}

          {currentSched.map((oneDay: StaffSchedule, index) => {
            return (
              <SchedRecord
                staffId={staffId}
                assignClinics={assignClinics}
                key={oneDay.id}
                dayInfo={oneDay}
                index={index}
                todayList={currentSched}
                $active={values.isWorkDay}
                formik={formik}
                // isEditable={isEditable}
              />
            );
          })}
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default WorkSchedule;
