import { useState } from 'react';
import { Dialog, SuccessDialog } from '../../../../../..';
import {
  DeleteAppointmentSVG,
  DuplicateSVG,
  EditBtnSVG,
} from '../../../../../../../assets/icons';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { showDrawer } from '../../../../../../../store/slices/drawerSlice';
import { AppointmentsNew, Visits } from '../../../../../../../types/AppointmentNewTypes';
import { DrawerMode } from '../../../../../../../types/DrawerMode';
import CheckBox from '../../../../../../atoms/CheckBox';
import ConfirmDeleteDialog from '../../../../../../molecules/ConfirmDeleteDialog';
import AppointmentCard from '../AppointmentCard';
import * as S from './styles';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
} from '../../../../../../../store/api/appointment/appointmentApi';
import { setMessage } from '../../../../../../../store/slices/message';
import { AppointmentStatus } from '../../../../../../../types/StatusTypes';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { Roles } from '../../../../../../../types/Roles';
import CreateExistingClaimDialog from '../CreateExistingClaimDialog';
import { Fade, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useClaimManualCreateMutation } from '../../../../../../../store/api/claims/claimsApi';
import { transformReasonText } from '../../../../../../../helpers/functions/getAppointmentReasonName';

type Props = {
  appointment: AppointmentsNew;
  time: string;
  visit: Visits;
};

const PlannerRecord: React.FC<Props> = ({ appointment, time, visit }) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.auth);

  const [openDialog, setOpenDialog] = useState(false);

  const [openCreateClaimDialog, setOpenCreateClaimDialog] = useState(false);

  const [openExistingClaim, setOpenExistingClaim] = useState(false);

  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [deleteVisit] = useDeleteVisitServiceMutation({});

  const [createManual] = useClaimManualCreateMutation({});

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleShowExisting = () => {
    setOpenCreateClaimDialog(!openCreateClaimDialog);
    setOpenExistingClaim(!openExistingClaim);
  };

  const handleDelete = () => {
    appointment.visits.length > 1
      ? deleteVisit({ id: visit.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Service was successfully deleted from appointment',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          })
      : deleteAppointment({ id: appointment.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Appointment was successfully deleted',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
  };

  return (
    <S.RecordItem>
      <Dialog
        open={openCreateClaimDialog}
        onClose={() => setOpenCreateClaimDialog(!openCreateClaimDialog)}
      >
        <SuccessDialog
          onClose={() => setOpenCreateClaimDialog(!openCreateClaimDialog)}
          titleText="Create manual Claim"
          helperText={`Do you want to create ${
            appointment.authSigs
          } manual claim, covered service: ${
            transformReasonText(appointment.mainVisitReason).text
          } ?`}
          onSuccess={() => {
            createManual({
              appointmentId: appointment.id,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Manual claim was successfully created',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              });
          }}
          successText="Yes"
        />
      </Dialog>
      {/* <Dialog
        open={openExistingClaim}
        onClose={() => setOpenExistingClaim(!openExistingClaim)}
      >
        <CreateExistingClaimDialog
          appointment={appointment}
          onClose={() => setOpenExistingClaim(!openExistingClaim)}
        />
      </Dialog> */}
      <S.BtnColumn>
        {appointment.status === AppointmentStatus.PENDING && (
          <>
            <Dialog open={openDialog} onClose={handleChangeDialog}>
              <ConfirmDeleteDialog
                onClose={handleChangeDialog}
                fullTitle={
                  appointment.visits.length > 1
                    ? `Are you sure want to delete current service from appointment #${appointment.id.slice(
                        0,
                        8,
                      )}?`
                    : 'Are you sure you want to delete the entire appointment?'
                }
                helperText={
                  appointment.visits.length > 1
                    ? 'You will delete only one current service from appointment'
                    : 'You will delete the entire appointment and lose progress'
                }
                onSuccess={handleDelete}
              />
            </Dialog>
            <DeleteAppointmentSVG onClick={handleChangeDialog} />
          </>
        )}
      </S.BtnColumn>
      {(userInfo.role === Roles.BILLING ||
        userInfo.role === Roles.LOCATION_MANAGER ||
        userInfo.role === Roles.SUPER_ADMIN) && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#0084B1',
                fontFamily: 'Inter',
              },
            },
          }}
          title={
            !!appointment.createManualClaimDate
              ? `MANUAL: ${appointment.countManualClaims}, ${dayjs(
                  appointment.createManualClaimDate,
                ).format('MM/DD/YYYY h:mm A')}`
              : ''
          }
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: 300,
          }}
        >
          <S.BtnColumn
            className="signatures"
            onClick={() => {
              appointment.countManualClaims === 0 &&
                appointment.status === AppointmentStatus.COMPLETED &&
                setOpenCreateClaimDialog(!openCreateClaimDialog);
            }}
          >
            {appointment.isCheckBilling && userInfo.role === Roles.BILLING && (
              <CheckBox checked={appointment.countManualClaims > 0 ? true : false} />
            )}
          </S.BtnColumn>
        </Tooltip>
      )}
      <S.BtnColumn>
        <DuplicateSVG
          onClick={() =>
            dispatch(
              showDrawer({
                show: true,
                mode: DrawerMode.DUPL_APPOINTMENT,
                props: appointment,
              }),
            )
          }
        />
      </S.BtnColumn>
      <AppointmentCard appointment={appointment} time={time} visit={visit} />
    </S.RecordItem>
  );
};

export default PlannerRecord;
