import { useDispatch } from 'react-redux';
import * as S from './styles';
import { Button, DatePicker, Input } from '../../../../../../components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Chip, IconButton, InputAdornment, TextField } from '@mui/material';
import { EyeVisibleSVG } from '../../../../../../assets/icons';
import {
  useCashboxCalculateMutation,
  useCashboxUpdateMutation,
} from '../../../../../../store/api/cash/cashboxApi';
import { setMessage } from '../../../../../../store/slices/message';
import AutoCompleteInput from '../../../../../../components/atoms/AutoComplete';
import { useUsersGetAllQuery } from '../../../../../../store/api/user/profileApi';
import { useDebounce } from 'use-debounce';
import { usePatientsGetQuery } from '../../../../../../store/api/clinics/clinicApi';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import dayjs, { Dayjs } from 'dayjs';
import { isDate } from 'lodash';

type Props = {
  onClose: () => void;
};

const CalculateDialog: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();

  const [calculate] = useCashboxCalculateMutation({});

  const [startDate, setStartDate] = useState<string | Dayjs | null>(null);
  const [endDate, setEndDate] = useState<string | Dayjs | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const rowData = usePatientsGetQuery({
    q: debouncedSearchValue,
    id: selectedClinic.id,
    page: 1,
  });

  const disableButton = useMemo(
    () =>
      !startDate || !endDate || !dayjs(startDate).isValid() || !dayjs(endDate).isValid(),
    [startDate, endDate],
  );

  const handleCalculate = () => {
    calculate({
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      clinicId: selectedClinic.id,
      ...(selectedValue && { patientId: selectedValue.userId }),
    })
      .unwrap()
      .then(res => {})
      .catch(error => {
        dispatch(
          setMessage({
            message: 'Data for report not found',
            type: 'error',
          }),
        );
      });
    onClose();
  };

  return (
    <S.Content>
      <S.Article>Calculate </S.Article>
      <AutoCompleteInput
        data={rowData?.currentData?.rows || []}
        mainLabel={'Username'}
        loading={rowData.isFetching || rowData.isLoading ? true : false}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
      <S.InputRow>
        <DatePicker
          // error={!dayjs(startDate).isValid()}
          label="from"
          value={startDate}
          shouldDisableDate={date => {
            if (endDate && date > endDate) {
              return true;
            } else {
              return false;
            }
          }}
          onChange={value => setStartDate(value)}
        />
        <DatePicker
          // error={!dayjs(endDate).isValid()}
          shouldDisableDate={date => {
            if (startDate && date < startDate) {
              return true;
            } else {
              return false;
            }
          }}
          label="to"
          value={endDate}
          onChange={value => setEndDate(value)}
        />
      </S.InputRow>
      <S.ButtonWrapper>
        <Button text="Calculate" onClick={handleCalculate} disabled={disableButton} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default CalculateDialog;
