import dayjs from 'dayjs';
import { AvatarSVG, FileDownloadSVG } from '../../../assets/icons';
import * as S from './styles';
import { MessageType } from '../../../types/ChatTypes';
import { FileDownload } from '@mui/icons-material';
import { getFileName } from '../../../helpers/functions/fileName';
import prettyBytes from 'pretty-bytes';

type Props = {
  isMine: boolean;
  text: string;
  msg: MessageType;
};

const MessageItem: React.FC<Props> = ({ isMine, text, msg }) => {
  return (
    <S.MessageWrapper $isMine={isMine}>
      <S.Content $isMine={isMine}>
        <S.Avatar>
          {msg.user?.avatar ? (
            <img src={msg.user.avatar?.url} alt="ava" />
          ) : (
            <AvatarSVG />
          )}
        </S.Avatar>
        <S.TextWrapper $isMine={isMine}>
          <S.UserInfo $isMine={isMine}>
            <S.UserInfoText>
              <S.Name>
                {msg.user?.profile?.firstName + ' ' + msg.user?.profile?.lastName}
              </S.Name>
              • {msg?.user?.role}
            </S.UserInfoText>
            <S.TimeText>{dayjs(msg.createdAt).format('MM/DD/YYYY h:mm A')}</S.TimeText>
          </S.UserInfo>
          {text && (
            <S.MessageText $isMine={isMine}>
              <S.Text>{text}</S.Text>
            </S.MessageText>
          )}
          {msg.messageFile.length > 0 && (
            <S.Files $isMine={isMine}>
              {msg.messageFile?.map(msgFile => {
                return (
                  <S.FileWrapper
                    key={msgFile.id}
                    $isMine={isMine}
                    onClick={() => {
                      window.open(msgFile.url);
                    }}
                  >
                    <S.InfoSide>
                      <S.FileArticle>{getFileName(msgFile.name)}</S.FileArticle>
                      <S.Size>{prettyBytes(Number(msgFile.size))}</S.Size>
                    </S.InfoSide>
                    <S.IconSide>
                      <FileDownloadSVG />
                    </S.IconSide>
                  </S.FileWrapper>
                );
              })}
            </S.Files>
          )}
        </S.TextWrapper>
      </S.Content>
    </S.MessageWrapper>
  );
};

export default MessageItem;
