import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVER_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  paramsSerializer: (params: Record<string, unknown>) =>
    queryString.stringify(params, { arrayFormat: 'none' }),
  prepareHeaders: headers => {
    headers.set('ngrok-skip-browser-warning', '*');
    return headers;
  },
});

export const api = createApi({
  baseQuery,
  tagTypes: [
    'Appointments',
    'User',
    'SearchUsers',
    'Files',
    'CashBox',
    'Notes',
    'Tickets',
    'Schedule',
    'Events',
    'Staff',
    'Clinics',
    'Templates',
    'Coupons',
    'HealthRecords',
    'Survey',
    'ClinicSchedule',
    'Notifications',
    'Me',
    'Dashboard',
    'Billing',
    'Invoice',
    'StaffInsurances',
    'Mail',
    'Documents',
    'Progress',
    'Payer',
    'Claims',
    'Reports',
  ],
  endpoints: builder => ({}),
  keepUnusedDataFor: 30,
});
