import { useState } from 'react';
import { ArrowLSVG, ArrowRSVG } from 'assets/icons';
import { CustomTable, SearchInput, SelectInput } from 'components';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useInvoiceListGetQuery } from 'store/api/invoices/invoiceApi';
import InvoiceRow from './TableData/InvoiceRow';
import { MenuItem, SelectChangeEvent } from '@mui/material';

const headers = [
  { name: 'Invoice Number', key: 'InvoiceNumber' },
  { name: 'Name', key: 'Name' },
  { name: 'Invoice type', key: 'InvoiceType' },
  { name: 'Charge amount', key: 'ChargeAmount' },
  { name: 'Status', key: 'Status' },
  { name: 'Appointments #', key: 'VisitReason' },
];

const INVOICE_SHEET_LIMIT = 20;

const InvoiceSheet = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('');

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(INVOICE_SHEET_LIMIT);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + INVOICE_SHEET_LIMIT);
    setSecondCount(secondCount + INVOICE_SHEET_LIMIT);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - INVOICE_SHEET_LIMIT);
    setSecondCount(secondCount - INVOICE_SHEET_LIMIT);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useInvoiceListGetQuery({
    clinicId: selectedClinic.id,
    q: debouncedSearchValue,
    page: page,
    take: INVOICE_SHEET_LIMIT,
    ...(status !== '' && { status: status }),
  });

  const handleShowCount = () => {
    if (getList?.currentData?.count && getList.currentData.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {getList?.currentData?.count && getList.currentData.count < secondCount
            ? getList.currentData.count
            : secondCount}{' '}
          of {getList?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    } else {
      return (
        <S.PaginationText>
          Showing 0 of {getList?.currentData?.count} invoices{' '}
        </S.PaginationText>
      );
    }
  };

  return (
    <S.TableBody>
      <S.HeaderTable>
        <S.PaginationWrapper>
          <S.Arrows>
            <ArrowLSVG
              onClick={handleBack}
              className={getList.currentData?.page === 1 ? 'disabled' : ''}
            />
            <ArrowRSVG
              onClick={handleForward}
              className={
                getList.currentData?.page === getList.currentData?.lastPage
                  ? 'disabled'
                  : ''
              }
            />
          </S.Arrows>
          {getList.isFetching ? <div>...</div> : handleShowCount()}
        </S.PaginationWrapper>
        <S.SubtitleItems>
          <S.SelectWrap>
            <SelectInput
              label="Status"
              value={status}
              onChange={(e: SelectChangeEvent<unknown>) =>
                setStatus(e.target.value as string)
              }
            >
              <MenuItem value={''}>
                <S.MenuItemRow>All</S.MenuItemRow>
              </MenuItem>
              <MenuItem value={'in_progress'}>
                <S.MenuItemRow>In progress</S.MenuItemRow>
              </MenuItem>
              <MenuItem value={'completed'}>
                <S.MenuItemRow>Completed</S.MenuItemRow>
              </MenuItem>
            </SelectInput>
          </S.SelectWrap>
          <S.SearchWrapper>
            <SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search by username"
            />
          </S.SearchWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching || getList.isLoading}>
        <InvoiceRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default InvoiceSheet;
