import { useState } from 'react';
import { Drawer, TabsItem } from '../../../../components';
import { Tab } from '@mui/material';
import Login from './Login';
import SignUp from './SignUp';
import logo from '../../../../assets/images/logo.png';
import * as S from './styles';
import * as AuthS from '../../styles';
import ContactACA from '../ContactACA';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { showDrawer } from '../../../../store/slices/drawerSlice';
import AuthDrawerContent from '../AuthDrawerContent';
import { DrawerMode } from '../../../../types/DrawerMode';

const Credentials = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const isShowDrawer = useAppSelector(state => state.drawer.show);
  const dispatch = useDispatch();
  return (
    <>
      <AuthS.AuthWrapper>
        <AuthS.LoginBlock>
          <img className="logo" src={logo} alt="logotype"></img>
          <S.CredentialsWrapper>
            <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
              <Tab disableRipple label="Log In"></Tab>
              <Tab disableRipple label="Sign Up"></Tab>
            </TabsItem>
            {tabIndex === 0 && <Login />}
            {tabIndex === 1 && <SignUp />}
          </S.CredentialsWrapper>
        </AuthS.LoginBlock>
        <Drawer
          open={isShowDrawer}
          onClose={() =>
            dispatch(
              showDrawer({
                show: false,
                mode: DrawerMode.DEFAULT,
                props: null,
              }),
            )
          }
        >
          <AuthDrawerContent />
        </Drawer>
        <ContactACA />
      </AuthS.AuthWrapper>
    </>
  );
};

export default Credentials;
