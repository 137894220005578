import dayjs from 'dayjs';
import { EditBtnMinimalSVG } from '../../../../../../assets/icons';
import {
  Button,
  Dialog,
  LinearLoader,
  MultiDatePicker,
} from '../../../../../../components';
import ClinicWorkSchedulePlaceholder from './components/ClinicWorkSchedulePlaceholder';
import * as S from './styles';
import { getDay } from '../../../../../../helpers/CalendarHelpers/getDay';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { handleChangeDate } from '../../../../../../helpers/CalendarHelpers/handleDates';
import ClinicScheduleCard from './components/ClinicScheduleCard';
import ClinicScheduleDialog from './components/ClinicScheduleDialog';
import {
  useClinicScheduleCheckWorkingTimeQuery,
  useClinicScheduleGetQuery,
  useClinicScheduleWeekendsQuery,
} from '../../../../../../store/api/clinic-schedule/clinicScheduleApi';
import { useParams } from 'react-router-dom';

const ClinicWorkSchedule = () => {
  const [selectedDate, setSelectedDate] = useState<DateObject[]>(
    getDay(new DateObject()),
  );

  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleShowDialog = () => {
    setIsShowDialog(!isShowDialog);
  };

  const params = useParams();

  const getSchedule = useClinicScheduleGetQuery({
    id: params.clinicId,
  });

  const [currentMonth, setCurrentMonth] = useState<DateObject>(new DateObject());

  useEffect(() => {
    setCurrentMonth(selectedDate[0]);
  }, [selectedDate]);

  const weekends = useClinicScheduleWeekendsQuery({
    clinicId: params.clinicId,
    date: currentMonth.format('YYYY-MM-01'),
  });

  return (
    <S.Wrapper>
      <Dialog open={isShowDialog} onClose={handleShowDialog}>
        <ClinicScheduleDialog onClose={handleShowDialog} />
      </Dialog>
      <S.ArticleBtnsWrapper>
        <S.ArticleWrapper>
          <S.Article>Schedule Settings</S.Article>
          <S.HelperText>
            Here you can set the settings for the schedule of the entire clinic.
          </S.HelperText>
        </S.ArticleWrapper>
        <S.ButtonWrapper>
          <Button text="Change settings" onClick={handleShowDialog}>
            <EditBtnMinimalSVG />
          </Button>
        </S.ButtonWrapper>
      </S.ArticleBtnsWrapper>
      {/* {weekends.isFetching || weekends.isLoading ? (
        <LinearLoader />
      ) : ( */}
      <S.Content>
        <S.DayPickerWrapper>
          <MultiDatePicker
            disabled={weekends.isLoading || weekends.isFetching}
            disabledClick={weekends.isLoading || weekends.isFetching}
            multiple={false}
            value={selectedDate}
            onMonthChange={date => setCurrentMonth(date)}
            onChange={(value: any) => {
              handleChangeDate('Day', setSelectedDate, value);
            }}
            mapDays={({ date }) => {
              let color;
              if (
                weekends.currentData &&
                weekends.currentData.map((item: string) => {
                  return (
                    dayjs(item.slice(0, 10)).format('MM/DD/YYYY') ===
                      date.format('MM/DD/YYYY') && (color = '#C66060')
                  );
                })
              )
                if (color) return { style: { color: '#C66060' }, className: 'colored' };
                else return;
            }}
          />
        </S.DayPickerWrapper>
        {getSchedule.currentData?.count ? (
          <ClinicScheduleCard date={selectedDate} />
        ) : (
          <ClinicWorkSchedulePlaceholder />
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default ClinicWorkSchedule;
