import * as S from './styles';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import StatisticHeaderBar from './components/StatisticHeaderBar';
import AveragePatientVisits from './components/AveragePatientVisits';
import DonutChart from '../../../../components/atoms/DonutChart';
import TicketsCount from './components/TicketsCount/TicketsCount';
import { ScrollContainer } from '../../../../components';
import Invoices from './components/Invoices';
import DashVisitReasons from './components/DashVisitReasons';
import DashCash from './components/DashCash';
import Masseur from './components/Masseur';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Roles } from '../../../../types/Roles';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.auth);

  return (
    <S.DashboardWrapper>
      <S.SubtitleBar>
        <S.Article>Dashboard</S.Article>
        <S.SubtitleItems></S.SubtitleItems>
      </S.SubtitleBar>
      <S.SubHeader>
        <S.SelectWrapper></S.SelectWrapper>
      </S.SubHeader>
      <S.ScrollWrapper>
        <S.Content>
          {/* <StatisticHeaderBar />
          <AveragePatientVisits />
          <S.RowCards>
            <TicketsCount />
            <Invoices />
            <Masseur />
          </S.RowCards> 
          <DashVisitReasons />
          {userInfo.role !== Roles.SUPER_ADMIN && <DashCash />} */}
        </S.Content>
      </S.ScrollWrapper>
    </S.DashboardWrapper>
  );
};

export default Dashboard;
