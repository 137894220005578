import { FormikProps } from 'formik';
import AutoCompleteInput from '../../../../../atoms/AutoComplete';
import Input from '../../../../../atoms/Input';
import { AppointmentFormValues } from '../../../AppointmentDrawer';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { AppointmentsNew } from '../../../../../../types/AppointmentNewTypes';
import { ChangeEvent, useEffect, useState } from 'react';
import { useUserDeepSearchMutMutation } from '../../../../../../store/api/searchApi/searchApi';
import { useDebounce } from 'use-debounce';
import OptionContent from '../OptionContent';
import ReactInputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import { OptionType } from '../OptionContent/OptionContent';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
  selectedValue: any;
  setSelectedValue: React.Dispatch<any>;
};

const Phone: React.FC<Props> = ({
  formik,
  byClick,
  userProfile,
  selectedAppointment,
  duplAppointment,
  selectedValue,
  setSelectedValue,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const [allPatientsGet, allPatientsStatus] = useUserDeepSearchMutMutation({});

  const [data, setData] = useState([]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    if (
      !userProfile &&
      !duplAppointment &&
      (!selectedAppointment || selectedAppointment?.isPossibleToUpdate)
    ) {
      allPatientsGet({
        phone: debouncedSearchValue,
        page: 1,
      })
        .unwrap()
        .then(res => setData(res?.rows));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (selectedValue) {
      formik.setValues({
        ...formik.values,
        firstName: selectedValue.profile?.firstName || '',
        lastName: selectedValue.profile?.lastName || '',
        phone: selectedValue?.phone || '',
        email: selectedValue?.email || '',
        userId: selectedValue?.id || '',
        dateOfBirth: selectedValue.profile?.dateOfBirth || '',
      });
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!selectedValue) {
      formik.setFieldValue('phone', searchValue);
    }
    // console.log(selectedValue, searchValue);
  }, [searchValue]);

  return (
    <>
      {byClick ? (
        <AutoCompleteInput
          data={data || []}
          isRequired
          customRender={true}
          mainLabel={'Phone'}
          loading={allPatientsStatus.isLoading ? true : false}
          searchValue={searchValue}
          options={data || []}
          setSearchValue={setSearchValue}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          disabled={!!selectedValue || selectedAppointment?.isPossibleToUpdate === false}
          getOptionLabel={(option: any) =>
            !selectedValue ? formik.values.phone : option && option?.phone
          }
          renderOption={(props: any, option: any) => {
            return (
              <OptionContent
                type={OptionType.PHONE}
                searchValue={debouncedSearchValue}
                textToHighlight={option?.phone || ''}
                key={option.id}
                otherText={option.profile?.firstName || ''}
                props={props}
                option={option}
                loading={allPatientsStatus.isLoading}
              />
            );
          }}
        />
      ) : (
        <ReactInputMask
          mask="+19999999999"
          value={formik.values.phone}
          onChange={handleChangeInput}
          disabled={
            !!userProfile?.phone ||
            !!selectedAppointment?.patient?.phone ||
            !!duplAppointment?.patient?.phone ||
            !!selectedValue
          }
        >
          <Input
            label="Phone"
            id="phone"
            isRequired
            name="phone"
            error={!!formik.errors.phone}
            helperText={formik.errors.phone as string}
          />
        </ReactInputMask>
      )}
    </>
  );
};

export default Phone;
