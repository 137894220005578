import { Appointments, EventType } from '../../../types/AppointmentsTypes';
import { Treatment } from '../../../types/TreatmentType';
import { api } from '../api';

type Response<T> = {};

export const eventsApi = api.injectEndpoints({
  endpoints: builder => ({
    eventsGet: builder.query<Array<EventType>, any>({
      query: ({ ...data }) => ({
        url: `/event/all?sort=date&asc=DESC`,
        params: { ...data },
      }),
      providesTags: ['Appointments', 'Events'],
    }),
    eventsGetOne: builder.query<EventType, any>({
      query: ({ id, ...data }) => ({
        url: `/event/${id}`,
        params: { ...data },
      }),
      providesTags: ['Appointments', 'Events'],
    }),
    eventsUpdate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/event/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Appointments', 'Events', 'CashBox'],
    }),
  }),
});

export const { useEventsGetQuery, useEventsUpdateMutation, useEventsGetOneQuery } =
  eventsApi;
