import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  Drawer,
  HorizontalHeader,
  LinearLoader,
  Marketing,
  MenuSectionItem,
  SelectInput,
  VerticalMenu,
} from '../../components';
import { useAppSelector } from '../../hooks/useAppSelector';
import * as S from './styles';
import { showDrawer } from '../../store/slices/drawerSlice';
import { DrawerMode } from '../../types/DrawerMode';
import ChooseDrawerContent from '../../services/chooseDrawerContent';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setSelectedClinic } from '../../store/slices/selectedClinic';
import Appointments from '../../components/organismus/Appointments';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  AvatarSVG,
  CalendarSVG,
  CashSVG,
  ChatsSVG,
  ClinicMenuIconSVG,
  CreateNotificationSVG,
  DashboardSVG,
  MarketingSVG,
  StaffIconSVG,
  TasksSVG,
  TemplatesSVG,
  TicketSVG,
} from '../../assets/icons';
import { useClinicsGetQuery } from '../../store/api/clinics/clinicApi';
import Patients from '../Receptionist/components/Patients';
import PatientProfile from '../Receptionist/components/PatientProfile';
import Profile from '../Receptionist/components/Profile';

import Chats from '../Receptionist/components/Chats';
import Tickets from '../Receptionist/components/Tickets';

import Dashboard from '../Receptionist/components/Dashboard';
import { getChats } from '../../hooks/useChat';
import ProfileStaff from '../SuperAdmin/components/ProfileStaff';
import Clinics from '../SuperAdmin/components/Clinics';
import ProfileClinic from '../SuperAdmin/components/ProfileClinic';
import Templates from '../SuperAdmin/components/Templates';
import Staff from '../SuperAdmin/components/Staff';
import Cash from '../Receptionist/components/Cash';
import CreateNotificationDialog from '../../components/molecules/Dialogs/CreateNotificationDialog';
import { useLastSelectedClinicMutation } from '../../store/api/user/profileApi';
import AppointmentsView from '../../components/organismus/AppointmentsView';
import InitialVisitFormPage from '../SuperAdmin/components/Templates/components/pages/InitialVisitFormPage';
import PagePTInsurance from '../SuperAdmin/components/Templates/components/pages/PagePhysicalTherapyInsurance';
import PageACAInsurance from '../SuperAdmin/components/Templates/components/pages/PageACAInsurance';
import PagePTInitialConsultation from '../SuperAdmin/components/Templates/components/pages/PagePTInitialConsultation';
import PageClientRegistration from '../SuperAdmin/components/Templates/components/pages/ClientRegistrationPage';

type Props = {
  isLocalAdmin?: boolean;
};

const LocalAdmin: React.FC<Props> = ({ isLocalAdmin }) => {
  const drawerState = useAppSelector(state => state.drawer);
  const userInfo = useAppSelector(state => state.auth);

  const connected = useAppSelector(({ chats }) => chats.connected);
  const chats = useAppSelector(({ chats }) => chats.chats);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  useEffect(() => {
    if (connected) {
      getChats();
    }
  }, [connected]);

  const [clinic, setClinics] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation().pathname;
  const [changeSelectedClinic, status] = useLastSelectedClinicMutation();

  const clinics = useClinicsGetQuery({});

  useEffect(() => {
    if (!userInfo.selectedClinicId) {
      userInfo?.clinics && setClinics(userInfo?.clinics[0]?.id);
      userInfo?.clinics && dispatch(setSelectedClinic({ id: userInfo?.clinics[0]?.id }));
    } else {
      userInfo && setClinics(userInfo.selectedClinicId);
      userInfo && dispatch(setSelectedClinic({ id: userInfo.selectedClinicId }));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(setSelectedClinic({ id: clinic }));
  }, [clinic]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const routeList = [
    { article: 'Appointments', route: '/appointments', icon: <CalendarSVG /> },
    { article: 'Patients', route: '/patients', icon: <AvatarSVG /> },
    { article: 'Chats', route: '/chats', icon: <ChatsSVG /> },
    { article: 'Staff', route: '/staff', icon: <StaffIconSVG /> },
    {
      article: 'Clinic Info',
      route: `/clinics/${selectedClinic.id}`,
      icon: <ClinicMenuIconSVG />,
    },
    { article: 'Tickets', route: '/tickets', icon: <TasksSVG /> },
    { article: 'Dashboard', route: '/dashboard', icon: <DashboardSVG /> },
    { article: 'Marketing', route: '/marketing', icon: <MarketingSVG /> },
    { article: 'Templates', route: '/templates', icon: <TemplatesSVG /> },
    { article: 'Cash', route: '/cash', icon: <CashSVG /> },
  ];

  const { isHover } = useAppSelector(state => state.isHover);

  return (
    <>
      <Drawer
        open={drawerState.show}
        onClose={() =>
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }))
        }
      >
        <ChooseDrawerContent />
      </Drawer>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <CreateNotificationDialog onClose={handleChangeDialog} />
      </Dialog>

      <S.Wrapper>
        <HorizontalHeader isNotify={true} />
        {selectedClinic.id ? (
          <S.Content $isHover={isHover}>
            <Routes>
              <Route path="patients" element={<Patients />} />
              <Route path="patients/:patientId" element={<PatientProfile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="staff/:profileId" element={<ProfileStaff />} />
              {/* <Route path="clinics" element={<Clinics />} /> */}
              <Route path="clinics/:clinicId" element={<ProfileClinic />} />
              <Route path="chats" element={<Chats />} />
              <Route path="cash" element={<Cash />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="templates" element={<Templates />} />
              <Route path="marketing" element={<Marketing />} />
              <Route path="/appointments" element={<AppointmentsView />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="*" element={<Navigate to="/appointments" />} />
              {/* Routes forms */}
              <Route
                path="templates/initial-visit-form"
                element={<InitialVisitFormPage />}
              />
              <Route
                path="templates/physical-therapy-insurance"
                element={<PagePTInsurance />}
              />
              <Route
                path="templates/client-registration-form"
                element={<PageClientRegistration />}
              />
              <Route
                path="/templates/acupuncture-insurance"
                element={<PageACAInsurance />}
              />
              <Route
                path="/templates/pt-initial-evaluation"
                element={<PagePTInitialConsultation />}
              />
              {/* Routes forms */}
            </Routes>
          </S.Content>
        ) : (
          <LinearLoader />
        )}
        <VerticalMenu>
          <S.Items>
            {routeList.map(route => {
              return (
                <MenuSectionItem
                  key={route.article}
                  text={route.article}
                  onClick={() => navigate(`${route.route}`)}
                  $active={location.includes(`${route.route}`) && true}
                >
                  {route.icon}
                </MenuSectionItem>
              );
            })}
          </S.Items>
          <S.Buttons>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover ? 'Create Notification' : ''}
                backgroundColor="#848A9B"
                onClick={handleChangeDialog}
              >
                <CreateNotificationSVG />
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover ? 'Make a ticket' : ''}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      show: true,
                      mode: DrawerMode.TICKET_CREATE,
                    }),
                  )
                }
              >
                <TicketSVG />
              </Button>
            </S.ButtonWrapper>
          </S.Buttons>
        </VerticalMenu>
      </S.Wrapper>
    </>
  );
};

export default LocalAdmin;
